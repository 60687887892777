import React, { memo } from 'react';
import { Fab } from "@material-ui/core";
import CloseRounded from "@material-ui/icons/CloseRounded";

const ModalCloseButton = (props) => {

    const { closeModalHandler } = props;

    const closeButtonStyle = {
        position: 'absolute',
        right: '-5px',
        top: '-5px',
        zIndex: '999',
        backgroundColor: 'red'
    };

    return (
        <Fab size="small" style={closeButtonStyle} color="primary" onClick={closeModalHandler}>
            <CloseRounded />
        </Fab>
    );
};

export default memo(ModalCloseButton);
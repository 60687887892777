import { useTranslation } from "react-i18next";
import React from "react";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";

import styles from "./reminder-payment.module.scss";

const ReminderPayment = ({ buttonAction }) => {
  const { t } = useTranslation("dashboard");
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const { contactInformation, paymentMethods } = eventInfo;
  const { email, phone, paymentsFullName } = contactInformation;

  const paymentData = { bank: "", clabe: "" };

  const paymentIndex = paymentMethods.findIndex(
    (i) => i.method === "transferencia"
  );
  if (paymentIndex >= 0) {
    paymentData.bank = paymentMethods[paymentIndex].nameBank;
    paymentData.clabe = paymentMethods[paymentIndex].key;
  }
  return (
    <div
      style={{
        width: "100%",
        padding: "30px",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "80%" }}>
        <p className={styles["headerModal"]}>
          {t("paymentInfo.label1")} <b>{paymentData.bank}</b>{" "}
          {t("paymentInfo.label2")} <b>{paymentData.clabe}</b>.
        </p>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          className="custom-primary-btn"
          style={{ width: "100px" }}
          onClick={buttonAction}
        >
          {t("paymentInfo.payment")}
        </Button>
        <p className={styles["footerModal"]}>
          {t("paymentInfo.label3")} <strong>{email}</strong>{" "}
          {t("paymentInfo.label4")} <strong>{phone}</strong>{" "}
          {t("paymentInfo.label5")} <b>{paymentsFullName}</b>.
        </p>
      </div>
    </div>
  );
};

export default ReminderPayment;

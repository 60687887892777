import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

const DatePicker = (props) => {
  const { label, dataTestId, name, ...rest } = props;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        label={label}
        TextFieldComponent={TextField}
        name={name}
        format="yyyy/MM/dd"
        KeyboardButtonProps={{
          "aria-label": { label },
          /*eslint-disable-next-line no-useless-computed-key */
          ...{ ["data-testid"]: { name } },
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;

import {
   
    VALIDATION_PROFILE
  } from "../actions";

  const initialState = {
    validProfile:false
  }


  export default function reducer(state = initialState, action) {

    switch(action.type){
        case VALIDATION_PROFILE:
            return {...state,validProfile:action.data}
        default:
            return state    
    }


  }
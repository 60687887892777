import { required, minLength, maxLength, numeric } from "..";

export const validCellPhoneFlag = (cellphone) => {
  if (cellphone) {
    return (
      !required(cellphone) ||
      !minLength(10)(cellphone) ||
      !maxLength(15)(cellphone) ||
      !numeric(cellphone)
    );
  }
  return true;
};

import { EVENT_INFO, EVENT_INFO_FAILED, EVENT_INFO_SUCCESS } from "../actions";

const initialState = {
  loading: true,
  error: false,
  eventInfo: {
    title: { es: "", en: "" },
    alternativeTitle: { es: "", en: "" },
    name: { es: "", en: "" },
    alternativeName: { es: "", en: "" },
    footerRightsText: "",
    services: [],
    isB2Bsite: false,
    assets: {
      favicon: "",
      loginBanner: "",
      mainLogo: "",
    },
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET eventInfo-----
    case EVENT_INFO:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case EVENT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case EVENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        eventInfo: action.data,
      };

    default:
      return state;
  }
}

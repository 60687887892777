import React, { Suspense, Fragment } from "react";
import { ClimbingBoxLoader } from "react-spinners";
import { ThemeProvider } from "@material-ui/styles";
import { AnimatePresence } from "framer-motion";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
// Layout Blueprints
import { MinimalLayout } from "../../layout-blueprints";
import { useTranslation } from "react-i18next";

// Pages
import {
  Register,
  FreeRegister,
  Login,
  RecoverPassword,
  ChangePassword,
  ChooseService,
  PrivacyPolicy,
  ServiceTerms,
} from "../../pages";

import MuiTheme from "../../theme";

const NoSessionRoutes = () => {
  const { t } = useTranslation("loading-screens");
  const location = useLocation();

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={"#5383ff"} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            {t("loadingContent")}
            <span className="font-size-lg d-block text-dark">
              {/* This live preview instance can be slower than a real production
              build! */}
            </span>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <MinimalLayout>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/login" component={Login} />
              <Route exact path="/free-register" component={FreeRegister} />
              <Route exact path="/choose-service" component={ChooseService} />
              <Route exact path="/register" component={Register} />
              <Route
                exact
                path="/recover-password"
                component={RecoverPassword}
              />
              <Route exact path="/change-password" component={ChangePassword} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/service-terms" component={ServiceTerms} />
              <Route render={() => <Redirect to="/login" />} />
            </Switch>
          </MinimalLayout>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default NoSessionRoutes;

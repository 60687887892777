import React from "react";
import Dropzone from "react-dropzone";

import styles from "./file-dropzone.module.scss";

const FileDropzone = ({
  dataTestId,
  label,
  disabled,
  fileTypesAccepted,
  onDrop,
  onCancelUpload,
  styleImports
}) => {
  return (
    <Dropzone
      onDrop={onDrop}
      onFileDialogCancel={onCancelUpload}
      accept={fileTypesAccepted}
      disabled={disabled}
    >
      {({ getRootProps, getInputProps }) => (
        <div data-testid={dataTestId} {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={styles["dropzone-container"]} style={styleImports}>
            <div className="dx-text text-dark" style={{ fontSize: ".8rem" }}>
              {label}
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default FileDropzone;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Card, Modal } from "@material-ui/core";
import Swal from 'sweetalert2'
import { useTranslation } from "react-i18next";
import styles from "./meet.module.scss";
import MeetSurvey from "./meet-survey";
import JitsiMeet from "components/Meeting";
import PageLayout from "components/PageLayout";
import ModalCloseButton from "pages/VirtualTour/components/ModalCloseButton/ModalCloseButton";
import { requestVerifyVideoconference } from "../../services/MeetService";

const Meet = ({ history }) => {
  const { meetId } = useParams();
  const { loggedUser: { _id: userId } } = useSelector((state) => state.loggedUser);
  const { t, i18n } = useTranslation("meet");
  const currentLanguage = i18n.language;
  const [goAppointmentUrl, setGoAppointmentUrl] = React.useState("/");
  const [meetLoading, setMeetLoading] = React.useState(false);
  const [meetModalOpen, setMeetModalOpen] = React.useState(false);
  const [surveyModalOpen, setSurveyModalOpen] = React.useState(false);
  const [isMeetUndefined, setIsMeetUndefined] = React.useState(true);
  const [customErrorMessage, setCustomErrorMessage] = React.useState("");
  const [jitsiToken, setJitsiToken] = React.useState("");
  const [showMessage,setShowMessage] = React.useState(false)
  const [messageAlert,setMessageAlert] = React.useState('')
  let handleAlert = null
  let handleEnd = null
  React.useEffect(() => {
    if (meetId) {
      setIsMeetUndefined(false);
      setMeetModalOpen(true);
    }
  }, [meetId]);

  React.useEffect(() => {
    validationVideoconference();
  }, [userId]);
  
  const handleShowAlert = ()=>{
    setShowMessage(true)
    Swal.fire({
      timer:6000,
      onClose:()=>setShowMessage(false)
    })
    clearInterval(handleAlert)
}
 
  const setMeetError = (hasError, customErrorMessage = "") => {
    if (hasError) {
      setIsMeetUndefined(true);
      setMeetModalOpen(false);
    } else {
      setIsMeetUndefined(false);
      setMeetModalOpen(true);
    }
    if (customErrorMessage) {
      setCustomErrorMessage(customErrorMessage);
    }
  };
  
  const validationVideoconference = async () => {
    try {
      const response = await requestVerifyVideoconference(userId, meetId);
      if (response.status === 200) {
        if(response.data.controllerInterval['controllerAlert']){
          setMessageAlert(response.data.controllerInterval.messageAlert[currentLanguage])
          handleAlert = setInterval(handleShowAlert, 1000*response.data.controllerInterval['controllerAlert'])
        }else{
          setMessageAlert(response.data.controllerInterval.messageAlert[currentLanguage])
          setShowMessage(true)
          Swal.fire({
            timer:6000,
            onClose:()=>setShowMessage(false)
          })
        }
        handleEnd = setInterval(handleEndCall, 1000*response.data.controllerInterval['controllerHangUp'])
        setGoAppointmentUrl(response.data.goAppointmentUrl);
        setJitsiToken(response.data.token);
        setMeetError(false);
      } else {
        setMeetError(true, t("error"));
      }
    } catch (e) {
      setMeetError(true);
    }
  };

  const handleEndCall = async () => {
    if(handleAlert){
      clearInterval(handleAlert)
    }
    if(handleEnd){
      clearInterval(handleEnd)
    }
    setMeetLoading(true);
    setMeetModalOpen(false);
    const response = await requestVerifyVideoconference(userId, meetId);
    setGoAppointmentUrl(response.data.goAppointmentUrl);
      if (response.status === 200 && response.data.showSurvey) {
        setSurveyModalOpen(response.data.showSurvey);
      } else {
        handleGoBackAppointment(response.data.goAppointmentUrl);
      }
      setMeetLoading(false);
  };

  const handleGoBackAppointment = (urlPath=null) => {
    history.push({
      pathname: urlPath || goAppointmentUrl,
    });
  };

  const onSurveySubmit = () => {
    handleGoBackAppointment();
  };

  return (
    <PageLayout
      errorMessage="Hubo un error al cargar la videoconferencia, intente más tarde"
      loading={meetLoading}
      titleHeading={t("pageTitle")}
    >
      {
        isMeetUndefined && <h3 className="text-center mt-4">{customErrorMessage}</h3>
      }
      <Modal open={meetModalOpen}>
        <div
          style={{ width: "100%", height: "100%", backgroundColor: "white"}}
        >
          {showMessage?
            <div className={styles['message-alert']}>
               {messageAlert}
            </div>
          :null}
          <ModalCloseButton
            closeModalHandler={handleEndCall}
          />
          <JitsiMeet
            handleEndCall={handleEndCall}
            setMeetError={setMeetError}
            jitsiToken={jitsiToken}
            meetId={meetId}
          />
        </div>
      </Modal>
      
      <Modal open={surveyModalOpen} className={styles["page-wrapper"]}>
        <Card className={styles["card-container"]}>
          <div className={styles["form-container"]}>
            <h3>{t("survey")}</h3>
            <div className="d-flex justify-content-end">
              <MeetSurvey surveySubmit={onSurveySubmit} />
            </div>
          </div>
        </Card>
      </Modal>

    </PageLayout>
  );
};
export default Meet;

import React from "react";
import axios from "../../config/axios";

const Report = ({ history }) => {
  let downloadFile1 = () => {
    axios
      .get("/get-data", { responseType: "blob" })
      .then((body) => {
        let url = window.URL.createObjectURL(new Blob([body.data]));
        let a = document.createElement("a");
        a.href = url;
        a.download = "Reporte.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  React.useEffect(() => {
    downloadFile1();
    history.push("/dashboard");
  }, [history]);

  return <div />;
};
export default Report;

import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import React from "react";

const PremiumRegisterDetails = ({ user }) => {
  const { t } = useTranslation("register");
  const {
    firstName,
    lastName,
    email,
    cellphone,
    comercialName,
    companyWeb,
    city,
  } = user;
  return (
    <div className="w-100 pr-4">
      <h5>{t("resume.title")}</h5>
      <TextField
        type="text"
        value={firstName}
        name="firstName"
        autoComplete="off"
        fullWidth
        disabled={true}
      />
      <TextField
        type="text"
        value={lastName}
        name="lastName"
        autoComplete="off"
        fullWidth
        disabled={true}
      />
      <TextField
        type="email"
        value={email}
        name="email"
        autoComplete="off"
        fullWidth
        disabled
      />
      <TextField name="cellphone" value={`+${cellphone}`} disabled={true} />
      {comercialName && (
        <TextField
          value={comercialName}
          name="comercialName"
          fullWidth
          type="text"
          autoComplete="off"
          disabled
        />
      )}
      {companyWeb && (
        <TextField
          type="text"
          value={companyWeb}
          name="companyWeb"
          autoComplete="off"
          fullWidth
          disabled
        />
      )}
      {city && (
        <TextField
          type="text"
          value={city}
          name="city"
          autoComplete="off"
          fullWidth
          disabled
        />
      )}

      <div className="mt-4">
        <small className="text-dark">
          {t("resume.checkData")}
        </small>
      </div>
    </div>
  );
};
export default PremiumRegisterDetails;

import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";

import styles from "../../Register/register-form.module.scss";
import stylesInfo from "./user-info.module.scss";
import { PhoneInput } from "../../";

const UserInfo = () => {
  const { t } = useTranslation("dashboard");
  const { loggedUser } = useSelector((store) => store.loggedUser);
  const {
    firstName,
    lastName,
    email,
    contact: { cellphoneDialCode, cellphone, city },
    comercialName,
    companyWeb,
  } = loggedUser;

  return (
    <div className={stylesInfo["divUser"]}>
      <h5 className="font-weight-bold mt-4">{t("paymentInfo.confirmUser")}</h5>
      <TextField
        type="text"
        value={firstName}
        name="firstName"
        autoComplete="off"
        className={styles["text-field"]}
        fullWidth
        disabled={true}
      />

      <TextField
        type="text"
        value={lastName}
        name="lastName"
        autoComplete="off"
        className={styles["text-field"]}
        fullWidth
        disabled={true}
      />

      <TextField
        type="email"
        value={email}
        name="email"
        autoComplete="off"
        className={styles["text-field"]}
        fullWidth
        disabled
      />
      <PhoneInput
        valueName="cellphone"
        value={`+${cellphoneDialCode} ${cellphone}`}
        disabled={true}
      />

      {comercialName && (
        <TextField
          value={comercialName}
          name="comercialName"
          fullWidth
          type="text"
          autoComplete="off"
          className={styles["text-field"]}
          disabled
        />
      )}

      {companyWeb && (
        <TextField
          type="text"
          value={companyWeb}
          name="companyWeb"
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          disabled
        />
      )}

      {city && (
        <TextField
          type="text"
          value={city}
          name="city"
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          disabled
        />
      )}
    </div>
  );
};

export default UserInfo;

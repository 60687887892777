import { SERVICES, SERVICES_FAILED, SERVICES_SUCCESS } from "../actions";

const initialState = {
  loading: false,
  error: false,
  services: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET services-----
    case SERVICES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case SERVICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        services: action.data,
      };

    default:
      return state;
  }
}

import axios from "../../config/axios";
import handleError from "../../utils/handleAxiosError";
import { LOGIN, LOGIN_FAILED, LOGIN_SUCCESS } from "./Constants";
const { REACT_APP_API_URL } = process.env;

export const login = () => ({ type: LOGIN });
export const loginFailed = (error) => ({
  type: LOGIN_FAILED,
  data: error,
});
export const loginSuccess = (response) => ({
  type: LOGIN_SUCCESS,
  data: response,
});

export const loginUser = (session, remindMeSession) => {
  return async (dispatch) => {
    dispatch(login());

    try {
      const response = await axios.post("/login", { ...session });
      if (response.status === 200) {
        const userData = response.data.data;
        logginSuccessActions(userData, remindMeSession)(dispatch);
      } else {
        dispatch(loginFailed(response.data));
      }
    } catch (error) {
      dispatch(loginFailed(handleError(error)));
    }
  };
};

export const logginSuccessActions = (userData, remindMeSession = true) => {
  return async (dispatch) => {
    // Save JWT Token
    if (remindMeSession) {
      setLocalStorageUser(userData.accessToken);
      setLocalStorageReport(userData);
    } else {
      setSessionStorageUser(userData.accessToken);
      setSessionStorageReport(userData);
    }
    // Save loggedUser into Redux
    dispatch(loginSuccess(userData.user));
  };
};

// ----- LOGOUT -----
export const LOGOUT = "LOGOUT";

export const logout = () => ({
  type: LOGOUT,
});

export const logoutUser = () => {
  return async (dispatch) => {
    localStorage.removeItem("loggedUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("pageUsers");
    localStorage.removeItem("appointments");
    localStorage.removeItem("infoReport");
    sessionStorage.removeItem("loggedUser");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("pageUsers");
    sessionStorage.removeItem("appointments");
    sessionStorage.removeItem("infoReport");
    dispatch(logout());
  };
};

const pageUsers = [
  {
    page: 0,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 1,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 2,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 3,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 4,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
];

const appointments = [
  {
    page: 0,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 1,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 2,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 3,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
  {
    page: 4,
    selected: 0,
    array: "",
    sorting: "",
    field: "",
    actual: false,
  },
];

export const setLocalStorageUser = (accessToken) => {
  localStorage.setItem("accessToken", accessToken);

  // TODO: what is this for ??? why not declare this two bellow inside the RolAdminOrGod.jsx file
  localStorage.setItem("pageUsers", JSON.stringify(pageUsers));
  localStorage.setItem("appointments", JSON.stringify(appointments));
};
export const setLocalStorageReport = async (user) => {
  const userResponse = user.user;
  const infoReport = {
    accessToken: userResponse.accessToken,
    apiUrl: REACT_APP_API_URL,
    user: userResponse.email,
    name: userResponse.firstName,
    lastName: userResponse.lastName,
    tour: userResponse.role.name["es"],
  };
  localStorage.setItem("infoReport", JSON.stringify(infoReport));
};

export const setSessionStorageUser = (accessToken) => {
  sessionStorage.setItem("accessToken", accessToken);

  // TODO: what is this for ??? why not declare this two bellow inside the RolAdminOrGod.jsx file
  sessionStorage.setItem("pageUsers", JSON.stringify(pageUsers));
  sessionStorage.setItem("appointments", JSON.stringify(appointments));
};

export const setSessionStorageReport = async (user) => {
  const userResponse = user.user;
  const infoReport = {
    accessToken: userResponse.accessToken,
    apiUrl: REACT_APP_API_URL,
    user: userResponse.email,
    name: userResponse.firstName,
    lastName: userResponse.lastName,
    tour: userResponse.role.name["es"],
  };
  sessionStorage.setItem("infoReport", JSON.stringify(infoReport));
};

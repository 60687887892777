/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getVendorsAppointments } from "services/AppointmentService";
import { useDispatch, useSelector } from "react-redux";
import ModalMenu from "components/ModalMenu";
import DataTable from "../../../../../components/DataTable";
import { getCatalogues } from "redux/actions/Catalogues";
import { parseDateWithStartTime } from "../../../../../utils/helpers/date";

const ACCEPTED_APPOINTMENT = "STS002";

const RolAdminOrGod = ({ status, userId }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigationHistory = useHistory();

  const { catalogues } = useSelector((store) => store.catalogues);

  const [tableLoading, setTableLoading] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [refreshTable, setRefreshTable] = useState(false);

  const dataTableColumns = [
    {
      name: { es: "NOMBRE", en: "NAME" },
      selector: "buyer[0].firstName",
      cell: ({ buyer }) => {
        return `${buyer[0].firstName} ${buyer[0].lastName}`;
      },
      sortable: true,
    },
    {
      name: { es: "EMPRESA", en: "COMPANY" },
      selector: "buyer[0].comercialName",
      sortable: true,
    },
    {
      name: { es: "FECHA DE LA CITA", en: "APPOINTMENT DATE" },
      selector: "date",
      sortable: true,
    },
    {
      name: { es: "ESTATUS", en: "STATUS" },
      selector: `status[0].name[${currentLanguage}]`,
      sortable: true,
    },
    {
      name: { es: "ACCIONES", en: "ACTIONS" },
      cell: ({ buyer, status, _id }) => {
        const id = buyer[0]._id;
        const goToApointment = () => {
          navigationHistory.push("public-profile/" + id);
        };
        const itemsMenu = [
          {
            name: {
              es: "Información de la cita",
              en: "Appointment information",
            },
            action: goToApointment,
          },
        ];
        if (status[0].code === ACCEPTED_APPOINTMENT) {
          const goToVideoconference = () => {
            navigationHistory.push(`meet/${ _id }`);
          };
          itemsMenu.push({
            name: {
              es: "Ir a videoconferencia",
              en: "Go to videoconference",
            },
            action: goToVideoconference,
          });
        }
        return <ModalMenu Items={itemsMenu} />;
      },
    },
  ];

  useEffect(() => {
    dispatch(getCatalogues(["comercialNames"]));
  }, []);

  useEffect(() => {
    console.log('status', status);
    setRefreshTable(!refreshTable);
  }, [status]);

  const handleFilterChange = async (
    pagination,
    sorting,
    searchFilter,
    selectsFilters
  ) => {
    setTableLoading(true);
    try {
      const dataVendors = await getVendorsAppointments(
        status,
        userId,
        pagination,
        sorting,
        searchFilter,
        selectsFilters
      );
      if (dataVendors.status === 200) {
        const data = dataVendors.data.data.dataCleaned;
        if (data === null || (!data.length && searchFilter === "")) {
          setDataTable([]);
        } else {
          const formattedData = data.filter(
            (dataAppointment) =>
              (dataAppointment.date = parseDateWithStartTime(
                dataAppointment.date,
                dataAppointment.startTime
              ))
          );
          setDataTable(formattedData);
          setTotalRows(dataVendors.data.data.pagination.totalItems);
        }
      } else {
        // setError("Error interno de servidor");
        console.log("error de servidor");
      }
    } catch (error) {
      console.error(error);
      // setError("Error interno de servidor");
    } finally {
      setTableLoading(false);
    }
  };

  return (
    <>
      <DataTable
        routeConfig={{
          history: navigationHistory,
          route: "/my-appointments",
          locationSearch: location.search,
        }}
        columns={dataTableColumns}
        filterFields={[
          {
            displayName: { es: "EMPRESA", en: "COMPANY"},
            selector: "comercialName",
            defaultSelectorKey: "buyer.comercialName",
            catalog: catalogues.comercialNames,
          },
        ]}
        data={dataTable}
        refreshTable={refreshTable}
        pagination
        paginationServer
        enableSearch
        keyField="_id"
        onFilterChange={handleFilterChange}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalRows}
        progressPending={tableLoading}
      />
    </>
  );
};

export default RolAdminOrGod;

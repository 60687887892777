import React, { Fragment } from 'react';

import { Grid } from '@material-ui/core';

import svgImage7 from '../assets/images/illustrations/500.svg';

export default function Error500({ errorMessage = 'Hubo un error al cargar la información, intente más tarde.'}) {
  return (
    <Fragment>
      <div className="app-wrapper bg-light">
        <div className="app-main">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <div className="hero-wrapper bg-composed-wrapper min-vh-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <Grid
                        item
                        lg={6}
                        md={9}
                        className="px-4 mx-auto text-center text-black">
                        <img
                          src={svgImage7}
                          className="w-50 mx-auto d-block my-5 img-fluid"
                          alt="..."
                        />
                        <h3 className="font-size-xxl line-height-sm font-weight-light d-block px-3 mb-3 text-black-50">
                          Error del servidor.
                        </h3>
                        <p>
                          {errorMessage}
                        </p>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

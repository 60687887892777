import {
  required,
  validEmail,
  minLength,
  maxLength,
  string,
  webpage,
  confirmSameValue,
} from ".";

export default (user, validCellphone, captchaPassed) => {
  const {
    firstName,
    lastName,
    email,
    emailConfirmation,
    password,
    passwordConfirmation,
    comercialName,
    companyWeb,
    city,
  } = user;

  return (
    !required(firstName) ||
    !minLength(3)(firstName) ||
    !maxLength(25)(firstName) ||
    !string(firstName) ||
    !required(comercialName) ||
    !minLength(2)(comercialName) ||
    !maxLength(40)(comercialName) ||
    !string(comercialName) ||
    //
    !required(lastName) ||
    !minLength(3)(lastName) ||
    !maxLength(25)(lastName) ||
    !string(lastName) ||
    //
    !required(email) ||
    !validEmail(email) ||
    !maxLength(40)(email) ||
    !required(emailConfirmation) ||
    !validEmail(emailConfirmation) ||
    !confirmSameValue(email, emailConfirmation) ||
    //
    validCellphone ||
    //
    !required(password) ||
    !minLength(8)(password) ||
    !maxLength(40)(password) ||
    !required(passwordConfirmation) ||
    !confirmSameValue(password, passwordConfirmation) ||
    //
    
    //
    (companyWeb.length
      ? !webpage(companyWeb) ||
        !minLength(3)(companyWeb) ||
        !maxLength(40)(companyWeb)
      : false) ||
    //
    (city.length
      ? !required(user.city) ||
        !minLength(2)(user.city) ||
        !maxLength(40)(user.city) ||
        !string(user.city)
      : false) ||
    !captchaPassed
  );
};

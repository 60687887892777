import React, { memo } from 'react';
import { BeatLoader } from "react-spinners";

const TourContainer = (props) => {

    const { urlVirtualTour, showIFrame } = props;

    const iFrameStyle = {
        height: '100%',
        width: '100%'
    };

    let iFrameOrLoader = (
        <BeatLoader
            color={"var(--primary)"}
            loading={true}
        />
    );

    if (showIFrame) {
        iFrameOrLoader = (
            <iframe
                title="Virtual tour" 
                src={urlVirtualTour}
                style={iFrameStyle}
                id="virtualFrame"
            />
        );
    }
    React.useEffect(()=>{
        let count = 0
    
        const messageFrame = ()=>{
           
            let frameInfo = document.querySelector("#virtualFrame")
            if(frameInfo){
                let frameMessage = frameInfo.contentWindow;
                frameMessage.postMessage(JSON.stringify({
          
                    key: 'dataKey',
                    dataReport: localStorage.getItem("infoReport") || sessionStorage.getItem("infoReport"),
                    accessToken: localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken")      
                }), "*");
                count++
            }           
               if(count>=10){
                clearInterval(refreshIntervalId) 
               }
        }
        let refreshIntervalId = setInterval(messageFrame, 1000);    
     },[])
   

    return iFrameOrLoader;
};

export default memo(TourContainer);
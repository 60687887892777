import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { Grid, Button, TextField } from "@material-ui/core";

import tenantCode from "config/tenant";
import { reset } from "redux/actions";
import {
  required,
  validEmail,
  minLength,
  maxLength,
  string,
  numeric,
} from "utils/validations";

import { PhoneInput } from "components";

const BuyerForm = ({
  registerLoading,
  onSubmit,
  onClose,
  buyerData,
  loading,
}) => {
  const { t } = useTranslation("company-details");
  const dispatch = useDispatch();

  // ----- State -----

  const [user, setUser] = useState({
    _id: "",
    email: "",
    firstName: "",
    lastName: "",
    position: "",
    phone: "",
    cellphone: "",
    videoConferenceUrl: "",
    tenant: tenantCode,
    city: null,
    needs: "",
  });

  useEffect(() => {
    if (buyerData && buyerData._id) {
      setUser({
        _id: buyerData._id || "",
        email: buyerData.email || "",
        firstName: buyerData.firstName || "",
        lastName: buyerData.lastName || "",
        position: buyerData.position || "",
        phone: buyerData.contact.phone || "",
        cellphone: buyerData.contact.cellphone || "",
        videoConferenceUrl: buyerData.videoConferenceUrl || "",
        tenant: tenantCode,
        city: null,
        needs: buyerData.needs || "",
      });
    }
  }, [buyerData]);

  let disableRegisterBoolean;
  if (!buyerData) {
    disableRegisterBoolean =
      !required(user.firstName) ||
      !minLength(2)(user.firstName) ||
      !string(user.firstName) ||
      !required(user.lastName) ||
      !minLength(2)(user.lastName) ||
      !string(user.lastName) ||
      !required(user.phone) ||
      !minLength(10)(user.phone) ||
      !maxLength(15)(user.phone) ||
      !numeric(user.phone) ||
      !required(user.cellphone) ||
      !minLength(10)(user.cellphone) ||
      !maxLength(15)(user.cellphone) ||
      !numeric(user.cellphone) ||
      !required(user.needs) ||
      !minLength(2)(user.needs) ||
      !string(user.needs);
  } else {
    disableRegisterBoolean =
      !required(user.firstName) ||
      !minLength(2)(user.firstName) ||
      !string(user.firstName) ||
      !required(user.lastName) ||
      !minLength(2)(user.lastName) ||
      !string(user.lastName) ||
      !required(user.email) ||
      !validEmail(user.email) ||
      !required(user.phone) ||
      !minLength(10)(user.phone) ||
      !maxLength(15)(user.phone) ||
      !numeric(user.phone) ||
      !required(user.cellphone) ||
      !minLength(10)(user.cellphone) ||
      !maxLength(15)(user.cellphone) ||
      !numeric(user.cellphone) ||
      !required(user.videoConferenceUrl) ||
      !minLength(2)(user.videoConferenceUrl) ||
      !required(user.needs) ||
      !minLength(2)(user.needs) ||
      !string(user.needs);
  }

  // ----- Effects -----
  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  // ----- Functions -----
  const handleInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleDialcodeChange = (index) => {
    return ({ dialCode }) => {
      setUser({ ...user, [index]: dialCode });
    };
  };

  const register = () => {
    onSubmit(user);
  };

  return (
    <Grid container spacing={0} justifyContent="center">
      <Grid item xs={12} md={8} lg={12}>
        <div className="pt-2 pb-4" />
        <Grid container>
          <Grid item xs={12} className="pt-1">
            <Grid container>
              <Grid item className="w-50 p-2">
                <TextField
                  error={
                    user.firstName.length > 0 &&
                    (!required(user.firstName) ||
                      !minLength(2)(user.firstName) ||
                      !string(user.firstName))
                  }
                  helperText={
                    user.firstName.length > 0
                      ? !required(user.firstName) ||
                        !minLength(2)(user.firstName) ||
                        !string(user.firstName)
                        ? "Nombre(s) válido de al menos 2 caracteres."
                        : null
                      : null
                  }
                  value={user.firstName}
                  name="firstName"
                  label="Nombre(s) *"
                  fullWidth
                  placeholder="Ingresa tu(s) nombre(s)"
                  type="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item className="w-50 p-2">
                <TextField
                  error={
                    user.lastName.length > 0 &&
                    (!required(user.lastName) ||
                      !minLength(2)(user.lastName) ||
                      !string(user.lastName))
                  }
                  helperText={
                    user.lastName.length > 0
                      ? !required(user.lastName) ||
                        !minLength(2)(user.lastName) ||
                        !string(user.lastName)
                        ? "Apellidos válidos de al menos 2 caracteres."
                        : null
                      : null
                  }
                  value={user.lastName}
                  name="lastName"
                  label="Apellidos *"
                  fullWidth
                  placeholder="Ingresa tus apelllidos"
                  type="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
            <Grid container>
              {!buyerData && (
                <Grid item className="w-50 p-2">
                  <TextField
                    error={
                      user.email.length > 0 &&
                      (!required(user.email) || !validEmail(user.email))
                    }
                    helperText={
                      user.email.length > 0
                        ? !required(user.email) || !validEmail(user.email)
                          ? "Correo no válido."
                          : null
                        : null
                    }
                    value={user.email}
                    name="email"
                    label="Correo Electrónico *"
                    fullWidth
                    placeholder="Ingresa tu correo electrónico"
                    type="email"
                    autoComplete="off"
                    onChange={handleInputChange}
                  />
                </Grid>
              )}
              <Grid item className="w-50 p-2">
                <TextField
                  value={user.position}
                  name="position"
                  label="Puesto *"
                  fullWidth
                  placeholder="Puesto en la empresa"
                  type="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid container spacing={4} className="mt-1">
                <Grid item className="w-50 p-2">
                  <PhoneInput
                    inputLabel="Teléfono *"
                    value={user.phone}
                    valueName="phone"
                    previousState={user}
                    setValue={setUser}
                    placeholder="Ingresa tu Teléfono"
                    error={
                      user.phone.length > 0 &&
                      (!required(user.phone) ||
                        !minLength(10)(user.phone) ||
                        !maxLength(15)(user.phone) ||
                        !numeric(user.phone))
                    }
                    helperText={
                      user.phone.length > 0
                        ? !required(user.phone) ||
                          !minLength(10)(user.phone) ||
                          !maxLength(15)(user.phone) ||
                          !numeric(user.phone)
                          ? "Ingresa un teléfono válido."
                          : null
                        : null
                    }
                    setCountryData={handleDialcodeChange("phoneDialCode")}
                  />
                </Grid>
                <Grid item className="w-50 p-2">
                  <PhoneInput
                    inputLabel="Celular *"
                    value={user.cellphone}
                    valueName="cellphone"
                    previousState={user}
                    setValue={setUser}
                    placeholder="Ingresa tu Celular"
                    error={
                      user.cellphone.length > 0 &&
                      (!required(user.cellphone) ||
                        !minLength(10)(user.cellphone) ||
                        !maxLength(15)(user.cellphone) ||
                        !numeric(user.cellphone))
                    }
                    helperText={
                      user.cellphone.length > 0
                        ? !required(user.cellphone) ||
                          !minLength(10)(user.cellphone) ||
                          !maxLength(15)(user.cellphone) ||
                          !numeric(user.cellphone)
                          ? "Ingresa un celular válido."
                          : null
                        : null
                    }
                    setCountryData={handleDialcodeChange("cellphoneDialCode")}
                  />
                </Grid>
              </Grid>

              <Grid item className="w-50 p-2">
                <TextField
                  error={
                    user.needs.length > 0 &&
                    (!required(user.needs) ||
                      !minLength(2)(user.needs) ||
                      !string(user.needs))
                  }
                  helperText={
                    user.needs.length > 0
                      ? !required(user.needs) ||
                        !minLength(2)(user.needs) ||
                        !string(user.needs)
                        ? "Requerimientos válidos de al menos 2 caracteres."
                        : null
                      : null
                  }
                  value={user.needs}
                  name="needs"
                  label="Requerimientos *"
                  fullWidth
                  placeholder="Captura tus requerimientos como comprador"
                  type="text"
                  autoComplete="off"
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" className="mt-2">
          <Grid item>
            {registerLoading ? (
              <BeatLoader color={"var(--primary)"} loading={true} />
            ) : (
              <>
                <div>
                  <Button
                    style={{ alignSelf: "right", marginRight: 10 }}
                    color="secondary"
                    size="large"
                    variant="contained"
                    className="mb-2"
                    onClick={onClose}
                  >
                    {t("companyBuyersTable.addBuyerModal.closeButtonLabel")}
                  </Button>

                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    className="mb-2 custom-primary-btn"
                    disabled={
                      disableRegisterBoolean || registerLoading || loading
                    }
                    onClick={register}
                  >
                    {t("companyBuyersTable.addBuyerModal.updateButtonLabel")}
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BuyerForm;

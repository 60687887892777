import React from "react";
import { useTranslation } from "react-i18next";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";

const AttachedFile = ({
  dataTestId,
  icon,
  noUploadedMessage = {
    es: "No has cargado ningún archivo",
    en: "No file has been uploaded",
  },
  fileName,
  fileUrl,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const GetIcon = () => {
    switch (icon) {
      case "pdf":
        return (
          <PictureAsPdfOutlinedIcon
            className="text-primary"
            style={{ width: "35px", height: "35px" }}
          />
        );
      case "image":
        return <ImageOutlinedIcon className="text-primary" />;
      default:
        return null;
    }
  };

  return (
    <div data-testid={dataTestId} className="text-dark d-flex">
      {fileName && fileUrl ? (
        <>
          <GetIcon />
          <a
            className="ml-3 text-dark"
            href={fileUrl}
            download={fileName}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fileName}
          </a>
        </>
      ) : (
        <small className="font-italic">
          {noUploadedMessage[currentLanguage]}
        </small>
      )}
    </div>
  );
};

export default AttachedFile;

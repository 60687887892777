import { useTranslation } from "react-i18next";
import React from "react";
import { NavLink as RouterLink } from "react-router-dom";

const TermsConditions = () => {
  const { t } = useTranslation("register");
  return (
    <div className="text-dark text-center" style={{ fontSize: 12 }}>
      <span>
        <b>{t("terms1")}</b>, {t("terms2")}{" "}
      </span>
      <RouterLink to="/service-terms" target="_blank">
        <b>{t("serviceTerms")}</b>
      </RouterLink>
      <b> {t("terms3")} </b>
      <RouterLink to="/privacy-policy" target="_blank">
        <b>{t("privacyPolicy")}</b>
      </RouterLink>
    </div>
  );
};

export default TermsConditions;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Card,
  Tabs,
  Tab,
  Typography,
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { AttachedFile } from "../../..";
import rightFormStyles from "../../../profile/right-form/right-form.module.scss";
import truncateString from "../../../../utils/truncate-string";
import useUserCatalogs from "../../../../utils/catalogs/useUserCatalogs";

const TabsInfo = ({ user, handleInputChange, handleRadioChange }) => {
  // -- State --
  const { t, i18n } = useTranslation("profile");
  const currentLanguage = i18n.language;

  const [tabValue, setTabValue] = useState(0);

  const {
    companySizes,
    companyTypes,
    attendedMarkets,
    certificationOptions,
    interactiveMedias,
    sectors,
    informationTechnologies,
    telecommunications,
    industries4,
    belongingClusters,
  } = useUserCatalogs();

  const {
    taxResidence: {
      country,
      state,
      street,
      exteriorNumber,
      interiorNumber,
      neighborhood,
      city,
      postalCode,
    },
    companyInformation: {
      companyType,
      attendedMarket,
      certifications,
      companyOriginCountry,
      businessSeniorityYears,
      companySize,
      threeMostImportantClients,
      branchesLocation,
      otherBelongingClusters,
      otherCertifications,
    },
    companyPresentation: {
      rfcTaxIdentificationCard: {
        name: rfcTaxIdentificationCardName,
        url: rfcTaxIdentificationCardUrl,
      },
    },
    financialIntegrity: {
      positiveOpinionSatLetter,
      paymentTerms,
      lastYearAnnualSale,
      otherFinancialIntegrityCertifications,
    },
    sectorInformation: {
      sector,
      informationTechnology,
      telecommunication,
      interactiveMedia,
      industry4,
    },
  } = user;
  const [generalServices, setGeneralServices] = React.useState("");

  const tabsContent = [
    {
      label: {
        es: "Datos Generales de la Empresa",
        en: "General Information About the Company",
      },
      sections: [
        {
          es: "Otros Datos de la Empresa",
          en: "Other Company Data",
        },
      ],
    },
    {
      label: {
        es: "Sector / Subsector Industrial",
        en: "Sector / Industrial Subsector",
      },
    },
    {
      label: {
        es: "Negocio o Sector Principal Atendido",
        en: "Business or Main Sector Served",
      },
    },
    {
      label: {
        es: "Cámaras, Clústers, Certificaciones",
        en: "Cameras, Clusters, Certifications",
      },
    },
  ];
  // -- Effects --
  // Mark checked checkboxes for multiple selection fields
  useEffect(() => {
    markCheckedOptions(companyType, companyTypes.array, companyTypes.setter);
    markCheckedOptions(sector, sectors.array, sectors.setter);
    markCheckedOptions(
      telecommunication,
      telecommunications.array,
      telecommunications.setter
    );
    markCheckedOptions(
      interactiveMedia,
      interactiveMedias.array,
      interactiveMedias.setter
    );
    markCheckedOptions(industry4, industries4.array, industries4.setter);
    let searchMatches = 0;
    for (let j = 0; j < sector.length; j++) {
      for (let i = 0; i < sectors.array.length; i++) {
        if (sector[j] === sectors.array[i].value) {
          searchMatches++;
        }
      }
      if (searchMatches !== 0) {
      } else {
        setGeneralServices(sector[j]);
      }
      searchMatches = 0;
    }
    markCheckedOptions(
      attendedMarket,
      attendedMarkets.array,
      attendedMarkets.setter
    );
    markCheckedOptions(
      certifications,
      certificationOptions.array,
      certificationOptions.setter
    );
    markCheckedOptions(
      informationTechnology,
      informationTechnologies.array,
      informationTechnologies.setter
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // -- Functions --
  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const markCheckedOptions = (savedOptions, options, setter) => {
    savedOptions.forEach((savedOption) => {
      options.forEach((option) => {
        if (savedOption === option.value) {
          option.checked = true;
        }
      });
    });
    setter([...options]);
  };

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        className="mt-4"
        variant="scrollable"
        indicatorColor="primary"
      >
        {tabsContent.map(({ label }, index) => (
          <Tab key={index} label={label[currentLanguage]} />
        ))}
      </Tabs>

      <Card className="p-3 mt-4 w-100">
        <div className="d-flex flex-column">
          <div>
            <TabPanel value={tabValue} index={0}>
              <h5 className="mb-4">
                {tabsContent[tabValue].label[currentLanguage]}
              </h5>

              <Grid container spacing={4}>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={country}
                    name="country"
                    label={t("tabForms.tab1.country")}
                    placeholder={t("tabForms.tab1.countryPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={state}
                    name="state"
                    label={t("tabForms.tab1.state")}
                    placeholder={t("tabForms.tab1.statePlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={street}
                    name="street"
                    label={t("tabForms.tab1.street")}
                    placeholder={t("tabForms.tab1.streetPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={exteriorNumber}
                    name="exteriorNumber"
                    label={t("tabForms.tab1.exteriorNumber")}
                    placeholder={t("tabForms.tab1.exteriorNumberPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={interiorNumber}
                    name="interiorNumber"
                    label={t("tabForms.tab1.interiorNumber")}
                    placeholder={t("tabForms.tab1.interiorNumberPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={neighborhood}
                    name="neighborhood"
                    label={t("tabForms.tab1.neighborhood")}
                    placeholder={t("tabForms.tab1.neighborhoodPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={city}
                    name="city"
                    label={t("tabForms.tab1.city")}
                    placeholder={t("tabForms.tab1.cityPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={postalCode}
                    name="postalCode"
                    label={t("tabForms.tab1.postalCode")}
                    placeholder={t("tabForms.tab1.postalCodePlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="text"
                    value={companyOriginCountry}
                    name="companyOriginCountry"
                    label={t("tabForms.tab1.companyOriginCountry")}
                    placeholder={t(
                      "tabForms.tab1.companyOriginCountryPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={businessSeniorityYears}
                    name="businessSeniorityYears"
                    label={t("tabForms.tab1.businessSeniorityYears")}
                    placeholder={t(
                      "tabForms.tab1.businessSeniorityYearsPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    name="companySize"
                    value={companySize}
                    label={t("tabForms.tab1.companySize")}
                    fullWidth
                    disabled
                    select
                    onChange={handleInputChange}
                  >
                    {companySizes.array.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label[currentLanguage]}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab1.companyType")}
                  </p>
                  {companyTypes.array.map(
                    ({ value, label, checked }, index) => {
                      return (
                        <FormControlLabel
                          className={rightFormStyles["form-item-checkbox"]}
                          key={index}
                          control={
                            <Checkbox
                              color="primary"
                              value={value}
                              checked={checked}
                            />
                          }
                          label={label[currentLanguage]}
                          disabled
                        />
                      );
                    }
                  )}
                </Grid>
              </Grid>

              <h5 className="my-4 pb-2">
                {tabsContent[tabValue].sections &&
                  tabsContent[tabValue].sections[0][currentLanguage]}
              </h5>

              <Grid container spacing={4}>
                <Grid item className={`${rightFormStyles["form-item"]} pb-0`}>
                  <p className={rightFormStyles["form-label"]}>
                    {t("tabForms.tab1.positiveOpinionSatLetter")}
                  </p>
                  <RadioGroup
                    aria-label="positiveOpinionSatLetter"
                    name="positiveOpinionSatLetter"
                    value={positiveOpinionSatLetter}
                    onChange={(e) =>
                      handleRadioChange(e, "positiveOpinionSatLetter")
                    }
                  >
                    <div className="d-flex">
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" />}
                        label={t(
                          "tabForms.tab1.positiveOpinionSatLetterYESoption"
                        )}
                        disabled
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" />}
                        label="NO"
                        disabled
                      />
                    </div>
                  </RadioGroup>
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={paymentTerms}
                    name="paymentTerms"
                    label={t("tabForms.tab1.paymentTerms")}
                    placeholder={t("tabForms.tab1.paymentTermsPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item className={rightFormStyles["form-item"]}>
                  <TextField
                    type="number"
                    value={lastYearAnnualSale}
                    name="lastYearAnnualSale"
                    label={t("tabForms.tab1.lastYearAnnualSale")}
                    placeholder={t(
                      "tabForms.tab1.lastYearAnnualSalePlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5}>
                <Grid item className="w-100">
                  <TextField
                    type="text"
                    value={otherFinancialIntegrityCertifications}
                    name="otherFinancialIntegrityCertifications"
                    label={t(
                      "tabForms.tab1.otherFinancialIntegrityCertifications"
                    )}
                    placeholder={t(
                      "tabForms.tab1.otherFinancialIntegrityCertificationsPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item style={{ width: "66%" }}>
                  <p className={rightFormStyles["form-label"]}>
                    {t("tabForms.tab1.taxIdCardRFC")}
                  </p>
                  <AttachedFile
                    icon="pdf"
                    fileName={truncateString(rfcTaxIdentificationCardName, 20)}
                    fileUrl={rfcTaxIdentificationCardUrl}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <h5 className="mb-4">
                {tabsContent[tabValue].label[currentLanguage]}
              </h5>

              <Grid
                container
                spacing={4}
                className={rightFormStyles["margin-general-services"]}
              >
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab2.belongingSector")}
                  </p>
                  {sectors.array.map(({ value, label, checked }, index) => (
                    <FormControlLabel
                      className={rightFormStyles["form-item-checkbox"]}
                      key={index}
                      control={
                        <Checkbox
                          color="primary"
                          value={value}
                          checked={checked}
                        />
                      }
                      label={label[currentLanguage]}
                      disabled
                    />
                  ))}
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab2.informationTechnologiesSubsector")}
                  </p>
                  {informationTechnologies.array.map(
                    ({ value, label, checked }, index) => (
                      <FormControlLabel
                        className={rightFormStyles["form-item-checkbox"]}
                        key={index}
                        control={
                          <Checkbox
                            color="primary"
                            value={value}
                            checked={checked}
                          />
                        }
                        label={label[currentLanguage]}
                        disabled
                      />
                    )
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab2.telecommunicationsSubsector")}
                  </p>
                  {telecommunications.array.map(
                    ({ value, label, checked }, index) => (
                      <FormControlLabel
                        className={rightFormStyles["form-item-checkbox"]}
                        key={index}
                        control={
                          <Checkbox
                            color="primary"
                            value={value}
                            checked={checked}
                          />
                        }
                        label={label[currentLanguage]}
                        disabled
                      />
                    )
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab2.interactiveMediaSubsector")}
                  </p>
                  {interactiveMedias.array.map(
                    ({ value, label, checked }, index) => (
                      <FormControlLabel
                        className={rightFormStyles["form-item-checkbox"]}
                        key={index}
                        control={
                          <Checkbox
                            color="primary"
                            checked={checked}
                            value={value}
                          />
                        }
                        label={label[currentLanguage]}
                        disabled
                      />
                    )
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab2.industry4Subsector")}
                  </p>
                  {industries4.array.map(({ value, label, checked }, index) => (
                    <FormControlLabel
                      className={rightFormStyles["form-item-checkbox"]}
                      key={index}
                      control={
                        <Checkbox
                          color="primary"
                          value={value}
                          checked={checked}
                        />
                      }
                      label={label[currentLanguage]}
                      disabled
                    />
                  ))}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <h5 className="mb-4">
                {tabsContent[tabValue].label[currentLanguage]}
              </h5>

              <Grid container spacing={4}>
                <Grid item className="w-100 mt-0">
                  <TextField
                    type="text"
                    value={threeMostImportantClients}
                    name="threeMostImportantClients"
                    label={t("tabForms.tab3.threeMostImportantClients")}
                    placeholder={t(
                      "tabForms.tab3.threeMostImportantClientsPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} className="mt-2">
                <Grid item className="w-100">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab3.suppliedMarkets")}
                  </p>
                  {attendedMarkets.array.map(
                    ({ value, label, checked }, index) => (
                      <FormControlLabel
                        className={rightFormStyles["form-item-checkbox"]}
                        key={index}
                        control={
                          <Checkbox
                            color="primary"
                            value={value}
                            checked={checked}
                          />
                        }
                        label={label[currentLanguage]}
                        disabled
                      />
                    )
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item className="w-100">
                  <TextField
                    type="text"
                    value={branchesLocation}
                    name="branchesLocation"
                    label={t("tabForms.tab3.branchesLocation")}
                    placeholder={t("tabForms.tab3.branchesLocationPlaceholder")}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              <h5 className="mb-4">
                {tabsContent[tabValue].label[currentLanguage]}
              </h5>

              <Grid container spacing={4} className="mt-2">
                <Grid item className="w-100 pb-0">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab4.belongingClusters")}
                  </p>
                  {belongingClusters.array.map(
                    ({ value, label, checked }, index) => (
                      <FormControlLabel
                        className={rightFormStyles["form-item-checkbox"]}
                        key={index}
                        control={
                          <Checkbox
                            color="primary"
                            value={value}
                            checked={checked}
                          />
                        }
                        label={label}
                        disabled
                      />
                    )
                  )}
                </Grid>

                <Grid item className="w-50">
                  <TextField
                    type="text"
                    value={otherBelongingClusters}
                    name="otherBelongingClusters"
                    label={t("tabForms.tab4.otherBelongingClusters")}
                    placeholder={t(
                      "tabForms.tab4.otherBelongingClustersPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>

                <Grid item className="w-100 pb-0">
                  <p className={rightFormStyles["form-label-subtitle"]}>
                    {t("tabForms.tab4.certifications")}
                  </p>
                  {certificationOptions.array.map(
                    ({ value, label, checked }, index) => (
                      <FormControlLabel
                        className={rightFormStyles["form-item-checkbox"]}
                        key={index}
                        control={
                          <Checkbox
                            color="primary"
                            value={value}
                            checked={checked}
                          />
                        }
                        label={label}
                        disabled
                      />
                    )
                  )}
                </Grid>
                <Grid item className="w-50 pt-0">
                  <TextField
                    type="text"
                    value={otherCertifications}
                    name="otherCertifications"
                    label={t("tabForms.tab4.otherCertifications")}
                    placeholder={t(
                      "tabForms.tab4.otherCertificationsPlaceholder"
                    )}
                    autoComplete="off"
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </div>
        </div>
      </Card>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabsInfo;

import axios from "config/axios";

const { REACT_APP_API_URL } = process.env;

const getVendorsAppointments = (
  statusCode,
  userId,
  pagination,
  sort,
  search = "",
  selectsFilters
) => {
  const filters = {
    ...selectsFilters,
    "seller._id": userId,
    $or: [
      "title",
      "seller.email",
      "seller.comercialName",
      "seller.firstName",
      "buyer.email",
      "date",
      "startTime",
      "status.name",
    ],
  };
  if (statusCode !== "ALL") {
    filters["status.code"] = statusCode;
  }
  const payload = {
    ...pagination,
    selects: {
      title: 1,
      "seller.email": 1,
      "seller.comercialName": 1,
      "seller.firstName": 1,
      "seller._id": 1,
      buyer: 1,
      date: 1,
      startTime: 1,
      "status.name": 1,
      "status.code": 1,
    },
    filters,
    sort,
    search,
  };
  return axios.post(
    `${REACT_APP_API_URL}/vendors/appointments/${statusCode}`,
    payload
  );
};

const getBuyersAppointments = (
  statusCode,
  userId,
  pagination,
  sort,
  search = "",
  selectsFilters
) => {
  
  const filters = {
    ...selectsFilters,
    "buyer._id": userId,
    $or: [
      "title",
      "seller.email",
      "seller.comercialName",
      "seller.firstName",
      "buyer.email",
      "date",
      "startTime",
      "status.name",
    ],
  };
  if (statusCode !== "ALL") {
    filters["status.code"] = statusCode;
  }
  const payload = {
    ...pagination,
    selects: {
      title: 1,
      seller: 1,
      buyer: 1,
      date: 1,
      startTime: 1,
      "status.name": 1,
      "status.code": 1,
    },
    filters,
    sort,
    search,
  };
  return axios.post(
    `${REACT_APP_API_URL}/buyers/appointments/${statusCode}`,
    payload
  );
};

const getAdminAppointments = (
  statusCode,
  pagination,
  sort,
  search = "",
  selectsFilters,
) => {
  const filters = {
    ...selectsFilters,
    $or: [
      "title",
      "seller.email",
      "seller.comercialName",
      "seller.firstName",
      "buyer.email",
      "buyer.companyId.businessName",
      "date",
      "startTime",
      "status.name",
      "statusVideoconferenceId.name",
    ],
  };
  if (statusCode !== "ALL") {
    filters["status.code"] = statusCode;
  }
  const payload = {
    ...pagination,
    selects: {
      title: 1,
      "seller.email": 1,
      "seller.comercialName": 1,
      "seller.firstName": 1,
      "seller.lastName": 1,
      "buyer.email": 1,
      "buyer.companyId": 1,
      date: 1,
      startTime: 1,
      "status.name": 1,
      "status.code": 1,
      "statusVideoconferenceId.name": 1,
      "statusVideoconferenceId.code": 1,
    },
    filters,
    sort,
    search,
  };
  return axios.post(`${REACT_APP_API_URL}/appointments/${statusCode}`, payload);
};

const getAdminAppointmentsVideoconference = (
  statusCode,
  pagination,
  sort,
  search = "",
  dateFilter,
  selectsFilters
) => {
  if (!dateFilter) {
    dateFilter = "HISTORY";
  }
  if(dateFilter instanceof Date){
    dateFilter = "";  
}
  const filters = {
    ...selectsFilters,
    dateFilter,
    $or: [
      "title",
      "seller.email",
      "seller.comercialName",
      "seller.firstName",
      "buyer.email",
      "buyer.companyId.businessName",
      "date",
      "startTime",
      "status.name",
      "statusVideoconferenceId.name",
    ],
  };
  if (statusCode !== "ALL") {
    filters["statusVideoconferenceId.code"] = statusCode;
  }
  const payload = {
    ...pagination,
    selects: {
      title: 1,
      "seller.email": 1,
      "seller.comercialName": 1,
      "seller.firstName": 1,
      "seller.lastName": 1,
      "buyer.email": 1,
      "buyer.companyId": 1,
      date: 1,
      startTime: 1,
      "status.name": 1,
      "status.code": 1,
      "statusVideoconferenceId.name": 1,
      "statusVideoconferenceId.code": 1,
    },
    filters,
    sort,
    search,
  };
  return axios.post(`${REACT_APP_API_URL}/videoconferences/${statusCode}`, payload);
};

const createAppointmentSurvey = (form, appointmentid) => {
  return axios.post(
    `${REACT_APP_API_URL}/create-appointment-survey/${appointmentid}`,
    form
  );
};

export {
  getVendorsAppointments,
  getBuyersAppointments,
  getAdminAppointments,
  getAdminAppointmentsVideoconference,
  createAppointmentSurvey,
};

import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Alert, Loader } from "components";
import { registerBuyer } from "redux/actions/";
import BuyerForm from "./BuyerForm";

const AddAssistant = ({ companyid, history, onClose }) => {
  const { t } = useTranslation("company-details");
  const dispatch = useDispatch();

  const { loading, error, success } = useSelector((store) => store.buyers);

  useEffect(() => {
    //Redirect to successful register page
    if (success) {
      window.location.reload(false);
    }
  }, [success]);

  const onSubmit = (user) => {
    dispatch(registerBuyer(user, companyid));
  };

  const assistantForm = (
    <BuyerForm
      registerLoading={loading}
      history={history}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );

  return (
    <Grid container className="p-3">
      <Grid item lg={12} xs={12}>
        {loading && <Loader />}

        <h4 className="font-size-xl mb-0 py-2 font-weight-bold">
          {t("companyBuyersTable.addBuyerModal.title")}
        </h4>

        {assistantForm}
      </Grid>

      <Grid item className="w-100">
        {success && (
          <Grid item xs={12} lg={12}>
            <Alert
              severity="success"
              message="Operación realizada exitosamente."
            />
          </Grid>
        )}

        {error && <Alert severity="error" message={error} />}
      </Grid>
    </Grid>
  );
};

export default AddAssistant;

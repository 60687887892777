import { webpage, string } from "./validations";

export const cdnDynamic = (src, callback) => {
  let validateSrc = webpage(src);
  let validateCallback = typeof callback;

  if (validateSrc) {
    if (validateCallback === "function") {
      let elementScript, state, elementParent;
      state = false;
      elementScript = document.createElement("script");
      elementScript.type = "text/javascript";
      elementScript.src = src;
      elementScript.onload = elementScript.onreadystatechange = function() {
        if (!state && (!this.readyState || this.readyState === "complete")) {
          state = true;
          callback();
        }
      };
      elementParent = document.getElementsByTagName("script")[0];
      elementParent.parentNode.insertBefore(elementScript, elementParent);
    } else {
      console.log("The callback is invalid");
    }
  } else {
    console.log("The script is invalid");
  }
};

export const titleDynamic = (title) => {
  let validateTitle = string(title);

  if (validateTitle) {
    let elementTitle = document.createElement("title");
    elementTitle.textContent = title;
    document.head.appendChild(elementTitle);
  } else {
    console.log("The title is invalid");
  }
};

export const faviconDynamic = (urlFavicon) => {
  let validateFavicon = webpage(urlFavicon);

  if (validateFavicon) {
    let elementFavicon = document.createElement("link");
    elementFavicon.setAttribute("rel", "shortcut icon");
    elementFavicon.setAttribute("href", urlFavicon);
    document.head.appendChild(elementFavicon);
  } else {
    console.log("The favicon is invalid");
  }
};

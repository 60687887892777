import {
  SELLERS,
  SELLERS_FAILED,
  SELLERS_SUCCESS,
  SELLERS_SUCCESS_ADMIN,
  SELLER,
  SELLER_FAILED,
  SELLER_SUCCESS,
  REQUEST_APPOINTMENT_AVAILABILITY,
  REQUEST_APPOINTMENT_AVAILABILITY_FAILED,
  REQUEST_APPOINTMENT_AVAILABILITY_SUCCESS,
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  success: false,
  sellersData: undefined,
  requestAvailability: {
    isAvailable: false,
    id: "",
  },
  seller: {
    avatar: "",
    completed: false,
    contact: {},
    virtualTour: {},
    additionalInfo: {
      sections: [],
    },
    companyId: {
      prodServ: "",
    },
    appointments: {
      accepted: 0,
      pending: 0,
    },
    role: {},
  },
  sellerAdmin: {
    avatar: "",
    completed: false,
    contact: {},
    virtualTour: {},
    additionalInfo: {
      sections: [],
    },
    companyId: {
      prodServ: "",
    },
    appointments: {
      accepted: 0,
      pending: 0,
    },
    role: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELLERS:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case SELLERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case SELLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        sellersData: action.data,
      };

    case SELLER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case SELLER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case SELLER_SUCCESS:
      return {
        ...state,
        loading: false,
        seller: action.data,
      };

      case SELLERS_SUCCESS_ADMIN:
      return {
        ...state,
        loading: false,
        sellerAdmin: action.data,
      };

    // ----- GET request appointment availability -----
    case REQUEST_APPOINTMENT_AVAILABILITY:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case REQUEST_APPOINTMENT_AVAILABILITY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case REQUEST_APPOINTMENT_AVAILABILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        requestAvailability: action.data,
      };
    default:
      return state;
  }
}

import moment from "moment";
import React from "react";
import axios from "../../config/axios";
const { TIME_ZONE } = process.env;
const ReportAppointment = ({ history }) => {
  let downloadFile1 = () => {
    axios
      .get("/reportAppointments", { responseType: "blob" })
      .then((body) => {
        let url = window.URL.createObjectURL(new Blob([body.data]));
        let a = document.createElement("a");
        a.href = url;
        moment.tz.setDefault(TIME_ZONE);
        const nameFile = `ReporteDeCitas${moment().format(
          "YYYYMMDDHHmm"
        )}.xlsx`;
        a.download = nameFile;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  React.useEffect(() => {
    downloadFile1();
    history.push("/dashboard");
  }, [history]);

  return <div />;
};
export default ReportAppointment;

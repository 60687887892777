import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ClimbingBoxLoading } from "components";
import ScrollToTop from "./ScrollToTop";
import SessionRoutes from "./Routes/SessionRoutes";
import NoSessionRoutes from "./Routes/NoSessionRoutes";
import { getEventInfo, loginFailed, loginSuccess } from "../redux/actions";
import { titleDynamic, faviconDynamic } from "./dynamicTags";
import axios from "../config/axios";

const SessionValidator = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const { eventInfo, loading: loadingEventInfo } = useSelector(
    (store) => store.eventInfo
  );
  const { loginSuccess: loginSuccessState } = useSelector(
    (store) => store.loggedUser
  );

  const [loadingUser, setLoadingUser] = useState(true);

  useEffect(() => {
    if (eventInfo.title && eventInfo.assets) {
      titleDynamic(eventInfo.title[currentLanguage]);
      faviconDynamic(eventInfo.assets.favicon);
    }
  }, [eventInfo]);

  const getUserSelf = useCallback(async () => {
    axios
      .get("/users/user-self")
      .then(({ data }) => {
        dispatch(loginSuccess(data.data));
      })
      .catch(() => {
        dispatch(loginFailed());
      })
      .finally(() => {
        setLoadingUser(false);
      });
  }, [dispatch]);

  // Main app effect
  useEffect(() => {
    // Get Event Info
    dispatch(getEventInfo());
    // Get logged user
    getUserSelf();
  }, [dispatch, getUserSelf]);

  /**
   * While any of this data is still loading, Render Loading App component until everything is loaded
   */
  if (loadingUser || loadingEventInfo) {
    return <ClimbingBoxLoading />;
  }

  if (loginSuccessState) {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <SessionRoutes />
        </ScrollToTop>
      </BrowserRouter>
    );
  } else {
    return (
      <BrowserRouter>
        <NoSessionRoutes />
      </BrowserRouter>
    );
  }
};

export default SessionValidator;

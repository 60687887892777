import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
import {
  CATALOGUES,
  CATALOGUES_FAILED,
  CATALOGUES_SUCCESS,
} from './Constants';

export const catalogues = () => ({ type: CATALOGUES });
export const cataloguesFailed = (error) => ({
  type: CATALOGUES_FAILED,
  data: error,
});
export const cataloguesSuccess = (response) => {
  return {
    type: CATALOGUES_SUCCESS,
    data: response,
  };
};
export const getCatalogues = (cataloguesArray) => {
  return async (dispatch, getState) => {
    dispatch(catalogues());

    try {
      const { catalogues: {
        catalogues: cataloguesPrevState,
      } } = getState();
      // Remove required catalogs that are already loaded
      let cataloguesArrayNeeded = [...cataloguesArray];
      cataloguesArray.forEach(catalogueArray => {
        if (cataloguesPrevState[catalogueArray] !== undefined) {
          cataloguesArrayNeeded.splice(cataloguesArrayNeeded.indexOf(catalogueArray), 1);
        }
      });
      // If there is not a missing catalogArray needed, there is no need to ask them to the API or do anything else
      if (cataloguesArrayNeeded.length > 0) {
        const response = await axios.post(`/catalogues/`, { catalogues: cataloguesArrayNeeded });
        if (response.status === 200) {
          const catalogueResponse = response.data.data;
          dispatch(cataloguesSuccess(catalogueResponse));
        } else {
          throw new Error('Status was not 400');
        }
      }
      
    } catch (error) {
      dispatch(cataloguesFailed(handleAxiosError(error)));
    }
  };
};

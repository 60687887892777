import React from "react";
import Box from "@material-ui/core/Box";
import RolBuyers from "./components/RolBuyers";
import RolSellers from "./components/RolSellers";
import RolAdminOrGod from "./components/RolAdminOrGod/RolAdminOrGod";
import { useSelector } from "react-redux";

const TabPanel = (props) => {
  const { value, role, status } = props;
  const { loggedUser } = useSelector((store) => store.loggedUser);
  const handleEmitTabChange = (tab) => {
    if (props.emitTabChange) {
      props.emitTabChange(tab);
    }
  };

  const renderTable = () => {
    let tableToRender = null;

    switch (role) {
      case "RL005": //COMPRADOR
        tableToRender = (
          <RolBuyers
            status={status}
            userId={loggedUser._id}
            emitTabChange={handleEmitTabChange}
            pageSelected={props.pageSelected}
          />
        );
        break;

      case "RL003": //ASISTENTE = VENDEDOR - FREE & PREMIUM - SOCIO & NO SOCIO
      case "RL004":
        tableToRender = (
          <RolSellers
            status={status}
            userId={loggedUser._id}
            emitTabChange={handleEmitTabChange}
            pageSelected={props.pageSelected}
          />
        );
        break;

      case "RL000": //GOD
      case "RL001": //ADMIN
        tableToRender = (
          <RolAdminOrGod
            status={status}
            emitTabChange={handleEmitTabChange}
            pageSelected={props.pageSelected}
          />
        );
        break;

      default:
        break;
    }
    return tableToRender;
  };

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${role}`}
      aria-labelledby={`simple-tab-${role}`}
    >
      <Box style={{ paddingTop: "16px" }}>{renderTable}</Box>
    </div>
  );
};

export default TabPanel;

import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Modal } from "@material-ui/core";
import ModalCloseButton from "./components/ModalCloseButton";
import TourContainer from "./components/TourContainer";
import ConfirmExit from "./components/ConfirmExit";
import { useHistory } from "react-router-dom";
import urlIsWorking from "../../utils/validations/urlIsWorking";
import getLocalAccessToken from "utils/helpers/getLocalAccessToken";

const VirtualTour = () => {
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const noSessionSiteBgUrl = eventInfo.assets.noSessionSiteBg;

  const [showModal, setShowModal] = useState(true);
  const [showIFrame, setShowIFrame] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [typeOfErrorInRendering, setTypeOfErrorInRendering] = useState(
    "Validando acceso al virtual tour"
  );

  const navigationHistory = useHistory();

  const {
    REACT_APP_API_URL,
    REACT_APP_API_HOST,
    REACT_APP_API_PORT,
    REACT_APP_TOUR_HOST,
    REACT_APP_TOUR_PORT,
    REACT_APP_TOUR_URL,
    REACT_APP_TENANT_CODE,
    REACT_APP_FRONTEND_URL,
  } = process.env;

  const iFrameContainerStyle = {
    height: "100%",
    width: "100%",
  };

  const handleCloseConfirmExit = () => setShowConfirmExit(false);

  const handleOpenConfirmExit = () => setShowConfirmExit(true);

  const { loggedUser } = useSelector((store) => store.loggedUser);

  let renderModal = () => null;

  let acceptConfirmExit = () => null;

  let urlVirtualTour = null,
    urlVirtualTourAccess = null;

  if (loggedUser) {
    const { _id: userId, virtualTour, service } = loggedUser;

    const accessToken = getLocalAccessToken();

    if (service) {
      urlVirtualTourAccess = service.virtualTourUrl;
    }

    if (urlVirtualTourAccess) {
      urlVirtualTour = virtualTour
        ? `${urlVirtualTourAccess}index.htm?sessionToken=${accessToken}` +
          `&userId=${userId}&tourToken=${virtualTour.token}` +
          `&tenant=${REACT_APP_TENANT_CODE}` +
          `&apiUrl=${REACT_APP_API_URL}` +
          `&frontUrl=${REACT_APP_FRONTEND_URL}LogOut`
        : null;
    } else {
      urlVirtualTour = virtualTour
        ? `${REACT_APP_TOUR_URL}index.htm?sessionToken=${accessToken}` +
          `&userId=${userId}&tourToken=${virtualTour.token}` +
          `&tenant=${REACT_APP_TENANT_CODE}` +
          `&apiUrl=${REACT_APP_API_URL}` +
          `&frontUrl=${REACT_APP_FRONTEND_URL}LogOut`
        : null;
    }

    const showIFrameHandler = () => {
      setTypeOfErrorInRendering("");
      setShowIFrame(true);
    };

    const redirectToPreviousPage = () => navigationHistory.goBack();

    const closeModal = () => setShowModal(false);

    acceptConfirmExit = () => {
      redirectToPreviousPage();
      handleCloseConfirmExit();
      closeModal();
    };

    const requestAccessToVirtualTour = () => {
      const httpsRequestParams = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          Code: REACT_APP_TENANT_CODE,
        },
        host: REACT_APP_API_HOST,
        port: REACT_APP_API_PORT,
        method: "PATCH",
        path: `/users/${userId}`,
      };
      return virtualTour
        ? urlIsWorking(httpsRequestParams, { tourToken: virtualTour.token })
        : false;
    };

    const requestIfVirtualTourIsWorking = () => {
      const httpsRequestParams = {
        host: REACT_APP_TOUR_HOST,
        port: REACT_APP_TOUR_PORT,
        method: "GET",
        path: `/index.htm`,
      };
      return urlIsWorking(httpsRequestParams);
    };

    const validateIfVirtualTourIsWorking = async () => {
      try {
        const isWorking = await requestIfVirtualTourIsWorking();
        if (isWorking) {
          showIFrameHandler();
          return;
        }
        setTypeOfErrorInRendering(
          "El virtual tour no está disponible, intente más tarde"
        );
      } catch {
        setTypeOfErrorInRendering(
          "El virtual tour no está disponible, intente más tarde"
        );
      }
    };

    const validateAccessToVirtualTour = async () => {
      try {
        const accessGranted = await requestAccessToVirtualTour();
        if (accessGranted) {
          return validateIfVirtualTourIsWorking();
        }
        setTypeOfErrorInRendering(
          "No cuentas con los permisos suficientes para acceder al virtual tour"
        );
      } catch {
        setTypeOfErrorInRendering(
          "Hubo un error al solicitar el acceso al virtual tour, intente más tarde"
        );
      }
    };

    renderModal = () => {
      validateAccessToVirtualTour();
    };
  }

  const redirectToLogin = useCallback(() => navigationHistory.push("/"), [
    navigationHistory,
  ]);

  const showModalOrPreviousPage = useCallback(() => {
    if (loggedUser) {
      return renderModal();
    }
    redirectToLogin();
  }, [loggedUser, redirectToLogin]);

  useEffect(() => {
    showModalOrPreviousPage();
  }, [showModalOrPreviousPage]);

  let contentToRender = (
    <div
      className="nosession-site-bg-image"
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${noSessionSiteBgUrl})`,
      }}
    >
      <h2 className="text-black font-weight-bold text-center">
        {typeOfErrorInRendering}
      </h2>
    </div>
  );

  if (!typeOfErrorInRendering) {
    contentToRender = (
      <TourContainer urlVirtualTour={urlVirtualTour} showIFrame={showIFrame} />
    );
  }

  return (
    <>
      <Modal open={showModal}>
        <>
          <ConfirmExit
            handleCloseConfirmExit={handleCloseConfirmExit}
            acceptConfirmExit={acceptConfirmExit}
            showConfirmExit={showConfirmExit}
          />
          <div style={iFrameContainerStyle}>
            <ModalCloseButton closeModalHandler={handleOpenConfirmExit} />
            {contentToRender}
          </div>
        </>
      </Modal>
    </>
  );
};

export default VirtualTour;

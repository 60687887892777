import React from "react";
import { useTranslation } from "react-i18next";

import { AvatarUploader, Loader } from "../../../components";
import leftResumeStyles from "../../../components/profile/left-resume/left-resume.module.scss";

const LeftResume = ({
  company: {
    avatar,
    companyName,
    companyWeb,
    appointments: { accepted, pending },
  },
  onAvatarSelected,
  loading,
}) => {
  const { t } = useTranslation("company-details");

  return (
    <div className={leftResumeStyles["left-resume-wrapper"]}>
      <h5 className="text-left mb-4">{t("leftResume.cardTitle")}</h5>
      {loading ? (
        <Loader />
      ) : (
        <div className="avatar-icon-wrapper avatar-icon-xl">
          <div className="d-block p-0 avatar-icon-wrapper m-0">
            <div className="overflow-hidden">
              <div className="avatar-icon">
                <img
                  src={avatar}
                  alt={`${companyName} avatar`}
                  className={leftResumeStyles["avatar-img"]}
                />
              </div>
              <AvatarUploader
                loading={loading}
                onAvatarSelected={onAvatarSelected}
              />
            </div>
          </div>
        </div>
      )}

      <h5 className="font-weight-bold">{companyName}</h5>
      {companyWeb && (
        <p className={leftResumeStyles["email-label"]}>{companyWeb}</p>
      )}

      <>
        <h5 className="font-weight-bold">
          {t("leftResume.myAppointmentsTitle")}
        </h5>
        <div className="d-flex justify-content-center">
          <div>
            <h2>{accepted}</h2>
            <div
              className={`border-success ${leftResumeStyles["severity-appointments-indicator"]}`}
            />
            <p className={leftResumeStyles["appointments-indicator-label"]}>
              {t("leftResume.acceptedAppointmentsLabel")}
            </p>
          </div>

          <div className={leftResumeStyles["severity-separator"]} />

          <div>
            <h2>{pending}</h2>
            <div
              className={`border-warning ${leftResumeStyles["severity-appointments-indicator"]}`}
            />
            <p className={leftResumeStyles["appointments-indicator-label"]}>
              {t("leftResume.pendingAppointmentsLabel")}
            </p>
          </div>
        </div>
      </>
    </div>
  );
};

export default LeftResume;

import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import { Card } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import axios from "../../config/axios";
import PageLayout from "components/PageLayout";
import styles from "./appointment.module.scss";
import LeftResume from "components/public-profile/left-resume/left-resume";
import RightResume from "components/public-profile/right-resume/right-resume";
import TabsInfo from "components/public-profile/right-resume/full-user/tabs-info";
import profileStyles from "../profile/profile.module.scss";

const AppointmentProfile = () => {
  // State
  const { t } = useTranslation(["appointment-profile", "public-profile"]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [vendorUser, setVendorUser] = useState(false);
  const [buyerUser, setBuyerUser] = useState(false);
  const [appointment, setAppointment] = useState(false);
  const [availableHours, setAvailableHours] = useState(false);
  const [enumScreen, setEnumScreen] = useState(false);

  const { appointmentid } = useParams();

  /** Get THIS appointment */
  const getAppointment = useCallback(async () => {
    axios
      .get(`/appointment/${appointmentid}`)
      .then(({ data }) => {
        setAppointment(data.data.appointment);
        setBuyerUser(data.data.appointment.buyer);
        setVendorUser(data.data.user);
        setEnumScreen(data.data.screen);
        setAvailableHours(data.data.availableHours);
      })
      .catch(() => {
        setError(t("public-profile:profileNotFoundLabel"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [appointmentid]);

  useEffect(() => {
    // Get appointment
    getAppointment();
  }, [getAppointment]);

  /**
   * Accepts the current appointment request with this public user
   * HERE: ONLY FOR ADMINS
   */
  const acceptAppointmentRequest = ({ date, startTime, endTime }) => {
    setLoading(true);
    axios
      .post(`/appointment/admin-accept-appointment-request`, {
        date,
        startTime,
        endTime,
        buyerId: buyerUser._id,
        sellerId: vendorUser._id,
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          title: t("public-profile:b2b.acceptAppointmentTitle"),
          text: `${t("public-profile:b2b.acceptAppointmentSent")} ${vendorUser.firstName} ${vendorUser.lastName}`,
        });
        setVendorUser(data.data.user);
        setEnumScreen(data.data.screen);
        setAppointment(data.data.appointment);
        setAvailableHours(data.data.availableHours);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          title: t("public-profile:b2b.acceptAppointmentErrorTitle"),
          text: `${t("public-profile:b2b.acceptAppointmentSendFailed")} ${vendorUser.firstName} ${vendorUser.lastName}. ${t("b2b.acceptAppointmentError")}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Rejects the current appointment request with this public user
   * HERE: ONLY FOR ADMINS
   */
  const rejectAppointmentRequest = () => {
    Swal.fire({
      title: t("public-profile:b2b.rejectAppointmentPrompt"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("public-profile:b2b.rejectAppointmentConfirmBtn"),
      cancelButtonText: t("public-profile:b2b.rejectAppointmentCancelBtn"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .post(`/appointment/admin-reject-appointment-request`, {
            buyerId: buyerUser._id,
            sellerId: vendorUser._id,
          })
          .then(({ data }) => {
            Swal.fire(t("public-profile:b2b.rejectAppointmentSuccess"), "", "success");
            setVendorUser(data.data.user);
            setEnumScreen(data.data.screen);
            setAppointment(data.data.appointment);
            setAvailableHours(data.data.availableHours);
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: t("public-profile:b2b.rejectAppointmentErrorTitle"),
              text: `${t("public-profile:b2b.rejectAppointmentError1")} ${vendorUser.firstName} ${vendorUser.lastName}. ${t("public-profile:b2b.rejectAppointmentError2")}`,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  /**
   * Cancels the current appointment request with this public user
   * HERE: ONLY FOR ADMINS
   */
  const cancelAcceptedAppointment = () => {
    Swal.fire({
      title: t("public-profile:b2b.cancelAppointmentPrompt"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("public-profile:b2b.cancelAppointmentConfirmBtn"),
      cancelButtonText: t("public-profile:b2b.cancelAppointmentCancelBtn"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .post(`/appointment/admin-cancel-appointment-request`, {
            buyerId: buyerUser._id,
            sellerId: vendorUser._id,
          })
          .then(({ data }) => {
            Swal.fire(t("public-profile:b2b.cancelAppointmentSuccess"), "", "success");
            setVendorUser(data.data.user);
            setEnumScreen(data.data.screen);
            setAppointment(data.data.appointment);
            setAvailableHours(data.data.availableHours);
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: t("public-profile:b2b.cancelAppointmentErrorTitle"),
              text: `${t("public-profile:b2b.cancelAppointmentError1")} ${vendorUser.firstName} ${vendorUser.lastName}. ${t("public-profile:b2b.cancelAppointmentError2")}`,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  return (
    <PageLayout
      errorMessage={error}
      loading={loading}
      error={error}
      titleHeading={
        vendorUser
          ? t("appointment-profile:pageTitle")
          : error
          ? t("appointment-profile:profileNotFoundLabel")
          : t("appointment-profile:loadingProfileLabel")
      }
    >
      <div className={profileStyles["content-wrapper"]}>
        <Card className={profileStyles["new-profile-wrapper"]}>
          <div className={styles["resume-form-container"]}>
            <LeftResume
              user={vendorUser}
              appointment={appointment}
              screen={enumScreen}
              availableHours={availableHours}
              acceptAppointmentRequest={acceptAppointmentRequest}
              rejectAppointmentRequest={rejectAppointmentRequest}
              cancelAcceptedAppointment={cancelAcceptedAppointment}
            />

            <div className={profileStyles["resume-form-separator"]} />

            <RightResume user={vendorUser} screen={enumScreen} />
          </div>
        </Card>

        <TabsInfo user={vendorUser} />
      </div>
    </PageLayout>
  );
};

export default AppointmentProfile;

import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

import "./ChooseServiceCard.module.scss";
import RoleInfoDialog from "../ChooseRole/RoleInfoDialog";

const ChooseServiceCardBenefitsList = ({ benefits, actionRegister, color }) => {
  const { t } = useTranslation("choose-service-card");
  const [dialog, setDialog] = React.useState(false);
  const toggleDialog = () => setDialog(!dialog);

  return (
    <div>
      <RoleInfoDialog
        dialog={dialog}
        toggleDialog={toggleDialog}
        benefits={benefits}
        goRegister={actionRegister}
        color={color}
      />
      <ul>
        {benefits.length &&
          benefits.map((benefit, i) => {
            if (i <= 2) {
              return (
                <li style={{ fontSize: "14px" }} key={i}>
                  - {benefit}
                </li>
              );
            }
            return null;
          })}
      </ul>
      {benefits.length > 3 ? (
        <div className="d-flex justify-content-center">
          <Button
            size="large"
            variant="outlined"
            style={{
              fontWeight: "bold",
              marginTop: "auto",
              marginBottom: 10,
            }}
            onClick={() => {
              setDialog(true);
            }}
          >
            {t("moreInformation")}
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default ChooseServiceCardBenefitsList;

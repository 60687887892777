import React, { Fragment } from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { connect, useSelector, useDispatch } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import { Avatar, Box, Button } from "@material-ui/core";

import defaultUser from "../../assets/images/avatars/user-default.png";
import refreshLoggedUser from "../../utils/refreshLoggedUser";
import { loginFailed, loginSuccess } from "../../redux/actions";

const SidebarUserbox = (props) => {
  const { i18n, t } = useTranslation("dashboard");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((store) => store.loggedUser);

  const { sidebarToggle, sidebarHover } = props;

  if (!loggedUser) return;

  return (
    <Fragment>
      <Box
        className={clsx("app-sidebar-userbox", {
          "app-sidebar-userbox--collapsed": sidebarToggle && !sidebarHover,
        })}
      >
        <Avatar
          alt={loggedUser && loggedUser.firstName ? loggedUser.firstName : ""}
          src={
            loggedUser && loggedUser.avatar ? loggedUser.avatar : defaultUser
          }
          className="app-sidebar-userbox-avatar"
        />
        <Box className="app-sidebar-userbox-name">
          <Box>
            <div
              style={{
                fontWeight: "bold",
                height: "auto",
                whiteSpace: "pre-wrap",
              }}
            >
              <p>
                {loggedUser.firstName} {loggedUser.lastName}
              </p>
            </div>
          </Box>
          <Box className="app-sidebar-userbox-description">
            {loggedUser.role && loggedUser.role.name[currentLanguage]
              ? loggedUser.role.name[currentLanguage]
              : ""}
          </Box>

          {loggedUser && loggedUser.role && loggedUser.role.code !== "RL001" ? (
            <Box className="app-sidebar-userbox-btn-profile" on="true">
              <Button
                style={{ backgroundColor: "#3FE3B2" }}
                size="small"
                variant="contained"
                className="text-white"
                component={RouterLink}
                onClick={() => {
                  refreshLoggedUser(dispatch, loginSuccess, loginFailed);
                }}
                to="/profile"
              >
                {t("profileButton")}
              </Button>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(SidebarUserbox);

import {
  ADDITIONAL_INFO,
  ADDITIONAL_INFO_FAILED,
  ADDITIONAL_INFO_SUCCESS,
  RESET,
} from "../actions";

const initialState = {
  additionalInfo: {},
  loading: false,
  error: false,
  success: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- SAVE USER ADDITIONAL INFO -----
    case ADDITIONAL_INFO:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ADDITIONAL_INFO_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case ADDITIONAL_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        additionalInfo: action.data,
      };

    case RESET:
      return {
        success: false,
        error: false,
      };
    default:
      return state;
  }
}

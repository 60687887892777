import { useSelector } from "react-redux";
import clsx from "clsx";
import { connect } from "react-redux";
import React, { Fragment } from "react";
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const styleText = {
  color: "black",
};

const Footer = (props) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const { footerShadow, sidebarToggle, footerFixed, showShadow } = props;
  const { eventInfo } = useSelector((store) => store.eventInfo);

  const getCopyText = () => {
    switch (currentLanguage) {
      case "es":
        return "Todos los derechos reservados";
      case "en":
        return "All rights reserved";
      default:
        return "";
    }
  };

  const footerCopyText = (
    <p className="footer-rights">
      {getCopyText()} {eventInfo.footerRightsText[currentLanguage]} &copy;{" "}
      {new Date().getFullYear()}
    </p>
  );
  return (
    <Fragment>
      {showShadow ? (
        <Paper
          square
          elevation={footerShadow ? 11 : 2}
          className={clsx("app-footer text-black-50", {
            "app-footer--fixed": footerFixed,
            "app-footer--fixed__collapsed": sidebarToggle,
          })}
        >
          <div className="app-footer--inner">
            <div
              className="app-footer--second font-weight-bold"
              style={styleText}
            >
              {footerCopyText}
            </div>
          </div>
        </Paper>
      ) : (
        <div className="app-footer--inner">
          <div
            className="app-footer--second font-weight-bold"
            style={styleText}
          >
            {footerCopyText}
          </div>
        </div>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});
export default connect(mapStateToProps)(Footer);

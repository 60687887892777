import React from "react";
import PropTypes from "prop-types";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = ({ severity, message, insertNewLines = false }) => {
  return (
    <MuiAlert
      className="py-1 mb-4 d-flex justify-content-center align-items-center"
      severity={severity}
    >
      <span
        className="font-weight-bold"
        style={{ whiteSpace: insertNewLines ? "pre-wrap" : "normal" }}
      >
        {message}
      </span>
    </MuiAlert>
  );
};

Alert.propTypes = {
  severity: PropTypes.string.isRequired,
  message: PropTypes.any.isRequired,
};

export default Alert;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import CloseRounded from "@material-ui/icons/CloseRounded";
import { Grid, TextField, MenuItem, Fab, Button } from "@material-ui/core";

import {
  required,
  minLength,
  string,
  maxLength,
  validEmail,
  validPhoneFlag,
  validCellPhoneFlag,
  validRfc,
  webpage,
} from "utils/validations";
import convertToBase64 from "utils/convertToBase64";
import {
  reset,
  updateUserSelf,
  loginSuccess,
  updateUserData,
} from "redux/actions";
import validateFileSize from "utils/validate-file-size";
import truncateString from "utils/truncate-string";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";
import { PhoneInput, AttachedFile, FileDropzone, Loader } from "../..";

import styles from "./right-form.module.scss";

const RightForm = ({ userData = {} }) => {
  const { i18n, t } = useTranslation("profile");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const { personTypes, roleId, providerTypes } = useUserCatalogs();

  const [editMode, setEditMode] = useState(false);

  const [deleteArchive, setDeleteArchive] = useState(true);
  const [deleteLogo, setDeleteLogo] = useState(true);
  const styleDivDelete = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const { loading, updateSuccess, updatedUserSelf, updatedUser } = useSelector(
    (store) => store.user
  );
  const { loggedUser } = useSelector((store) => store.loggedUser);

  const [phoneCountryData, setPhoneCountryData] = useState({
    dialCode: "",
  });
  const [cellphoneCountryData, setCellphoneCountryData] = useState({
    dialCode: "",
  });

  // Validate if user is provided by admin on public profile or logged user is trying to update its own profile
  const isAdminUpdatingPublicUser = Object.keys(userData).length;

  let userDataToUpdate = {
    role: { _id: "" },
    contact: {
      userPhone: "",
      phoneDialCode: "",
      userCellphone: "",
      cellphoneDialCode: "",
    },
  };
  if (!isAdminUpdatingPublicUser) {
    userDataToUpdate = { ...userDataToUpdate, ...loggedUser };

    userDataToUpdate.role._id = loggedUser.role._id;
    userDataToUpdate.contact.userPhone = loggedUser.contact.phone;
    userDataToUpdate.contact.phoneDialCode = loggedUser.contact.phoneDialCode;
    userDataToUpdate.contact.userCellphone = loggedUser.contact.cellphone;
    userDataToUpdate.contact.cellphoneDialCode =
      loggedUser.contact.cellphoneDialCode;
  } else {
    userDataToUpdate = { ...userDataToUpdate, ...userData };
    userDataToUpdate.role._id = userData.role._id;
    userDataToUpdate.contact.userPhone = userData.contact.phone;
    userDataToUpdate.contact.phoneDialCode = userData.contact.phoneDialCode;
    userDataToUpdate.contact.userCellphone = userData.contact.cellphone;
    userDataToUpdate.contact.cellphoneDialCode =
      userData.contact.cellphoneDialCode;
  }

  const {
    role: { _id: loggedUserRoleId },
    contact: { userPhone, phoneDialCode, userCellphone, cellphoneDialCode },
  } = userDataToUpdate;

  const defaultUser = {
    ...userDataToUpdate,
    phone: userDataToUpdate.contact.phone,
    cellphone: userDataToUpdate.contact.cellphone,
  };

  // Populate user to update with values from db
  const [userToUpdate, setUserToUpdate] = useState({
    ...userDataToUpdate,
    phoneDialCode,
    phone: userPhone,
    cellphone: userCellphone,
    cellphoneDialCode,

    brochure: { base64: "", fileName: "" },
    companyLogo: { base64: "", fileName: "" },
  });

  const {
    firstName,
    lastName,
    position,
    email,
    phone,
    cellphone,
    comercialName,
    personType,
    businessName,
    rfc,
    companyWeb,
    youtube,
    providerType,
    videoConferenceUrl,
    needs,
    companyPresentation: {
      brochure: { name: brochureName, url: brochureUrl },
      companyLogo: { name: companyLogoName, url: companyLogoUrl },
    },
    brochure: { fileName: brochureUploadedFileName },
    companyLogo: { fileName: companyLogoUploadedFileName },
  } = userToUpdate;
  const isValidForm =
    !required(firstName) ||
    !minLength(3)(firstName) ||
    !maxLength(25)(firstName) ||
    !string(firstName) ||
    !required(lastName) ||
    !minLength(3)(lastName) ||
    !maxLength(25)(lastName) ||
    !string(lastName) ||
    !required(position) ||
    !minLength(3)(position) ||
    !maxLength(50)(position) ||
    !string(position) ||
    validCellPhoneFlag(cellphone) ||
    !required(rfc) ||
    !validRfc(rfc) ||
    !required(needs) ||
    !minLength(3)(needs) ||
    !string(needs);
  const isRoleBuyer = loggedUserRoleId === roleId.BUYER;

  /* ----- EFFECTS ----- */
  // Populate company data if user is a buyer depending on own or public profile
  useEffect(() => {
    if (isRoleBuyer) {
      if (!isAdminUpdatingPublicUser) {
        const {
          comercialName,
          businessName,
          rfc,
          companyWeb,
        } = loggedUser.companyId;
        setUserToUpdate({
          ...userToUpdate,
          comercialName,
          businessName,
          rfc,
          companyWeb,
        });
      } else {
        const {
          comercialName,
          businessName,
          rfc,
          companyWeb,
        } = userDataToUpdate.companyId;
        setUserToUpdate({
          ...userToUpdate,
          comercialName,
          businessName,
          rfc,
          companyWeb,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Clean errors/success indicators when edit mode is ON
  useEffect(() => {
    if (editMode) {
      dispatch(reset());
    }
  }, [editMode, dispatch]);
  useEffect(() => {
    if (editMode) {
      setDeleteArchive(true);
      setDeleteLogo(true);
    }
  }, [editMode]);
  // Set dialCode on user object to send it to backend
  useEffect(() => {
    const { dialCode: phoneDialCode } = phoneCountryData;
    const { dialCode } = cellphoneCountryData;

    if (phoneDialCode) {
      setUserToUpdate({ ...userToUpdate, phoneDialCode });
    }
    if (dialCode) {
      setUserToUpdate({ ...userToUpdate, cellphoneDialCode: dialCode });
    }
  }, [phoneCountryData, cellphoneCountryData]);
  // Disable edit mode when user was successfuly updated
  useEffect(() => {
    if (updateSuccess) {
      setEditMode(false);
      // Update user without refresh with updated response only if logged user is updating own profile
      if (!isAdminUpdatingPublicUser) {
        dispatch(loginSuccess(updatedUserSelf));
        setUserToUpdate({ ...userToUpdate, ...updatedUserSelf });
      } else {
        setUserToUpdate({ ...userToUpdate, ...updatedUser });
      }
      Swal.fire({
        icon: "success",
        title: t("saveSuccess"),
        timer: 3000,
      });
      dispatch(reset());
    }
  }, [
    updateSuccess,
    dispatch,
    isAdminUpdatingPublicUser,
    updatedUser,
    updatedUserSelf,
    userToUpdate,
  ]);

  /* ----- FUNCTIONS ----- */
  const handleInputChange = (inputEvent) => {
    setUserToUpdate({
      ...userToUpdate,
      [inputEvent.target.name]: inputEvent.target.value,
    });
  };
  const onDropFile = async (files, fieldName, maxFileSize) => {
    const selectedFile = files[0];

    const isValidSizeFile = validateFileSize(selectedFile.size, maxFileSize);

    // Validate max file size, clean dropped file in case it's invalid
    if (isValidSizeFile) {
      let base64File = undefined;
      try {
        base64File = await convertToBase64(selectedFile);
        setUserToUpdate({
          ...userToUpdate,
          [fieldName]: { base64: base64File, fileName: selectedFile.name },
        });
      } catch (error) {
        throw new Error(error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Archivo muy pesado",
        text: `El tamaño máximo permitido del archivo es de ${maxFileSize}MB, inténtalo de nuevo.`,
      });

      setUserToUpdate({
        ...userToUpdate,
        [fieldName]: { base64: "", fileName: "" },
      });

      return;
    }
  };
  const onCancelUpload = () => {
    //console.log("ON CANCEL UPLOAD")
  };
  const deleteConfirmImage = () => {
    Swal.fire({
      title: "¿Estás seguro que deseas eliminar el logotipo?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Sí, eliminar`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        userToUpdate.delete = "companyLogo";
        updateUser(userToUpdate);
        userToUpdate.delete = null;
        userToUpdate.companyLogo.base64 = "";
        userToUpdate.companyLogo.fileName = "";
      }
    });
  };
  const deleteConfirmPdf = () => {
    Swal.fire({
      title: "¿Estás seguro que deseas eliminar el pdf?",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: `Sí, eliminar`,
      cancelButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        userToUpdate.delete = "brochure";
        setDeleteArchive(false);
        updateUser(userToUpdate);
        userToUpdate.delete = null;
        userToUpdate.brochure.base64 = "";
        userToUpdate.brochure.fileName = "";
      }
    });
  };
  const cancelEditUser = () => {
    setUserToUpdate({ ...userToUpdate, ...defaultUser });
    setEditMode(false);
  };
  const updateUser = (user, validateRequiredFields = false) => {
    const newUser = { ...user };

    if (!isAdminUpdatingPublicUser) {
      dispatch(updateUserSelf(newUser, validateRequiredFields));
    } else {
      dispatch(updateUserData(newUser._id, newUser, validateRequiredFields));
    }
  };
  const LoadingIndicator = () => {
    if (loading) {
      return (
        <div className="mt-4">
          <Loader />
        </div>
      );
    }
    return null;
  };
  return (
    <div className={styles["right-form-wrapper"]}>
      <h4>{t("rightResume.cardTitle")}</h4>
      <div className="d-flex flex-column">
        <div className="mr-3">
          <>
            <Grid container spacing={4}>
              <Grid item className="w-50">
                <TextField
                  type="text"
                  value={firstName}
                  id="firstName"
                  name="firstName"
                  label={t("rightForm.firstName")}
                  placeholder={t("rightForm.firstNamePlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading}
                  onChange={handleInputChange}
                  error={
                    !required(firstName) ||
                    !minLength(3)(firstName) ||
                    !maxLength(25)(firstName) ||
                    !string(firstName)
                  }
                  helperText={
                    !required(firstName) ||
                    !minLength(3)(firstName) ||
                    !maxLength(25)(firstName) ||
                    !string(firstName)
                      ? t("rightForm.firstNameError")
                      : null
                  }
                />
              </Grid>
              <Grid item className="w-50">
                <TextField
                  type="text"
                  value={lastName}
                  id="lastName"
                  name="lastName"
                  label={t("rightForm.lastName")}
                  placeholder={t("rightForm.lastNamePlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading}
                  onChange={handleInputChange}
                  error={
                    !required(lastName) ||
                    !minLength(3)(lastName) ||
                    !maxLength(25)(lastName) ||
                    !string(lastName)
                  }
                  helperText={
                    !required(lastName) ||
                    !minLength(3)(lastName) ||
                    !maxLength(25)(lastName) ||
                    !string(lastName)
                      ? t("rightForm.lastNameError")
                      : null
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} className="mt-1">
              <Grid item className="w-50">
                <TextField
                  type="text"
                  value={position}
                  id="position"
                  name="position"
                  label={t("rightForm.position")}
                  placeholder={t("rightForm.positionPlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading}
                  onChange={handleInputChange}
                  error={
                    position.length
                      ? !required(position) ||
                        !minLength(3)(position) ||
                        !maxLength(50)(position) ||
                        !string(position)
                      : false
                  }
                  helperText={
                    position.length
                      ? !required(position) ||
                        !minLength(3)(position) ||
                        !maxLength(50)(position) ||
                        !string(position)
                        ? t("rightForm.positionError")
                        : null
                      : null
                  }
                />
              </Grid>

              <Grid item className="w-50">
                <TextField
                  type="email"
                  value={email}
                  id="email"
                  name="email"
                  label={t("rightForm.email")}
                  placeholder={t("rightForm.emailPlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled
                  onChange={handleInputChange}
                  error={
                    email && email.length > 0
                      ? !required(email) ||
                        !validEmail(email) ||
                        !maxLength(40)(email)
                      : false
                  }
                  helperText={
                    email && email.length > 0
                      ? !required(email) ||
                        !validEmail(email) ||
                        !maxLength(40)(email)
                        ? t("rightForm.emailError")
                        : null
                      : null
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} className="mt-1">
              <Grid item className="w-50">
                <PhoneInput
                  dataTestId="phone"
                  inputLabel={t("rightForm.phone")}
                  value={phone}
                  valueName="phone"
                  previousState={userToUpdate}
                  setValue={setUserToUpdate}
                  placeholder={t("rightForm.phonePlaceholder")}
                  error={validPhoneFlag(phone)}
                  helperText={t("rightForm.phoneError")}
                  setCountryData={setPhoneCountryData}
                  disabled={!editMode || loading}
                />
              </Grid>
              <Grid item className="w-50">
                <PhoneInput
                  dataTestId="cellphone"
                  inputLabel={t("rightForm.cellphone")}
                  value={cellphone}
                  valueName="cellphone"
                  previousState={userToUpdate}
                  setValue={setUserToUpdate}
                  placeholder={t("rightForm.cellphonePlaceholder")}
                  error={validCellPhoneFlag(cellphone)}
                  helperText={t("rightForm.cellphoneError")}
                  setCountryData={setCellphoneCountryData}
                  disabled={!editMode || loading}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} className="mt-1">
              <Grid item className="w-50">
                <TextField
                  type="text"
                  value={comercialName}
                  id="comercialName"
                  name="comercialName"
                  label={t("rightForm.comercialName")}
                  placeholder={t("rightForm.comercialNamePlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading || isRoleBuyer}
                  onChange={handleInputChange}
                  error={
                    comercialName && comercialName.length > 0
                      ? !required(comercialName) ||
                        !minLength(3)(comercialName) ||
                        !maxLength(40)(comercialName) ||
                        !string(comercialName)
                      : false
                  }
                  helperText={
                    comercialName && comercialName.length > 0
                      ? !required(comercialName) ||
                        !minLength(3)(comercialName) ||
                        !maxLength(40)(comercialName) ||
                        !string(comercialName)
                        ? "Ingresa Nombre y Marca Comercial válido. Mínimo 3 caracteres, máximo 40."
                        : null
                      : null
                  }
                />
              </Grid>
              <Grid item className="w-50">
                <TextField
                  id="personType"
                  name="personType"
                  label={t("rightForm.personType")}
                  fullWidth
                  disabled={!editMode || loading}
                  select
                  value={personType}
                  onChange={handleInputChange}
                >
                  {personTypes.array.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label[currentLanguage]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={4} className="mt-1">
              <Grid item className="w-50">
                <TextField
                  type="text"
                  value={businessName}
                  id="businessName"
                  name="businessName"
                  label={t("rightForm.businessName")}
                  placeholder={t("rightForm.businessNamePlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading || isRoleBuyer}
                  onChange={handleInputChange}
                  error={
                    businessName && businessName.length > 0
                      ? !required(businessName) ||
                        !minLength(3)(businessName) ||
                        !maxLength(40)(businessName) ||
                        !string(businessName)
                      : false
                  }
                  helperText={
                    businessName && businessName.length > 0
                      ? !required(businessName) ||
                        !minLength(3)(businessName) ||
                        !maxLength(40)(businessName) ||
                        !string(businessName)
                        ? t("rightForm.businessNameError")
                        : null
                      : null
                  }
                />
              </Grid>
              <Grid item className="w-50">
                <TextField
                  type="text"
                  id="rfc"
                  name="rfc"
                  label={t("rightForm.rfc")}
                  placeholder={t("rightForm.rfcPlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading || isRoleBuyer}
                  error={rfc.length ? !required(rfc) || !validRfc(rfc) : false}
                  helperText={
                    rfc.length
                      ? !required(rfc) || !validRfc(rfc)
                        ? t("rightForm.rfcError")
                        : null
                      : null
                  }
                  value={rfc}
                  onChange={handleInputChange}
                />
              </Grid>
            </Grid>

            <Grid container spacing={4} className="mt-1">
              <Grid item className="w-50">
                <TextField
                  type="text"
                  value={companyWeb}
                  id="companyWeb"
                  name="companyWeb"
                  label={t("rightForm.companyWeb")}
                  placeholder={t("rightForm.companyWebPlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading || isRoleBuyer}
                  onChange={handleInputChange}
                  error={
                    companyWeb && companyWeb.length > 0
                      ? !webpage(companyWeb) ||
                        !minLength(3)(companyWeb) ||
                        !maxLength(40)(companyWeb)
                      : false
                  }
                  helperText={
                    companyWeb && companyWeb.length > 0
                      ? !webpage(companyWeb)
                        ? t("rightForm.companyWebError1")
                        : !minLength(3)(companyWeb)
                        ? t("rightForm.companyWebError2")
                        : !maxLength(40)(companyWeb)
                        ? t("rightForm.companyWebError3")
                        : null
                      : null
                  }
                />
              </Grid>
              <Grid item className="w-50">
                <TextField
                  type="text"
                  value={youtube}
                  id="youtube"
                  name="youtube"
                  label={t("rightForm.youtube")}
                  placeholder={t("rightForm.youtubePlaceholder")}
                  autoComplete="off"
                  fullWidth
                  disabled={!editMode || loading || isRoleBuyer}
                  onChange={handleInputChange}
                  error={
                    youtube && youtube.length > 0
                      ? !webpage(youtube) ||
                        !minLength(3)(youtube) ||
                        !maxLength(40)(youtube)
                      : false
                  }
                  helperText={
                    youtube && youtube.length > 0
                      ? !webpage(youtube)
                        ? t("rightForm.youtubeError1")
                        : !minLength(3)(youtube)
                        ? t("rightForm.youtubeError2")
                        : !maxLength(40)(youtube)
                        ? t("rightForm.youtubeError3")
                        : null
                      : null
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={4} className="mt-1">
              <Grid item className="w-50">
                <TextField
                  id="providerType"
                  name="providerType"
                  label={t("rightForm.providerType")}
                  fullWidth
                  disabled={!editMode || loading}
                  select
                  value={providerType || ""}
                  onChange={handleInputChange}
                >
                  {providerTypes.array.map((option) => (
                    <MenuItem
                      key={option.value[currentLanguage]}
                      value={option.value[currentLanguage]}
                    >
                      {option.label[currentLanguage]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            {/* Only show this field when user has buyer role */}
            {isRoleBuyer ? (
              <>
                <Grid container spacing={4} className="mt-1">
                  <Grid item className="w-100">
                    <TextField
                      inputProps={{
                        "data-testid": "needs-buyer",
                      }}
                      type="text"
                      value={needs}
                      name="needs"
                      label={t("rightForm.needsBuyer")}
                      placeholder={t("rightForm.needsBuyerPlaceholder")}
                      autoComplete="off"
                      maxRows="4"
                      multiline
                      fullWidth
                      disabled={!editMode || loading}
                      onChange={handleInputChange}
                      error={
                        needs.length
                          ? !required(needs) ||
                            !minLength(3)(needs) ||
                            !string(needs)
                          : false
                      }
                      helperText={
                        needs.length
                          ? !required(needs) ||
                            !minLength(3)(needs) ||
                            !string(needs)
                            ? t("rightForm.needsError")
                            : null
                          : null
                      }
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container spacing={4} className="mt-1">
                  <Grid item className="w-100">
                    <TextField
                      type="text"
                      value={needs}
                      id="needs"
                      name="needs"
                      label={t("rightForm.needsProvider")}
                      placeholder={t("rightForm.needsProviderPlaceholder")}
                      autoComplete="off"
                      maxRows="4"
                      multiline
                      fullWidth
                      disabled={!editMode || loading}
                      onChange={handleInputChange}
                      error={
                        needs.length
                          ? !required(needs) ||
                            !minLength(3)(needs) ||
                            !string(needs)
                          : false
                      }
                      helperText={
                        needs.length
                          ? !required(needs) ||
                            !minLength(3)(needs) ||
                            !string(needs)
                            ? t("rightForm.needsError")
                            : null
                          : null
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4} className="mt-3">
                  <Grid item className="w-50">
                    <p className={styles["form-label"]}>
                      {t("rightForm.brochureName")}
                    </p>
                    {editMode ? (
                      <>
                        {deleteArchive && brochureName !== "" ? (
                          <div style={styleDivDelete}>
                            <AttachedFile
                              icon="pdf"
                              fileName={truncateString(brochureName, 20)}
                              fileUrl={brochureUrl}
                            />
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <ModalCloseButton
                                dataTestId="brochure-delete-option"
                                styles={{
                                  marginLeft: "0px",
                                  marginTop: "-20px",
                                }}
                                closeModalHandler={deleteConfirmPdf}
                              />
                            </div>
                          </div>
                        ) : (
                          <FileDropzone
                            dataTestId="brochure-dropzone"
                            label={
                              brochureUploadedFileName
                                ? brochureUploadedFileName
                                : t("rightForm.brochureNameUpload")
                            }
                            disabled={loading}
                            fileTypesAccepted=".pdf"
                            onDrop={(files) =>
                              onDropFile(files, "brochure", 25)
                            }
                            onCancelUpload={onCancelUpload}
                          />
                        )}
                      </>
                    ) : (
                      <AttachedFile
                        dataTestId="brochure-attached"
                        icon="pdf"
                        fileName={truncateString(brochureName, 20)}
                        fileUrl={brochureUrl}
                      />
                    )}
                  </Grid>

                  <Grid item className="w-50">
                    <p className={styles["form-label"]}>
                      {t("rightForm.companyLogo")}
                    </p>
                    {editMode ? (
                      <>
                        {companyLogoName !== "" && deleteLogo ? (
                          <div style={styleDivDelete}>
                            <div
                              style={{
                                height: "80px",
                                maxHeight: "80px",
                                margin: "0 auto",
                                display: "flex",
                              }}
                            >
                              <img
                                alt={companyLogoName}
                                src={companyLogoUrl}
                                style={{
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <ModalCloseButton
                                  dataTestId="company-logo-delete-option"
                                  styles={{
                                    marginLeft: "-10px",
                                    marginTop: "-3px",
                                  }}
                                  closeModalHandler={deleteConfirmImage}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <FileDropzone
                            dataTestId="company-logo-dropzone"
                            label={
                              companyLogoUploadedFileName
                                ? companyLogoUploadedFileName
                                : t("rightForm.companyLogoUpload")
                            }
                            disabled={loading}
                            fileTypesAccepted=".jpg, .jpeg, .png"
                            onDrop={(files) =>
                              onDropFile(files, "companyLogo", 10)
                            }
                            onCancelUpload={onCancelUpload}
                          />
                        )}
                      </>
                    ) : companyLogoUrl ? (
                      <div
                        data-testid="company-logo-attached"
                        style={{
                          height: "80px",
                          maxHeight: "80px",
                          margin: "0 auto",
                        }}
                      >
                        <img
                          alt={companyLogoName}
                          src={companyLogoUrl}
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    ) : (
                      <small
                        data-testid="company-logo-attached"
                        className="text-dark font-italic"
                      >
                        {t("rightForm.noFile")}
                      </small>
                    )}
                  </Grid>
                </Grid>
              </>
            )}

            <LoadingIndicator />

            <div className="d-flex justify-content-end">
              {editMode ? (
                <div>
                  <Button
                    variant="contained"
                    className="font-weight-bold mt-4 mr-2 text-primary custom-secondary-btn"
                    onClick={() => cancelEditUser()}
                    disabled={loading}
                  >
                    {t("cancelUpdateButtonLabel")}
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    className="font-weight-bold mt-4 custom-primary-btn"
                    onClick={() => updateUser(userToUpdate, true)}
                    disabled={loading || isValidForm}
                  >
                    {t("saveUpdateButtonLabel")}
                  </Button>
                </div>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  className="font-weight-bold mt-5 align-self-end custom-primary-btn"
                  onClick={() => setEditMode(true)}
                >
                  {t("updateButtonLabel")}
                </Button>
              )}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default RightForm;

const ModalCloseButton = (props) => {
  const { closeModalHandler } = props;
  const { styles, dataTestId } = props;

  const closeButtonStyle = {
    boxShadow: "0px 0px 0px 0px",
    minHeight: "10px",
    height: "15px",
    width: "15px",
    zIndex: "999",
    padding: "4px",
    backgroundColor: "red",
    position: "relative",
    marginLeft: styles.marginLeft,
    marginTop: styles.marginTop,
  };

  return (
    <div data-testid={dataTestId}>
      <Fab
        size="small"
        style={closeButtonStyle}
        color="primary"
        onClick={closeModalHandler}
      >
        <CloseRounded />
      </Fab>
    </div>
  );
};

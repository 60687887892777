import React, { useState } from "react";
import Swal from "sweetalert2";
import { Button } from "@material-ui/core";

import { DynamicCalendar } from "components";
import { useTranslation } from "react-i18next";

const BuyerCalendar = ({
  availableHours,
  acceptAppointmentRequest,
  rejectAppointmentRequest,
}) => {
  const { t } = useTranslation("public-profile");
  const [appointmentDatetime, setAppointmentDatetime] = useState(false);
  const selectAppointment = ({ date, startTime, endTime }) => {
    setAppointmentDatetime({
      date,
      startTime,
      endTime,
    });
  };
  const sendAcceptAppointmentRequest = () => {
    if (!appointmentDatetime) {
      Swal.fire({
        icon: "error",
        title: t("b2b.acceptAppointmentErrorTitle"),
        text: t("b2b.acceptAppointmentErrorSelectHour"),
      });
      return;
    } else {
      Swal.fire({
        title: t("b2b.acceptAppointmentPrompt"),
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: t("b2b.acceptAppointmentConfirmBtn"),
        cancelButtonText: t("b2b.acceptAppointmentCancelBtn"),
      }).then((result) => {
        if (result.isConfirmed) {
          acceptAppointmentRequest(appointmentDatetime);
        }
      });
    }
  };
  return (
    <div data-testid="buyer-calendar">
      <DynamicCalendar
        calendarSchedule={
          availableHours && availableHours.seller ? availableHours.seller : []
        }
        buyerSchedule={
          availableHours && availableHours.buyer ? availableHours.buyer : []
        }
        selectAppointment={selectAppointment}
      />
      <div className="mt-4 text-center">
        <Button className="mx-1" onClick={rejectAppointmentRequest}>
          <span className="btn-wrapper--label">{t("b2b.rejectSendBtn")}</span>
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="mx-1 custom-primary-btn"
          onClick={sendAcceptAppointmentRequest}
        >
          <span className="btn-wrapper--label">{t("b2b.acceptSendBtn")}</span>
        </Button>
      </div>
    </div>
  );
};

export default BuyerCalendar;

import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
import {
  UPDATE_USER_SELF_DETAILS,
  UPDATE_USER_SELF_DETAILS_FAILED,
  UPDATE_USER_SELF_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_FAILED,
  UPDATE_USER_DETAILS_SUCCESS,
} from "./Constants";

// -- Update user details --
export const updateUserSelfDetails = () => ({ type: UPDATE_USER_SELF_DETAILS });
export const updateUserSelfDetailsFailed = (error) => ({
  type: UPDATE_USER_SELF_DETAILS_FAILED,
  data: error,
});
export const updateUserSelfDetailsSuccess = (response) => ({
  type: UPDATE_USER_SELF_DETAILS_SUCCESS,
  data: response,
});

export const updateUserDetailsSelf = (
  data,
  validateRequiredFields = false
) => async (dispatch) => {
  dispatch(updateUserSelfDetails());

  try {
    const response = await axios.put(`/users/user-self`, {
      ...data,
      validateRequiredFields,
    });

    if (response.status === 200) {
      const updatedUserDetailsSelf = response.data.data;
      dispatch(updateUserSelfDetailsSuccess(updatedUserDetailsSelf));
    } else {
      dispatch(updateUserSelfDetailsFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(updateUserSelfDetailsFailed(handleAxiosError(error)));
  }
};

// -- Update user details by admin--
export const updateUserDetailsMain = () => ({ type: UPDATE_USER_DETAILS });
export const updateUserDetailsFailed = (error) => ({
  type: UPDATE_USER_DETAILS_FAILED,
  data: error,
});
export const updateUserDetailsSuccess = (response) => ({
  type: UPDATE_USER_DETAILS_SUCCESS,
  data: response,
});

export const updateUserDetails = (
  userId,
  data,
  validateRequiredFields = false
) => async (dispatch) => {
  dispatch(updateUserDetailsMain());

  try {
    const response = await axios.put(`/users/${userId}`, {
      ...data,
      validateRequiredFields,
    });

    if (response.status === 200) {
      const updatedUserDetails = response.data.data;
      dispatch(updateUserDetailsSuccess(updatedUserDetails));
    } else {
      dispatch(updateUserDetailsFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(updateUserDetailsFailed(handleAxiosError(error)));
  }
};

import {
  USERS,
  USERS_FAILED,
  USERS_SUCCESS,
  USER,
  USER_FAILED,
  USER_SUCCESS,
  RESET,
  UPLOAD_AVATAR,
  UPLOAD_AVATAR_FAILED,
  UPLOAD_AVATAR_SUCCESS,
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  UPDATE_USER_SELF,
  UPDATE_USER_SELF_FAILED,
  UPDATE_USER_SELF_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_POST_SUCCESS,
  PAGE,
} from "../actions";

const initialState = {
  usersPagination: [],
  users: [],
  user: {
    email: "",
    firstName: "",
    lastName: "",
    position: "",
    avatar: "",
    completed: false,
    needs: "",
    contact: {
      phone: "",
      cellphone: "",
    },
    virtualTour: {},
    role: {
      name: "",
      code: "",
    },
    additionalInfo: {
      sections: [],
    },
    appointments: {
      accepted: 0,
      pending: 0,
    },
    companyId: {
      comercialName: "",
      rfc: "",
      rfcSeller: "",
      socialName: "",
      webPage: "",
      virtualLink: "",
      prodServ: "",
    },
  },
  updatedUserSelf: {},
  updatedUser: {},
  loading: false,
  error: false,
  updateSuccess: false,
  addSuccess: false,

  newAvatar: "",
  uploadSuccess: false,
  page: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET users -----
    case PAGE:
      return {
        ...state,
        page: action.data,
      };
    case USERS:
      return {
        ...state,
        loading: true,
      };
    case USERS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.data.users,
        usersPagination: action.data.pagination,
      };

    // ----- GET user -----
    case USER:
      return {
        ...state,
        loading: true,
        error: false,
        updateSuccess: false,
        uploadSuccess: false,
      };
    case USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data,
        error: false,
      };

    // ----- UPLOAD/UPDATE user avatar -----
    case UPLOAD_AVATAR:
      return {
        ...state,
        loading: true,
        uploadSuccess: false,
      };
    case UPLOAD_AVATAR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
        newAvatar: action.data,
      };

    // ----- ADD user (only by admin) -----
    case ADD_USER:
      return {
        ...state,
        loading: true,
        addSuccess: false,
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        addSuccess: true,
        error: false,
        newAvatar: action.data,
      };

    // ----- UPDATE user self -----
    case UPDATE_USER_SELF:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        error: false,
      };
    case UPDATE_USER_SELF_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_USER_SELF_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        updateSuccess: true,
        updatedUserSelf: action.data,
      };

    // ----- UPDATE user by admin -----
    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        error: false,
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        updateSuccess: true,
        updatedUser: action.data,
      };
    case UPDATE_USER_POST_SUCCESS:
      return {
        ...state,
        updateSuccess: false,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        updateSuccess: false,
        addSuccess: false,
        newAvatar: "",
        uploadSuccess: false,
        user: {
          avatar: "",
          completed: false,
          contact: {},
          virtualTour: {},
          additionalInfo: {
            sections: [],
          },
          role: {
            name: "",
          },
          appointments: {
            accepted: 0,
            pending: 0,
          },
          companyId: {
            comercialName: "",
            rfc: "",
            socialName: "",
            webPage: "",
            virtualLink: "",
          },
        },
      };
    default:
      return state;
  }
}

import React from "react";
import { useTranslation } from "react-i18next";

const PaymentBanner = ({ eventInfo, setShowConfirmationPayment }) => {
  const { i18n, t } = useTranslation("dashboard");
  const currentLanguage = i18n.language;

  return (
    <div
      style={{
        position: "fixed",
        top: 70,
        zIndex: 999,
        background: "#faedb3",
        minHeight: "47px",
        textAlign: "center",
        padding: "0 25px",
        width: "100vw",
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        display: "flex",
        justifyContent: "center",
        color: "black",
        alignItems: "center",
      }}
      id='paymentBanner'
    >
      <p
        style={{
          marginTop: "15px",
          fontSize: "12px",
        }}
      >
        {t("payment.banner1")}{" "}
        {eventInfo.title[currentLanguage]} {t("payment.banner2")}
        <strong>
          {" "}
          {eventInfo.contactInformation
            ? eventInfo.contactInformation.email
            : null}
        </strong>{" "}
        {t("payment.banner3")}
        <strong
          style={{
            cursor: "pointer",
            marginLeft: "5px",
          }}
          onClick={() => {
            setShowConfirmationPayment(true);
          }}
        >
          {t("payment.banner4")}
        </strong>
      </p>
    </div>
  );
};

export default PaymentBanner;
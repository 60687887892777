import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
import {
  REGISTER,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  CONFIRM,
  CONFIRM_FAILED,
  CONFIRM_SUCCESS,
  RECOVER_PASS,
  RECOVER_PASS_FAILED,
  RECOVER_PASS_SUCCESS,
  CHANGE_PASS,
  CHANGE_PASS_FAILED,
  CHANGE_PASS_SUCCESS,
} from "./Constants";
import { logginSuccessActions } from "./LoggedUser";

export const register = () => ({ type: REGISTER });
export const registerFailed = (error) => ({
  type: REGISTER_FAILED,
  data: error,
});
export const registerSuccess = (response) => ({
  type: REGISTER_SUCCESS,
  data: response,
});

export const registerUser = (user, setSession = true) => {
  return async (dispatch) => {
    dispatch(register());

    try {
      let emailLower = user.email.toLowerCase();
      user.email = emailLower;
      const response = await axios.post("/register", { ...user });

      if (response.status === 200) {
        dispatch(registerSuccess(response.data));
        if (setSession) {
          const userData = response.data.data;
          logginSuccessActions(userData)(dispatch);
        }
      } else {
        dispatch(registerFailed(response.data));
      }
    } catch (error) {
      dispatch(registerFailed(handleAxiosError(error)));
    }
  };
};

export const confirm = () => ({ type: CONFIRM });
export const confirmFailed = (error) => ({
  type: CONFIRM_FAILED,
  data: error,
});
export const confirmSuccess = (response) => ({
  type: CONFIRM_SUCCESS,
  data: response,
});

export const confirmUser = (id, user) => {
  return async (dispatch) => {
    // Validate password and confirmation are the same
    if (user.password !== user.confirmPassword) {
      dispatch(registerFailed("Las contraseñas no coinciden"));
      return;
    }

    dispatch(confirm());

    try {
      const response = await axios.post(`/confirm/${id}`, { ...user });

      if (response.status === 200) {
        dispatch(confirmSuccess(response.data));
      } else {
        dispatch(confirmFailed(response.data));
      }
    } catch (error) {
      dispatch(confirmFailed(handleAxiosError(error)));
    }
  };
};

export const recoverPass = () => ({ type: RECOVER_PASS });
export const recoverPassFailed = (error) => ({
  type: RECOVER_PASS_FAILED,
  data: error,
});
export const recoverPassSuccess = (response) => ({
  type: RECOVER_PASS_SUCCESS,
  data: response,
});

export const recoverPassUser = (email) => {
  return async (dispatch) => {
    dispatch(recoverPass());

    try {
      const response = await axios.post("/password-recovery", { email });
      if (response.status === 200) {
        dispatch(recoverPassSuccess(response.data));
      } else {
        dispatch(recoverPassFailed(response.data));
      }
    } catch (error) {
      dispatch(recoverPassFailed(handleAxiosError(error)));
    }
  };
};

export const changePass = () => ({ type: CHANGE_PASS });
export const changePassFailed = (error) => ({
  type: CHANGE_PASS_FAILED,
  data: error,
});
export const changePassSuccess = (response) => ({
  type: CHANGE_PASS_SUCCESS,
  data: response,
});

export const changePassUser = (token, data) => {
  return async (dispatch) => {
    if (data.password !== data.confirmPassword) {
      dispatch(changePassFailed("Las contraseñas no coinciden"));
      return;
    }

    dispatch(changePass());

    try {
      const response = await axios.put(`/update-password?token=${token}`, {
        ...data,
      });
      if (response.status === 200) {
        dispatch(changePassSuccess(response.data));
      } else {
        dispatch(changePassFailed(response.data));
      }
    } catch (error) {
      dispatch(changePassFailed(handleAxiosError(error)));
    }
  };
};

import {
  BUYERS,
  BUYERS_FAILED,
  BUYERS_SUCCESS,
  REGISTER_BUYER_FAILED,
  REGISTER_BUYER_SUCCESS,
  BUYER,
  BUYER_FAILED,
  BUYER_SUCCESS,
  RESET,
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  success: false,
  buyersData: undefined,
  buyer: {
    firstName: "",
    lastName: "",
    avatar: "",
    completed: false,
    needs: "",
    contact: {},
    virtualTour: {},
    additionalInfo: {
      sections: [],
    },
    appointments: {
      accepted: 0,
      pending: 0,
    },
    companyId: {
      comercialName: "",
      rfc: "",
      socialName: "",
      webPage: "",
      virtualLink: "",
      additionalData: "",
    },
    role: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET BUYERS FORM -----
    case BUYERS:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case BUYERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case BUYERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        buyersData: action.data,
      };

    // ----- GET BUYER -----
    case BUYER:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case BUYER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case BUYER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        buyer: action.data,
      };

    case REGISTER_BUYER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case REGISTER_BUYER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        buyer: action.data,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
      };
    default:
      return state;
  }
}

 import axios from "../config/axios"
 const getUserSelf = async (dispatch,loginSuccess,loginFailed) => {
    axios
      .get("/users/user-self")
      .then(({ data }) => {
        dispatch(loginSuccess(data.data));
      }).catch(() => {
        dispatch(loginFailed());
      })
  };

  export default getUserSelf
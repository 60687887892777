import React from "react";
import { BeatLoader } from "react-spinners";

import Error500 from "pages/Error500";
import PageTitle from "./page-title/page-title";

/**
 * Main module page layout with custom hooks.
 *
 * @param {*} param0
 */
const PageLayout = ({
  children,
  dataLength = 1,
  emptyDataMessage = "No hay resultados en la base de datos",
  errorMessage,
  error = false,
  loading,
  titleHeading,
  titleDescription,
}) => {
  return (
    <div>
      {(titleHeading || titleDescription) && (
        <PageTitle
          showPageTitleIconBox={false}
          titleHeading={titleHeading || null}
          titleDescription={titleDescription || null}
        />
      )}
      {error !== false ? (
        <Error500 errorMessage={errorMessage} />
      ) : loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <BeatLoader color={"var(--primary)"} loading={true} />
        </div>
      ) : dataLength === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          <h1>{emptyDataMessage}</h1>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default PageLayout;

import {
  DYNAMIC_FORM,
  DYNAMIC_FORM_FAILED,
  DYNAMIC_FORM_SUCCESS,
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  success: false,
  dynamicForm: {
    sections: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET DYNAMIC REGISTER FORM -----
    case DYNAMIC_FORM:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };

    case DYNAMIC_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case DYNAMIC_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        dynamicForm: action.data,
      };
    default:
      return state;
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { getVendors } from "services/UserService";
import { getCatalogues } from "redux/actions/Catalogues";
import ModalMenu from "../../components/ModalMenu";
import DataTable from "../../components/DataTable";
import PageLayout from "components/PageLayout";
import TooltipTruncate from "../../components/TooltipTruncate";
import { parseDate } from "../../utils/helpers/date";

const Sellers = ({ history, location }) => {
  const { t } = useTranslation("providers");
  const dispatch = useDispatch();
  const { catalogues } = useSelector((store) => store.catalogues);

  const [totalRows, setTotalRows] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState(false);

  const dataTableColumns = [
    {
      name: { es: "NOMBRE DE CONTACTO", en: "CONTACT NAME" },
      selector: "firstName",
      sortable: true,
      cell: (row) => <>{`${row.firstName} ${row.lastName}`}</>,
    },
    {
      name: { es: "CORREO ELECTRONICO", en: "EMAIL" },
      selector: "email",
      sortable: true,
    },
    {
      name: { es: "EMPRESA", en: "COMPANY" },
      selector: "comercialName",
      sortable: true,
    },
    {
      name: { es: "FECHA DE REGISTRO", en: "REGISTRATION DATE" },
      selector: "createdAt",
      sortable: true,
    },
    {
      name: { es: "PRODUCTOS Y SERVICIOS", en: "PRODUCTS AND SERVICES" },
      selector: "needs",
      sortable: true,
      cell: ({ needs }) => <TooltipTruncate needs={needs} />,
    },
    {
      name: { es: "ACCIONES", en: "ACTIONS" },
      cell: ({ _id }) => {
        const actionItem = () => {
          history.push("public-profile/" + _id);
        };
        const itemsMenu = [
          {
            name: { es: "Ver perfil", en: "View profile" },
            action: actionItem,
          },
        ];
        return <ModalMenu Items={itemsMenu} />;
      },
      grow: 0,
    },
  ];

  useEffect(() => {
    dispatch(getCatalogues(["usersComercialNames"]));
  }, []);

  const handleFilterChange = async (
    pagination,
    sorting,
    searchFilter,
    selectsFilters
  ) => {
    setTableLoading(true);
    try {
      const dataVendors = await getVendors(
        pagination,
        sorting,
        searchFilter,
        selectsFilters
      );
      if (dataVendors.status === 200) {
        const data = dataVendors.data.data.users;
        if (!data.length && searchFilter === "") {
        } else {
          const formattedData = data.filter(
            (dataSeller) =>
              (dataSeller.createdAt = parseDate(dataSeller.createdAt))
          );
          setData(formattedData);
          setTotalRows(dataVendors.data.data.pagination.totalItems);
        }
      } else {
        setError("Error interno de servidor");
      }
    } catch (error) {
      console.error(error);
      setError("Error interno de servidor");
    } finally {
      setTableLoading(false);
    }
  };

  return (
    <PageLayout
      titleHeading={t("pageTitle")}
      errorMessage="No hay proveedores premium en la base de datos"
      error={error}
    >
      <DataTable
        routeConfig={{
          history,
          locationSearch: location.search,
        }}
        columns={dataTableColumns}
        filterFields={[
          {
            displayName: { es: "EMPRESA", en: "COMPANY"},
            selector: "comercialName",
            catalog: catalogues.usersComercialNames,
          },
        ]}
        data={data}
        pagination
        paginationServer
        enableSearch
        keyField="_id"
        onFilterChange={handleFilterChange}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalRows}
        progressPending={tableLoading}
      />
    </PageLayout>
  );
};

export default Sellers;

export default (error) => {
  if (error.response) {
    const responseError =
      error.response && error.response.data
        ? error.response.data.message
        : "Error interno de servidor.";
    return responseError;
  } else if (error.request) {
    return "Error interno de servidor. Petición enviada pero respuesta no obtenida, inténtalo de nuevo.";
  } else {
    return error;
  }
};

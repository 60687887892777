/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import React from "react";
import { ClimbingBoxLoader } from "react-spinners";
import { useSelector } from "react-redux";

import { cdnDynamic } from "utils/dynamicTags";
import { setUserJoinedMeeting } from "../../services/MeetService";

const JitsiMeet = ({ setMeetError, meetId, handleEndCall, jitsiToken = "" }) => {
  const { t } = useTranslation("loading-screens");
  const { loggedUser: { _id: userId } } = useSelector((state) => state.loggedUser);

  const [api, setApi] = React.useState("");
  const [load, setLoad] = React.useState(true);
  const [joinMeet, setJoinMeet] = React.useState(false);

  const getInfoRoom = async (jitsiTokenRoom) => {
    setJoinMeet(true);
    cdnDynamic(process.env.REACT_APP_SERVER_JITSI, () => {
      setApi(
        new window.JitsiMeetExternalAPI("8x8.vc", {
          height: document.documentElement.clientHeight || 500,
          roomName: `${process.env.REACT_APP_ROOM_JITSI}/${meetId}`,
          parentNode: document.querySelector("#meet"),
          onload: () => {
            setLoad(false);
          },
          jwt: `${jitsiTokenRoom}`,
          configOverwrite: {
            startWithAudioMuted: false,
            hideLobbyButton: false,
            enableWelcomePage: false,
            disableResponsiveTiles: true,
            toolbarButtons: [
              "microphone",
              "camera",
              "fullscreen",
              "hangup",
              "chat",
              "raisehand",
              "videoquality",
              "filmstrip",
              "select-background",
              "desktop",
            ],
            prejoinPageEnabled: false,
          },
        })
      );
    });
  };

  React.useEffect(() => {
    if (jitsiToken !== "") {
      getInfoRoom(jitsiToken);
    }
  }, [jitsiToken]);

  React.useEffect(() => {
    if (api !== "") {
      // Observer when user joins the conference - to set new appointment status on db
      api.addListener("videoConferenceJoined", async (data) => {
        if (userId && meetId) {
          try {
            const response = await setUserJoinedMeeting(userId, meetId);
            if (response.status === 200) {
              setMeetError(false);
            } else {
              setMeetError(true);
            }
          } catch (error) {
            setMeetError(true);
          }
        }
      });
      // Observe if the user leaves the conference - it will run handleEndCall process
      api.addListener("videoConferenceLeft", async (data) => {
        if (userId && meetId && handleEndCall) {
          handleEndCall();
        }
      });
    }
  }, [api]);

  return (
    <div>
      {!joinMeet ? null : (
        <div id="contentMeet" style={{ width: "100%", position: "relative" }}>
          {load ? (
            <div
              className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3"
              data-testid="meet-loader"
            >
              <div className="d-flex align-items-center flex-column px-4">
                <ClimbingBoxLoader color={"#5383ff"} loading={true} />
              </div>
              <div className="text-muted font-size-xl text-center pt-3">
                {t("loadingVideoconference")}
              </div>
            </div>
          ) : (
            ""
          )}
          <div
            id="meet"
            style={{ visibility: load ? "hidden" : "visible" }}
          ></div>
        </div>
      )}
    </div>
  );
};
export default JitsiMeet;

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  fas,
  faEllipsisH,
  faAngleDown,
  faArrowRight,
  faArrowLeft,
  faSortAmountUp,
  faSortAmountDown,
  faSort,
  faUniversity,
  faEnvelope,
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab, faPaypal } from "@fortawesome/free-brands-svg-icons";

export default function registerIcons() {
  library.add(
    faEllipsisH,
    faAngleDown,
    faArrowRight,
    faArrowLeft,
    faUniversity,
    far,
    fas,
    fab,
    faPaypal,
    faEnvelope,
    faSortAmountUp,
    faSortAmountDown,
    faSort,
    faCheckCircle,
    faTimesCircle
  );
}

import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MailOutlineTwoToneIcon from "@material-ui/icons/MailOutlineTwoTone";
import {
  Grid,
  InputAdornment,
  Card,
  Button,
  TextField,
  Container,
} from "@material-ui/core";

import { Alert, NoSessionNavigation, Loader } from "components";
import { recoverPassUser } from "redux/actions";
import { required, validEmail } from "utils/validations";

import styles from "./recover-password.module.scss";

export default function RecoverPassword({ history }) {
  const { t } = useTranslation("recover-password");
  const dispatch = useDispatch();
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const noSessionSiteBgUrl = eventInfo.assets.noSessionSiteBg;

  const { loading, error, recoverSuccess } = useSelector(
    (store) => store.register
  );

  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    if (recoverSuccess) setUserEmail("");
  }, [recoverSuccess]);

  const recoverPassword = () => {
    dispatch(recoverPassUser(userEmail));
  };

  const goLogin = () => {
    history.push({
      pathname: "/login",
    });
  };

  return (
    <Grid
      className="min-vh-100 nosession-site-bg-image"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${noSessionSiteBgUrl})`,
      }}
    >
      <div style={{ height: 42 }}>
        <NoSessionNavigation goBack={goLogin} containerStyle={{ flex: 1 }} />
      </div>

      <Grid className={styles["main-content"]}>
        <Container maxWidth="sm">
          <h1 className="display-2 mb-3 px-4 font-weight-bold text-center">
            {t("pageTitle")}
          </h1>
          <h3 className="font-size-lg line-height-sm font-weight-bold d-block px-3 mb-5 text-center">
            {t("description")}
          </h3>

          <Card className="p-3 text-center">
            <TextField
              placeholder={t("form.email")}
              value={userEmail}
              error={
                userEmail.length > 0 &&
                (!required(userEmail) || !validEmail(userEmail))
              }
              helperText={
                userEmail.length > 0
                  ? !required(userEmail) || !validEmail(userEmail)
                    ? t("form.emailNotValid")
                    : null
                  : null
              }
              fullWidth
              className="mt-0"
              margin="dense"
              variant="outlined"
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailOutlineTwoToneIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setUserEmail(e.target.value)}
            />

            {loading ? (
              <Loader />
            ) : (
              <Button
                disabled={!required(userEmail) || !validEmail(userEmail)}
                variant="contained"
                className="mt-4 custom-primary-btn"
                size="large"
                color="primary"
                onClick={recoverPassword}
              >
                <span className="btn-wrapper--label">{t("form.confirm")}</span>
              </Button>
            )}
          </Card>

          {error ? (
            <div className="mt-4">
              <Alert severity="error" message={error} />
            </div>
          ) : null}

          {recoverSuccess ? (
            <div className="mt-4">
              <Alert
                severity="success"
                message={`${t("form.success1")} \n${
                  eventInfo.contactInformation.email
                } \n${t("form.success2")} ${
                  eventInfo.contactInformation.phone
                }.`}
                insertNewLines={true}
              />
            </div>
          ) : null}
        </Container>
      </Grid>
    </Grid>
  );
}

import axios from "config/axios";

const { REACT_APP_API_URL } = process.env;

const getAssistantUsers = (pagination, sort, search, selectsFilters) => {
  const payload = {
    ...pagination,
    selects: {
      _id: 1,
      firstName: 1,
      lastName: 1,
      "contact.cellphone": 1,
      email: 1,
      "service.title": 1,
      "service.cardTitle": 1,
      "service._id": 1,
      comercialName: 1,
      position: 1,
      initialServiceId: 1,
    },
    filters: {
      ...selectsFilters,
      "role.isAssistant": {
        $in: [true],
      },
      $or: [
        "firstName",
        "lastName",
        "comercialName",
        "position",
        "contact.cellphone",
        "email",
        "service.title",
        "service.cardTitle",
        "initialServiceId",
      ],
    },
    sort,
    search,
  };
  return axios.post(`${REACT_APP_API_URL}/users/filters`, { ...payload });
};

const activateAssistantUsers = (userId, serviceid) => {
  return axios.put(`${REACT_APP_API_URL}/users/assistants-service/${userId}`, {
    serviceid,
  });
};

export { activateAssistantUsers, getAssistantUsers };

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@material-ui/core";
import { useSelector } from "react-redux";

import { PageTitle } from "components";

import RolAdminOrGod from "./components/RolAdminOrGod";

const Videoconferences = () => {
  const { t, i18n } = useTranslation("videoconferences");
  const currentLanguage = i18n.language;

  const { page } = useSelector((store) => store.pendingAppointments);

  const statusTabs = ["ALL", "STV001", "STV002", "STV003"];
  const tabsContent = [
    {
      label: {
        es: "Todas",
        en: "All",
      },
      tabWidth: "25%",
    },
    {
      label: {
        es: "Completas",
        en: "Completed",
      },
      tabWidth: "25%",
    },
    {
      label: {
        es: "Incompletas",
        en: "Incompleted",
      },
      tabWidth: "25%",
    },
    {
      label: {
        es: "No realizadas",
        en: "Scheduled",
      },
      tabWidth: "25%",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(page);
  const changeTabHandler = (_event, newTab) => setSelectedTab(newTab);

  return (
    <>
      <PageTitle titleHeading={t("pageTitle")} showPageTitleIconBox={false} />
      <Tabs
        value={selectedTab}
        onChange={changeTabHandler}
        aria-label="Estatus de citas"
        indicatorColor="primary"
      >
          {tabsContent.map(({ label, tabWidth }, index) => (
            <Tab
              key={index}
              label={label[currentLanguage]}
              style={{ width: tabWidth }}
            />
          ))}
      </Tabs>
      <RolAdminOrGod status={statusTabs[selectedTab]} />
    </>
  );
};

export default Videoconferences;

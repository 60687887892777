import {
  PENDING_APPOINTMENTS,
  PENDING_APPOINTMENTS_FAILED,
  PENDING_APPOINTMENTS_SUCCESS,
  PAGEAPPOINTMENT
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  success: false,
  pendingAppointmentsData: undefined,
  page:0
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET BUYERS FORM -----
    case PAGEAPPOINTMENT:
      return{
        ...state,
        page:action.data
      }
    case PENDING_APPOINTMENTS:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case PENDING_APPOINTMENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case PENDING_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingAppointmentsData: action.data,
      };
    default:
      return state;
  }
}

import {
  COMPANIES,
  COMPANIES_FAILED,
  COMPANIES_SUCCESS,
  COMPANY,
  COMPANY_FAILED,
  COMPANY_SUCCESS,
  ADD_COMPANY,
  ADD_COMPANY_FAILED,
  ADD_COMPANY_SUCCESS,
  EDIT_COMPANY,
  EDIT_COMPANY_FAILED,
  EDIT_COMPANY_SUCCESS,
  UPLOAD_COMPANY_AVATAR,
  UPLOAD_COMPANY_AVATAR_FAILED,
  UPLOAD_COMPANY_AVATAR_SUCCESS,
} from "../actions";

const initialState = {
  companies: [],
  company: {},
  loading: false,
  error: false,
  success: false,
  uploadSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET companies -----
    case COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case COMPANIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        companies: action.data,
      };

    // ----- GET company -----
    case COMPANY:
      return {
        ...state,
        loading: true,
      };
    case COMPANY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.data,
      };

    // ----- POST company -----
    case ADD_COMPANY:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case ADD_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
    case ADD_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    // ----- PUT company -----
    case EDIT_COMPANY:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case EDIT_COMPANY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
    case EDIT_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    // ----- PUT company avatar -----
    case UPLOAD_COMPANY_AVATAR:
      return {
        ...state,
        loading: true,
        uploadSuccess: false,
      };
    case UPLOAD_COMPANY_AVATAR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
        uploadSuccess: false,
      };
    case UPLOAD_COMPANY_AVATAR_SUCCESS:
      return {
        ...state,
        loading: false,
        uploadSuccess: true,
      };

    default:
      return state;
  }
}

import React from "react";
import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const ModalChildren = ({
  center = false,
  showDialog,
  toggleDialog,
  children,
}) => {
  return (
    <Dialog maxWidth="md" open={showDialog} onClose={toggleDialog}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ padding: "10px", cursor: "pointer" }}>
          <CloseIcon onClick={toggleDialog}></CloseIcon>
        </div>
      </div>
      <div className={center ? "text-center p-5" : ""}>{children}</div>
    </Dialog>
  );
};

export default ModalChildren;

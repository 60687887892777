import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";

import "./assets/base.scss";
import "./globals.scss";
import "react-phone-input-2/lib/style.css";

import registerIcons from "./utils/register-icons";
import configureStore from "./config/configureStore";
import SessionValidator from "./utils/SessionValidator";
import { ClimbingBoxLoading } from "components";

registerIcons();
const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Suspense fallback={<ClimbingBoxLoading />}>
          <SessionValidator />
        </Suspense>
      </Provider>
    );
  }
}

export default App;

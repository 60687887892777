export default (value) => {
  if (typeof value === "boolean" || typeof value === "object") {
    return true;
  } else {
    if(value){
      return value.trim().length > 0;
    }
    
  }
};

import React from "react";
import { Dialog, Grid } from "@material-ui/core";

const AppointmentDetailDialog = ({
  title,
  titleSize,
  content,
  showDialog,
  toggleDialog,
}) => {
  const titleClasses = [
    `font-size-${titleSize}`,
    "font-weight-bold",
    "mb-4",
    "text-center",
  ];

  return (
    <Dialog open={showDialog} onClose={toggleDialog}>
      <Grid container justifyContent="center" className="p-4">
        <h1 className={titleClasses.join(" ")}>{title}</h1>
        <Grid item xs={12}>
          {content}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default AppointmentDetailDialog;

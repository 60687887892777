import React from "react";
import { Grid } from "@material-ui/core";

import useWindowSize from "utils/custom-hooks/useWindowSize";
import chooseServiceModuleStyles from "pages/choose-service/choose-service.module.scss";
import ChooseServiceCard from "components/ChooseService/ChooseServiceCard";

const PremiumServiceOptions = ({ eventInfo, onServiceSelect }) => {
  const windowSize = useWindowSize();

  // Get total of premium services to display dynamically correct grid css fractions
  let premiumServicesQuantity = 0;
  if (eventInfo.services.length) {
    eventInfo.services.forEach((service) => {
      if (service.isPremium) premiumServicesQuantity++;
    });
  }

  return (
    <Grid className={chooseServiceModuleStyles["main-content"]}>
      <div
        className={chooseServiceModuleStyles["cards-container"]}
        style={{
          gridTemplateColumns:
            windowSize === "xsm" || windowSize === "sm"
              ? ""
              : `repeat(${premiumServicesQuantity}, 1fr)`,
        }}
      >
        {eventInfo.services.length &&
          eventInfo.services.map((service, index) => {
            if (service.isPremium) {
              return (
                <ChooseServiceCard
                  key={index}
                  service={service}
                  onServiceSelect={() => onServiceSelect(service)}
                  modalDashboard={true}
                />
              );
            }
          })}
      </div>
    </Grid>
  );
};

export default PremiumServiceOptions;

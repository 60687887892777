import React from "react";
import { BeatLoader } from "react-spinners";

const Loader = ({ justify = "center" }) => {
  return (
    <div
      data-testid="loader"
      style={{
        display: "flex",
        justifyContent: justify,
      }}
    >
      <BeatLoader color={"var(--primary)"} />
    </div>
  );
};

export default Loader;

import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
import {
  USERS,
  USERS_FAILED,
  USERS_SUCCESS,
  USER,
  USER_FAILED,
  USER_SUCCESS,
  UPLOAD_AVATAR,
  UPLOAD_AVATAR_FAILED,
  UPLOAD_AVATAR_SUCCESS,
  ADD_USER,
  ADD_USER_FAILED,
  ADD_USER_SUCCESS,
  UPDATE_USER_SELF,
  UPDATE_USER_SELF_FAILED,
  UPDATE_USER_SELF_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_POST_SUCCESS,
  PAGE,
} from "./Constants";

// ----- GET users -----
export const users = () => ({ type: USERS });
export const usersFailed = (error) => ({ type: USERS_FAILED, data: error });
export const usersSuccess = (response) => ({
  type: USERS_SUCCESS,
  data: response,
});

export const getUsers = ({ userRole, limit = 10, page = 0 }) => async (
  dispatch
) => {
  try {
    const response = await axios.post("/users/filters", {
      filters: userRole
        ? {
            role: {
              _id: userRole,
            },
          }
        : {},
      selects: {
        _id: 1,
        authorized: 1,
        confirmed: 1,
        createdAt: 1,
        email: 1,
        firstName: 1,
        lastName: 1,

        comercialName: 1,
      },
      sort: {
        lastName: "asc",
      },
      populations: [
        {
          path: "role",
          select: ["_id", "name", "code"],
        },
        {
          path: "companyId",
        },
      ],
      page: page + 1,
      limit: limit,
    });

    if (response.status === 200) {
      dispatch(usersSuccess(response.data.data));
    }
  } catch (error) {
    dispatch(usersFailed(handleAxiosError(error)));
  }
};

// ----- GET user -----
export const user = () => ({ type: USER });
export const userFailed = (error) => ({ type: USER_FAILED, data: error });
export const userSuccess = (response) => ({
  type: USER_SUCCESS,
  data: response,
});

export const getUser = (id) => async (dispatch) => {
  dispatch(user());

  try {
    const response = await axios.get(`/users/${id}`);

    if (response.status === 200) {
      const userResponse = response.data.data;
      dispatch(userSuccess(userResponse));
    } else {
      dispatch(userFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(userFailed(handleAxiosError(error)));
  }
};

// ----- UPLOAD/UPDATE user avatar -----
export const uploadAvatar = () => ({ type: UPLOAD_AVATAR });
export const uploadAvatarFailed = (error) => ({
  type: UPLOAD_AVATAR_FAILED,
  data: error,
});
export const uploadAvatarSuccess = (response) => ({
  type: UPLOAD_AVATAR_SUCCESS,
  data: response,
});

export const uploadUserAvatar = (userId, avatar) => async (dispatch) => {
  dispatch(user());

  try {
    const response = await axios.put(`/users/${userId}/image`, { avatar });
    if (response.status === 200) {
      const avatarResponse = response.data.data.avatar;
      dispatch(uploadAvatarSuccess(avatarResponse));
    } else {
      dispatch(uploadAvatarFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(uploadAvatarFailed(handleAxiosError(error)));
  }
};

// ----- ADD user (only by admin) -----
export const addUser = () => ({ type: ADD_USER });
export const addUserFailed = (error) => ({
  type: ADD_USER_FAILED,
  data: error,
});
export const addUserSuccess = (response) => ({
  type: ADD_USER_SUCCESS,
  data: response,
});

export const addNewUser = (data) => async (dispatch) => {
  dispatch(addUser());

  try {
    const response = await axios.put("/users", { ...data });
    if (response.status === 200) {
      dispatch(addUserSuccess());
    } else {
      dispatch(addUserFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(addUserFailed(handleAxiosError(error)));
  }
};

// ----- UPDATE user self -----
export const updateUserSelfMain = () => ({ type: UPDATE_USER_SELF });
export const updateUserSelfFailed = (error) => ({
  type: UPDATE_USER_SELF_FAILED,
  data: error,
});
export const updateUserSelfSuccess = (response) => ({
  type: UPDATE_USER_SELF_SUCCESS,
  data: response,
});

export const updateUserSelf = (data, validateRequiredFields) => async (
  dispatch
) => {
  dispatch(updateUserSelfMain());

  try {
    const response = await axios.put(`/users/user-self`, {
      ...data,
      validateRequiredFields,
    });
    if (response.status === 200) {
      const updatedUserSelf = response.data.data;
      dispatch(updateUserSelfSuccess(updatedUserSelf));
    } else {
      dispatch(updateUserSelfFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(updateUserFailed(handleAxiosError(error)));
  }
};

// ----- UPDATE user (only by admin) -----
export const updateUser = () => ({ type: UPDATE_USER });
export const updateUserFailed = (error) => ({
  type: UPDATE_USER_FAILED,
  data: error,
});
export const updateUserSuccess = (response) => ({
  type: UPDATE_USER_SUCCESS,
  data: response,
});
export const updateUserPostSuccess = () => ({ type: UPDATE_USER_POST_SUCCESS });
export const updateUserData = (userId, data, validateRequiredFields) => async (
  dispatch
) => {
  dispatch(updateUser());

  try {
    const response = await axios.put(`/users/${userId}`, {
      ...data,
      validateRequiredFields,
    });
    if (response.status === 200) {
      const updatedUser = response.data.data;
      dispatch(updateUserSuccess(updatedUser));
      dispatch(updateUserPostSuccess());
    } else {
      dispatch(updateUserFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(updateUserFailed(handleAxiosError(error)));
  }
};

export const PageChangeUser = (response) => ({
  type: PAGE,
  data: response,
});

import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
import {
  ROLES,
  ROLES_FAILED,
  ROLES_SUCCESS,
} from "./Constants";

export const roles = () => ({ type: ROLES });
export const rolesFailed = (error) => ({ type: ROLES_FAILED, data: error });
export const rolesSuccess = (response) => ({
  type: ROLES_SUCCESS,
  data: response,
});

export const getRoles = () => {
  return async (dispatch) => {
    dispatch(roles());

    try {
      const response = await axios.get("/role");

      if (response.status === 200) {
        const rolesResponse = response.data.data;
        dispatch(rolesSuccess(rolesResponse));
      } else {
        dispatch(rolesFailed(response.data));
      }
    } catch (error) {
      dispatch(rolesFailed(handleAxiosError(error)));
    }
  };
};

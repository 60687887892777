import axios from "config/axios";

export const requestVerifyVideoconference = async (idUser, idAppointment) => {
  try {
    const response = await axios.post("/verify-videoconference", {
      idUser,
      idAppointment,
    });
    return response.data;
  } catch (e) {
    return e;
  }
};

/**
 * Indicates a user has joined a videoconference
 * @param {string} userId - Logged user id that is joining
 * @param {string} meetId - Current joinable meeting id
 */
export const setUserJoinedMeeting = async (idUser, idAppointment) => {
  try {
    const response = await axios.post("/status-videoconference", {
      idUser,
      idAppointment,
      status: true, // Default, it'll indicate user has joined a videoconference
    });

    return response.data;
  } catch (error) {
    return error;
  }
};

import React, { Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Paper, Box, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";

import styles from "./page-title.module.scss";

const { REACT_APP_ROLE_ASISTANT_FREE } = process.env;

function PageTitle(props) {
  const { loggedUser } = useSelector((store) => store.loggedUser);

  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleIconBox,
    showPageTitleIconBox = true,
    pageTitleDescription,
    hasGoBackButton = false,
    history,
  } = props;
  const paperClasses = [
    "app-page-title--iconbox",
    "d-70",
    "d-flex",
    "align-items-center",
    "bg-secondary",
    "justify-content-center",
  ];
  const additionalPaperStyles = {
    padding: "2rem 2rem 0.2rem 2rem",
    margin: "-2rem -2rem 1rem",
  };

  const isPaymentBannerDisplayed =
    loggedUser.role._id === REACT_APP_ROLE_ASISTANT_FREE;

  const goBack = () => history.goBack();

  return (
    <Fragment>
      <Paper
        square
        elevation={pageTitleShadow ? 6 : 2}
        className={clsx("app-page-title", pageTitleStyle, pageTitleBackground)}
        style={additionalPaperStyles}
      >
        <div>
          <Box className="app-page-title--first">
            {showPageTitleIconBox && pageTitleIconBox && (
              <Paper elevation={2} className={paperClasses.join(" ")}>
                <DashboardTwoToneIcon />
              </Paper>
            )}
            <div
              className={`app-page-title--heading
                ${
                  isPaymentBannerDisplayed
                    ? styles["title-heading-container"]
                    : ""
                }`}
            >
              <h1>{props.titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {props.titleDescription}
                </div>
              )}
            </div>
          </Box>
        </div>
        <div>
          {hasGoBackButton &&
            (props.titleHeading === "Actualizar usuario" ? (
              <Button variant="contained" color="secondary">
                <Link
                  to={{ pathname: "/Users", remember: true }}
                  style={{ color: "white" }}
                >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                  </span>
                </Link>
              </Button>
            ) : props.titleHeading === "Agendar cita" ||
              props.titleHeading === "Detalle de cita" ? (
              <Button variant="contained" color="secondary">
                <Link
                  to={{ pathname: "/my-appointments", remember: true }}
                  style={{ color: "white" }}
                >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                  </span>
                </Link>
              </Button>
            ) : (
              <Button variant="contained" color="secondary" onClick={goBack}>
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={["fas", "arrow-left"]} />
                </span>
              </Button>
            ))}
        </div>
      </Paper>
    </Fragment>
  );
}
const mapStateToProps = (state) => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription,
});

export default connect(mapStateToProps)(PageTitle);

import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField, Button, Grid } from "@material-ui/core";

import { Loader, Alert, Recaptcha, PhoneInput } from "..";
import TermsConditions from "./terms-conditions";
import styles from "./register-form.module.scss";
import {
  required,
  validEmail,
  minLength,
  maxLength,
  string,
  numeric,
  webpage,
  confirmSameValue,
  disableRegisterFlag,
} from "../../utils/validations";

const defaultUserDataObj = {
  firstName: "",
  lastName: "",
  email: "",
  emailConfirmation: "",
  cellphone: "",
  cellphoneConfirmation: "",
  password: "",
  passwordConfirmation: "",
  comercialName: "",
  companyWeb: "",
  city: "",
};

const RegisterForm = ({
  onRegisterAction,
  editUser = false,
  noSubmit = false,
  usePromoCode = false,
  label = { es: "Regístrate", en: "Register" },
  initialServiceId,
}) => {
  const { i18n, t } = useTranslation("register");
  const currentLanguage = i18n.language;
  const { loading, error } = useSelector((store) => store.register);

  const [captchaPassed, setCaptchaPassed] = useState(false);
  const captchaSucceededHandler = () => setCaptchaPassed(true);
  const captchaFailedHandler = () => setCaptchaPassed(false);

  const [cellphoneCountryData, setCellphoneCountryData] = useState({
    dialCode: "",
  });

  const [user, setUser] = useState(defaultUserDataObj);

  useEffect(() => {
    if (editUser) {
      const completeUser = {
        ...editUser,
        cellphone: editUser.cellphoneDialCode + editUser.cellphone,
      };
      setUser(completeUser);
      setCellphoneCountryData({ dialCode: editUser.cellphoneDialCode });
    }
  }, [editUser]);

  const {
    firstName,
    lastName,
    email,
    emailConfirmation,
    cellphone,
    password,
    passwordConfirmation,
    comercialName,
    companyWeb,
    city,
  } = user;

  const validCellphone =
    !required(cellphone) ||
    !minLength(11)(cellphone) ||
    !maxLength(15)(cellphone) ||
    !numeric(cellphone);

  const disableRegisterFormFlag = disableRegisterFlag(
    user,
    validCellphone,
    !noSubmit ? captchaPassed : true,
    typeof usePromoCode === "string"
  );

  const handleInputChange = (inputEvent) => {
    setUser({ ...user, [inputEvent.target.name]: inputEvent.target.value });
  };

  const registerAction = () => {
    const newUser = {
      ...user,
      cellphoneDialCode: cellphoneCountryData.dialCode,
      initialServiceId,
    };
    onRegisterAction(newUser, false);
  };

  // If noSubmit, then hide Captcha, Terms & Conditions and Submit btn
  const hideSubmitItemsClass = noSubmit ? "d-none" : "";

  return (
    <>
      <div className={styles["form-container"]}>
        <TextField
          type="text"
          value={firstName}
          name="firstName"
          label={t("form.firstName")}
          placeholder={t("form.firstNamePlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            firstName && firstName.length > 0
              ? !required(firstName) ||
                !minLength(3)(firstName) ||
                !maxLength(25)(firstName) ||
                !string(firstName)
              : false
          }
          helperText={
            firstName && firstName.length > 0
              ? !required(firstName) ||
                !minLength(3)(firstName) ||
                !maxLength(25)(firstName) ||
                !string(firstName)
                ? t("form.firstNameError")
                : null
              : null
          }
        />

        <TextField
          type="text"
          value={lastName}
          name="lastName"
          label={t("form.lastName")}
          placeholder={t("form.lastNamePlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            lastName && lastName.length > 0
              ? !required(lastName) ||
                !minLength(3)(lastName) ||
                !maxLength(25)(lastName) ||
                !string(lastName)
              : false
          }
          helperText={
            lastName && lastName.length > 0
              ? !required(lastName) ||
                !minLength(3)(lastName) ||
                !maxLength(25)(lastName) ||
                !string(lastName)
                ? t("form.lastNameError")
                : null
              : null
          }
        />

        <TextField
          type="email"
          value={email}
          name="email"
          label={t("form.email")}
          placeholder={t("form.emailPlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            email && email.length > 0
              ? !required(email) || !validEmail(email) || !maxLength(50)(email)
              : false
          }
          helperText={
            email && email.length > 0
              ? !required(email) || !validEmail(email) || !maxLength(50)(email)
                ? t("form.emailError")
                : null
              : null
          }
        />
        <TextField
          type="email"
          value={emailConfirmation}
          name="emailConfirmation"
          label={t("form.emailConfirmation")}
          placeholder={t("form.emailConfirmationPlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            emailConfirmation.length > 0
              ? !required(emailConfirmation) ||
                !validEmail(emailConfirmation) ||
                !confirmSameValue(email, emailConfirmation)
              : false
          }
          helperText={
            emailConfirmation.length > 0
              ? !required(emailConfirmation) ||
                !validEmail(emailConfirmation) ||
                !confirmSameValue(email, emailConfirmation)
                ? t("form.emailConfirmationError")
                : null
              : null
          }
        />

        <PhoneInput
          inputLabel={t("form.cellphone")}
          value={cellphone}
          valueName="cellphone"
          previousState={user}
          setValue={setUser}
          placeholder={t("form.cellphonePlaceholder")}
          error={validCellphone}
          helperText={t("form.cellphoneError")}
          setCountryData={setCellphoneCountryData}
        />

        <TextField
          type="password"
          value={password}
          name="password"
          label={t("form.password")}
          placeholder={t("form.passwordPlaceholder")}
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            password && password.length > 0
              ? !required(password) ||
                !minLength(8)(password) ||
                !maxLength(40)(password)
              : false
          }
          helperText={
            password && password.length > 0
              ? !required(password) ||
                !minLength(8)(password) ||
                !maxLength(40)(password)
                ? t("form.passwordError")
                : null
              : null
          }
        />
        <TextField
          type="password"
          value={passwordConfirmation}
          name="passwordConfirmation"
          label={t("form.passwordConfirmation")}
          placeholder={t("form.passwordConfirmationPlaceholder")}
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            passwordConfirmation.length > 0
              ? !required(passwordConfirmation) ||
                !confirmSameValue(password, passwordConfirmation)
              : false
          }
          helperText={
            passwordConfirmation.length > 0
              ? !required(passwordConfirmation) ||
                !confirmSameValue(password, passwordConfirmation)
                ? t("form.passwordConfirmationError")
                : null
              : null
          }
        />

        <TextField
          value={comercialName}
          name="comercialName"
          label={t("form.comercialName")}
          fullWidth
          placeholder={t("form.comercialNamePlaceholder")}
          type="text"
          autoComplete="off"
          className={styles["text-field"]}
          onChange={handleInputChange}
          error={
            comercialName && comercialName.length > 0
              ? !required(comercialName) ||
                !minLength(2)(comercialName) ||
                !maxLength(40)(comercialName) ||
                !string(comercialName)
              : false
          }
          helperText={
            comercialName && comercialName.length > 0
              ? !required(comercialName) ||
                !minLength(2)(comercialName) ||
                !maxLength(40)(comercialName) ||
                !string(comercialName)
                ? t("form.comercialNameError")
                : null
              : null
          }
        />

        <TextField
          type="text"
          value={companyWeb}
          name="companyWeb"
          label={t("form.companyWeb")}
          placeholder={t("form.companyWebPlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            companyWeb && companyWeb.length > 0
              ? !webpage(companyWeb) ||
                !minLength(3)(companyWeb) ||
                !maxLength(40)(companyWeb)
              : false
          }
          helperText={
            companyWeb && companyWeb.length > 0
              ? !webpage(companyWeb)
                ? t("form.companyWebError1")
                : !minLength(3)(companyWeb)
                ? t("form.companyWebError2")
                : !maxLength(40)(companyWeb)
                ? t("form.companyWebError3")
                : null
              : null
          }
        />

        <TextField
          type="text"
          value={city}
          name="city"
          label={t("form.city")}
          placeholder={t("form.cityPlaceholder")}
          autoComplete="off"
          className={styles["text-field"]}
          fullWidth
          onChange={handleInputChange}
          error={
            city && city.length > 0
              ? !string(city) ||
                !required(city) ||
                !minLength(2)(city) ||
                !maxLength(40)(city)
              : false
          }
          helperText={
            city && city.length > 0
              ? !string(city) ||
                !required(city) ||
                !minLength(2)(city) ||
                !maxLength(40)(city)
                ? t("form.cityError")
                : null
              : null
          }
        />

        <Grid className={`mt-3 ${hideSubmitItemsClass}`}>
          <Recaptcha
            captchaSucceededHandler={captchaSucceededHandler}
            captchaFailedHandler={captchaFailedHandler}
          />
        </Grid>

        <div className="mt-2 mb-3">
          <small className="text-dark">
            {t("missingFields")}
          </small>
        </div>
      </div>

      <Grid
        container
        justifyContent="center"
        className={`mt-3 mb-3 ${hideSubmitItemsClass}`}
      >
        <TermsConditions />
      </Grid>
      <div className="d-flex justify-content-center mb-4">
        {loading ? (
          <Loader />
        ) : (
          <Button
            className="text-white custom-primary-btn"
            color="primary"
            variant="contained"
            disabled={disableRegisterFormFlag || loading}
            onClick={registerAction}
          >
            {label[currentLanguage]}
          </Button>
        )}
      </div>

      {error ? <Alert severity="error" message={error} /> : null}
    </>
  );
};

export default RegisterForm;

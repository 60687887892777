import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { Grid, Button, TextField } from "@material-ui/core";

import { AppointmentDetailDialog } from "../../components";

const RequestAppointmentDialog = ({
  createAppointmentRequest,
  showRequestDialog,
  setShowRequestDialog,
}) => {
  const { t } = useTranslation("public-profile");
  const [description, setDescription] = useState("");

  const toggleRequestDialog = () => {
    setDescription("");
    setShowRequestDialog(!showRequestDialog);
  };
  const requestAppointment = () => {
    createAppointmentRequest({ description });
    setShowRequestDialog(false);
  };

  const requestDialogContent = (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            fullWidth
            label={t("b2b.requestLabel")}
            placeholder={t("b2b.requestPlaceholder")}
            type="text"
            autoComplete="off"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
      </Grid>

      <div className="text-right mt-4">
        <Button size="small" className="mr-2" onClick={toggleRequestDialog}>
        {t("b2b.requestCancelBtn")}
        </Button>
        <Button
          // disabled={loading}
          size="small"
          variant="contained"
          color="primary"
          className="custom-primary-btn"
          onClick={requestAppointment}
        >
          {t("b2b.requestSendBtn")}
        </Button>
      </div>
    </>
  );

  return (
    <AppointmentDetailDialog
      title={t("b2b.requestTitle")}
      titleSize="lg"
      content={requestDialogContent}
      showDialog={showRequestDialog}
      toggleDialog={toggleRequestDialog}
    />
  );
};

export default RequestAppointmentDialog;

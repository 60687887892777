import { UPDATELEVEL } from "../actions";
const initialState = {
    service:{}
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    
      case UPDATELEVEL:
       
        return{
          service:action.data
        }  
      default:
        return state;
    }
  }
  
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextareaAutosize,
} from "@material-ui/core";

import { createAppointmentSurvey } from '../../services/AppointmentService';
import Form from "components/Form";
import { useTranslation } from "react-i18next";

const LoginFieldsSchema = [
  {
    name: 'alreadyMeet',
    placeholder: 'placeholders.alreadyMeet',
    value: '',
    validations: {
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
  },
  {
    name: 'companyMetRequirements',
    placeholder: 'placeholders.companyMetRequirements',
    value: '',
    validations: {
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
    evaluateValidationsFor: [
      'notMetRequirementsReason',
      'notMetRequirementsReasonText',
    ]
  },
  {
    name: 'notMetRequirementsReason',
    placeholder: 'placeholders.notMetRequirementsReason',
    value: '',
    validations: {
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
    skipValidationsIf: {
      'companyMetRequirements': ['Si', 'Yes'],
    },
    evaluateValidationsFor: ['notMetRequirementsReasonText']
  },
  {
    name: 'notMetRequirementsReasonText',
    placeholder: 'placeholders.notMetRequirementsReasonText',
    value: '',
    validations: {
      minLength: {
        errorMessage: { es: 'Al menos 6 caracteres', en: 'At least 6 characters' },
        min: 6,
      },
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
    skipValidationsIf: {
      'notMetRequirementsReason': [
        'Calidad',
        'Precio',
        'Logística',
        'Tiempo',
        'Quality',
        'Price',
        'Logistic',
        'Time',
      ],
      'companyMetRequirements': ['Si', 'Yes'],
    },
  },
  {
    name: 'businessViability',
    placeholder: 'placeholders.businessViability',
    value: '',
    validations: {
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
  },
  {
    name: 'duration',
    placeholder: 'placeholders.duration',
    value: '',
    validations: {
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
  },
  {
    name: 'currency',
    placeholder: 'placeholders.currency',
    value: '',
    validations: {
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
  },
  {
    name: 'totalAmount',
    value: '',
    validations: {
      required: {
        errorMessage: { es: 'Este campo es requerido', en: 'This field is required' },
      },
    },
  },
  {
    name: 'extraComents',
    placeholder: 'placeholders.extraComents',
    value: '',
    validations: {
      maxLength: {
        errorMessage: { es: 'Máximo 1000 caracteres', en: 'Maximum 1000 characters' },
        max: 1000,
      }
    }
  },
];

const MeetSurvey = ({ surveySubmit }) => {
  const { t } = useTranslation('meet-survey');
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const { meetId } = useParams();
  const [loading, setLoading] = useState(false);
  const [amountsOptions, setAmountsOptions] = useState([]);
  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  useEffect(() => {
    const { videoconferenceSurveyOptions } = eventInfo;
    if (videoconferenceSurveyOptions) {
      setAmountsOptions(videoconferenceSurveyOptions['amounts'] || []);
      setCurrenciesOptions(videoconferenceSurveyOptions['currencies'] || []);
    }
  }, [eventInfo]);
  const handleFormSubmit = async (formData) => {
    /**
     * Specific logic for CANIETI CIAT questions survey
     * Avoid storing unnecesary answers if this one is [Yes || Si]
     */
    if (formData.companyMetRequirements === t('answers.yes')) {
      formData.notMetRequirementsReason = '';
      formData.notMetRequirementsReasonText = '';
    }
    setLoading(true);
    try {
      await createAppointmentSurvey(formData, meetId);
    } catch {}
    setLoading(false);
    surveySubmit();
  };
  return (
    <Form onSubmit={handleFormSubmit} fields={LoginFieldsSchema}>
      {({ handleSubmit, handleChange, valid, fields }) => (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className="mb-3 w-100">
            <FormControl fullWidth error={!!fields.alreadyMeet.errors.length}>
              <InputLabel>{t(fields.alreadyMeet.placeholder)}</InputLabel>
              <Select
                disabled={loading}
                value={fields.alreadyMeet.value}
                onChange={handleChange('alreadyMeet')}
              >
                <MenuItem value={t('answers.yes')}>{t('answers.yes')}</MenuItem>
                <MenuItem value={t('answers.no')}>{t('answers.no')}</MenuItem>
              </Select>
              <FormHelperText>{fields.alreadyMeet.errors.join('. ')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className="mb-3 w-100">
            <FormControl fullWidth error={!!fields.companyMetRequirements.errors.length}>
              <InputLabel>{t(fields.companyMetRequirements.placeholder)}</InputLabel>
              <Select
                disabled={loading}
                value={fields.companyMetRequirements.value}
                onChange={handleChange('companyMetRequirements')}
              >
                <MenuItem value={t('answers.yes')}>{t('answers.yes')}</MenuItem>
                <MenuItem value={t('answers.moderately')}>{t('answers.moderately')}</MenuItem>
                <MenuItem value={t('answers.no')}>{t('answers.no')}</MenuItem>
              </Select>
              <FormHelperText>{fields.companyMetRequirements.errors.join('. ')}</FormHelperText>
            </FormControl>
          </Grid>
          {[t('answers.moderately'), t('answers.no')].includes(fields.companyMetRequirements.value) && (
            <Grid item className="mb-3 w-100">
              <FormControl fullWidth error={!!fields.notMetRequirementsReason.errors.length}>
                <InputLabel>{t(fields.notMetRequirementsReason.placeholder)}</InputLabel>
                <Select
                  disabled={loading}
                  value={fields.notMetRequirementsReason.value}
                  onChange={handleChange('notMetRequirementsReason')}
                >
                  <MenuItem value={t('answers.quality')}>{t('answers.quality')}</MenuItem>
                  <MenuItem value={t('answers.price')}>{t('answers.price')}</MenuItem>
                  <MenuItem value={t('answers.logistic')}>{t('answers.logistic')}</MenuItem>
                  <MenuItem value={t('answers.time')}>{t('answers.time')}</MenuItem>
                  <MenuItem value={t('answers.others')}>{t('answers.others')}</MenuItem>
                </Select>
                <FormHelperText>{fields.notMetRequirementsReason.errors.join('. ')}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          {
            fields.notMetRequirementsReason.value === t('answers.others') &&
            [t('answers.moderately'), t('answers.no')].includes(fields.companyMetRequirements.value) && (
            <Grid item className="mb-3 w-100">
              <FormControl fullWidth error={!!fields.notMetRequirementsReasonText.errors.length}>
                <InputLabel>{t(fields.notMetRequirementsReasonText.placeholder)}</InputLabel>
                <Input
                  disabled={loading}
                  value={fields.notMetRequirementsReasonText.value}
                  onChange={handleChange('notMetRequirementsReasonText')}
                />
                <FormHelperText>{fields.notMetRequirementsReasonText.errors.join('. ')}</FormHelperText>
              </FormControl>
            </Grid>
          )}
          <Grid item className="mb-3 w-100">
            <FormControl fullWidth error={!!fields.businessViability.errors.length}>
              <InputLabel>{t(fields.businessViability.placeholder)}</InputLabel>
              <Select
                disabled={loading}
                value={fields.businessViability.value}
                onChange={handleChange('businessViability')}
              >
                <MenuItem value={t('answers.highProb')}>{t('answers.highProb')}</MenuItem>
                <MenuItem value={t('answers.mediumProb')}>{t('answers.mediumProb')}</MenuItem>
                <MenuItem value={t('answers.lowProb')}>{t('answers.lowProb')}</MenuItem>
              </Select>
              <FormHelperText>{fields.businessViability.errors.join('. ')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className="mb-3 w-100">
            <FormControl fullWidth error={!!fields.duration.errors.length}>
              <InputLabel>{t(fields.duration.placeholder)}</InputLabel>
              <Select
                disabled={loading}
                value={fields.duration.value}
                onChange={handleChange('duration')}
              >
                <MenuItem value={t('answers.shortTerm')}>{t('answers.shortTerm')}</MenuItem>
                <MenuItem value={t('answers.midTerm')}>{t('answers.midTerm')}</MenuItem>
                <MenuItem value={t('answers.longTerm')}>{t('answers.longTerm')}</MenuItem>
              </Select>
              <FormHelperText>{fields.duration.errors.join('. ')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className="mb-3 w-100">
            <FormControl fullWidth error={!!fields.currency.errors.length}>
              <InputLabel>{t(fields.currency.placeholder)}</InputLabel>
              <Select
                disabled={loading}
                value={fields.currency.value}
                onChange={handleChange('currency')}
              >
                {currenciesOptions.map((currencyOption) => (
                  <MenuItem key={`currency-option-${currencyOption}`} value={currencyOption}>{currencyOption}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{fields.currency.errors.join('. ')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className="mb-3 w-100">
            <FormControl fullWidth error={!!fields.totalAmount.errors.length}>
              <InputLabel>
                {fields.duration.value === t('answers.shortTerm')
                  ? t("placeholders.totalAmountShort")
                  : t("placeholders.totalAmountLong")
                }
              </InputLabel>
              <Select
                disabled={loading}
                value={fields.totalAmount.value}
                onChange={handleChange('totalAmount')}
              >
                {amountsOptions.map((amountOption) => (
                  <MenuItem key={`amount-option-${amountOption}`} value={amountOption}>{amountOption}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{fields.totalAmount.errors.join('. ')}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item className="mb-3 w-100">
            <FormControl fullWidth error={!!fields.extraComents.errors.length}>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder={t(fields.extraComents.placeholder)}
                disabled={loading}
                value={fields.extraComents.value}
                onChange={handleChange('extraComents')}
              />
              <FormHelperText>{fields.extraComents.errors.join('. ')}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} container direction="row" justifyContent="center" className="mb-2">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={handleSubmit}
                disabled={!valid}
                variant="contained"
                color="primary"
              >
                {t("submit")}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </Form>
  );
};
export default MeetSurvey;

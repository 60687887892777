import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
import {
  COMPANIES,
  COMPANIES_FAILED,
  COMPANIES_SUCCESS,
  COMPANY,
  COMPANY_FAILED,
  COMPANY_SUCCESS,
  ADD_COMPANY,
  ADD_COMPANY_FAILED,
  ADD_COMPANY_SUCCESS,
  EDIT_COMPANY,
  EDIT_COMPANY_FAILED,
  EDIT_COMPANY_SUCCESS,
  UPLOAD_COMPANY_AVATAR,
  UPLOAD_COMPANY_AVATAR_FAILED,
  UPLOAD_COMPANY_AVATAR_SUCCESS,
} from "./Constants";

export const companies = () => ({ type: COMPANIES });
export const companiesFailed = (error) => ({
  type: COMPANIES_FAILED,
  data: error,
});
export const companiesSuccess = (response) => ({
  type: COMPANIES_SUCCESS,
  data: response,
});

export const getCompanies = () => async (dispatch) => {
  try {
    const response = await axios.get("/company");
    if (response.status === 200) {
      const companiesResponse = response.data.data;
      dispatch(companiesSuccess(companiesResponse));
    }
  } catch (error) {
    dispatch(companiesFailed(handleAxiosError(error)));
  }
};

export const company = () => ({ type: COMPANY });
export const companyFailed = (error) => ({
  type: COMPANY_FAILED,
  data: error,
});
export const companySuccess = (response) => ({
  type: COMPANY_SUCCESS,
  data: response,
});

export const getCompany = (companyId) => async (dispatch) => {
  dispatch(company());

  try {
    const response = await axios.get(`/company/${companyId}`);
    if (response.status === 200) {
      const companyResponse = response.data.data;
      dispatch(companySuccess(companyResponse));
    }
  } catch (error) {
    dispatch(companyFailed(handleAxiosError(error)));
  }
};

export const addCompany = () => ({ type: ADD_COMPANY });
export const addCompanyFailed = (error) => ({
  type: ADD_COMPANY_FAILED,
  data: error,
});
export const addCompanySuccess = (response) => ({
  type: ADD_COMPANY_SUCCESS,
  data: response,
});

export const addNewCompany = (data) => async (dispatch) => {
  try {
    const response = await axios.get("/company", {
      customers: data.customers,
      sections: data.sections,
    });
    if (response.status === 200) {
      dispatch(addCompanySuccess(response.data.data));
    }
  } catch (error) {
    dispatch(addCompanyFailed(handleAxiosError(error)));
  }
};

export const editCompany = () => ({ type: EDIT_COMPANY });
export const editCompanyFailed = (error) => ({
  type: EDIT_COMPANY_FAILED,
  data: error,
});
export const editCompanySuccess = (response) => ({
  type: EDIT_COMPANY_SUCCESS,
  data: response,
});

export const editExistingCompany = (companyId, data) => async (dispatch) => {
  try {
    const response = await axios.get(`/company/${companyId}`, {
      customers: data.customers,
      sections: data.sections,
    });
    if (response.status === 200) {
      dispatch(editCompanySuccess(response.data.data));
    }
  } catch (error) {
    dispatch(editCompanyFailed(handleAxiosError(error)));
  }
};

// -- UPDATE company avatar --
export const uploadAvatarCompany = () => ({ type: UPLOAD_COMPANY_AVATAR });
export const uploadAvatarFailedCompany = (error) => ({
  type: UPLOAD_COMPANY_AVATAR_FAILED,
  data: error,
});
export const uploadAvatarSuccessCompany = (response) => ({
  type: UPLOAD_COMPANY_AVATAR_SUCCESS,
  data: response,
});

export const uploadCompanyAvatar = (companyId, logo) => async (dispatch) => {
  dispatch(uploadAvatarCompany());

  try {
    const response = await axios.put(`/company/${companyId}/image`, { logo });
    if (response.status === 200) {
      dispatch(uploadAvatarSuccessCompany());
    }
  } catch (error) {
    dispatch(uploadAvatarFailedCompany(handleAxiosError(error)));
  }
};

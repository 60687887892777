import React from "react";

import WireTransfer from "./WireTransfer";
import PaypalMethod from "./PaypaylMethod";
import UserInfo from "./InformationContent/UserInfo";
import styles from "./information-payment.module.scss";

import formatNumberPrice from "../../utils/format-number-price";
import { useTranslation } from "react-i18next";

const InformationPayment = ({ selectedServicePrices, eventInfo }) => {
  const { t } = useTranslation("dashboard");
  const { price, priceWithIVA, priceDescription } = selectedServicePrices;

  return (
    <div className={styles["divParent"]}>
      <div className={styles["gridUser"]}>
        <UserInfo />
        <div className={styles["divPayment"]}>
          <WireTransfer />
          <div style={{ height: "30px" }}></div>
          <PaypalMethod price={priceWithIVA} />
          <div
            style={{
              marginTop: "35px",
              marginBottom: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <h4 className="font-weight-bold">{t("payment.totalAmount")}</h4>
            <h5>
              {formatNumberPrice(price)} {priceDescription}
            </h5>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p>
          {t("payment.reportPayment")}{" "}
          <strong>
            {eventInfo.contactInformation
              ? eventInfo.contactInformation.email
              : null}
          </strong>{" "}
          {t("payment.info")}{" "}
          <strong>
            {eventInfo.contactInformation
              ? eventInfo.contactInformation.phone
              : null}
          </strong>
        </p>
      </div>
    </div>
  );
};
export default InformationPayment;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@material-ui/core";
import { useSelector } from "react-redux";

import { PageTitle } from "components";

import TabPanel from "./components/TabPanel";

const MyAppointments = () => {
  const { t, i18n } = useTranslation("my-appointments");
  const currentLanguage = i18n.language;

  const { page } = useSelector((store) => store.pendingAppointments);
  const { loggedUser } = useSelector((store) => store.loggedUser);

  let roleType = null;
  if (loggedUser) {
    const {
      role: { code },
    } = loggedUser;
    roleType = code;
  }

  const statusTabs = ["ALL", "STS006", "STS002", "STS004", "STS005"];
  const tabsContent = [
    {
      label: {
        es: "Todas",
        en: "All",
      },
      tabWidth: "20%",
    },
    {
      label: {
        es: roleType === "RL003" ? "Solicitadas" : "Recibidas",
        en: roleType === "RL003" ? "Requested" : "Received",
      },
      tabWidth: "20%",
    },
    {
      label: {
        es: "Agendadas",
        en: "Accepted",
      },
      tabWidth: "20%",
    },
    {
      label: {
        es: "Rechazadas",
        en: "Rejected",
      },
      tabWidth: "20%",
    },
    {
      label: {
        es: "Canceladas",
        en: "Canceled",
      },
      tabWidth: "20%",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(page);
  const changeTabHandler = (_event, newTab) => setSelectedTab(newTab);

  const onTabChange = (newTab) => {
    return setSelectedTab(newTab);
  };

  return (
    <>
      <PageTitle titleHeading={t("pageTitle")} showPageTitleIconBox={false} />
      <Tabs
        value={selectedTab}
        onChange={changeTabHandler}
        aria-label="Estatus de citas"
        indicatorColor="primary"
      >
          {tabsContent.map(({ label, tabWidth }, index) => (
            <Tab
              key={index}
              label={label[currentLanguage]}
              style={{ width: tabWidth }}
            />
          ))}
      </Tabs>
      <TabPanel
        value={selectedTab}
        role={roleType}
        status={statusTabs[selectedTab]}
        emitTabChange={onTabChange}
      />
    </>
  );
};

export default MyAppointments;

import { useTranslation } from "react-i18next";
import React from "react";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPayPal from "../ReactPayPal";

const PaypalMethod = ({ price }) => {
  const { t } = useTranslation("register");
  const [selectedValue, setSelectedValue] = React.useState(true);

  return (
    <div
      className="w-100 mt-2 mr-4"
      style={{
        border: "solid 2px lightgray",
        padding: "10px",
        paddingLeft: "30px",
        paddingRight: "30px",
        position: "relative",
        top: "9px",
      }}
    >
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          icon={["fab", "paypal"]}
          className="font-size-xxl mr-4 text-primary"
        />

        <Typography
          className="pb-2"
          style={{
            position: "relative",
            top: "9px",
            fontWeight: "bold",
            fontSize: "12px",
          }}
        >
          {t("payments.paypal")}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <FontAwesomeIcon
          icon={"angle-up"}
          color="gray"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSelectedValue(!selectedValue);
          }}
        />
      </div>
      <div
        style={{ width: "100%", marginTop: "30px" }}
        className={selectedValue ? "" : "d-none"}
      >
        <ReactPayPal price={price} />
      </div>
    </div>
  );
};
export default PaypalMethod;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { getAdminAppointmentsVideoconference } from "services/AppointmentService";
import { DataTable, ModalMenu, DatePicker } from "components";
import { parseDateWithStartTime } from "utils/helpers/date";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";
import { getCatalogues } from "redux/actions";

import styles from "./RolAdminOrGod.module.scss";

const RolAdminOrGod = ({ status }) => {
  const { i18n, t } = useTranslation("videoconferences");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();
  const navigationHistory = useHistory();
  const location = useLocation();
  const { appointmentTimeSlots } = useUserCatalogs();

  const { catalogues } = useSelector((store) => store.catalogues);

  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState(null);
  const [dataTableData, setDataTableData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [refreshTable, setRefreshTable] = useState(1);
  const [filterFields, setFilterFields] = useState([]);

  const [dateFilter, setDateFilter] = useState(new Date());

  const dataTableColumns = [
    {
      name: {
        es: "NOMBRE DEL PROVEEDOR",
        en: "PROVIDER NAME",
      },
      selector: "seller[0].firstName",
      cell: ({ seller }) => {
        return `${seller[0].firstName} ${seller[0].lastName}`;
      },
      sortable: true,
    },
    {
      name: {
        es: "EMPRESA DEL PROVEEDOR",
        en: "PROVIDER COMPANY",
      },
      selector: "seller[0].comercialName",
      sortable: true,
    },
    {
      name: {
        es: "EMPRESA DEL COMPRADOR",
        en: "BUYER COMPANY",
      },
      selector: "buyer[0].companyId[0].businessName",
      sortable: true,
    },
    {
      name: {
        es: "CORREO DEL PROVEEDOR",
        en: "PROVIDER EMAIL",
      },
      selector: "seller[0].email",
      sortable: true,
    },
    {
      name: {
        es: "CORREO DEL COMPRADOR",
        en: "BUYER EMAIL",
      },
      selector: "buyer[0].email",
      sortable: true,
    },
    {
      name: {
        es: "FECHA DE LA CITA",
        en: "APPOINTMENT DATE",
      },
      selector: "date",
      sortable: true,
    },
    {
      name: {
        es: "ESTATUS",
        en: "STATUS",
      },
      selector: `status[0].name[${currentLanguage}]`,
      sortable: true,
    },
    {
      name: {
        es: "VIDEOCONFERENCIA",
        en: "VIDEOCONFERENCE",
      },
      selector: `statusVideoconferenceId[0].name[${currentLanguage}]`,
      sortable: true,
    },
    {
      name: {
        es: "ACCIONES",
        en: "ACTIONS",
      },
      cell: ({ _id }) => {
        const actionItem = () => {
          navigationHistory.push("appointment/" + _id);
        };
        const itemsMenu = [
          {
            name: {
              es: "Información de la cita",
              en: "Appointment information",
            },
            action: actionItem,
          },
        ];
        return <ModalMenu Items={itemsMenu} />;
      },
    },
  ];

  useEffect(() => {
    setRefreshTable(refreshTable + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, status]);

  useEffect(() => {
    dispatch(getCatalogues(["businessNames"]));
    dispatch(getCatalogues(["usersComercialNames"]));
  }, []);

  // Listen to backend success response and set default slot time in select filter
  useEffect(() => {
    if (responseData && responseData.currentSlotFilter) {
      setFilterFields([
        {
          displayName: { es: "EMPRESA(proveedor)", en: "COMPANY(provider)"},
          selector: "comercialName",
          defaultSelectorKey: "seller.comercialName",
          catalog: catalogues.usersComercialNames,
        },
        {
          displayName: { es: "EMPRESA(comprador)", en: "COMPANY(buyer)"},
          selector: "businessName",
          defaultSelectorKey: "buyer.companyId.businessName",
          catalog: catalogues.businessNames,
        },
        {
          displayName: { es: "SLOT", en: "SLOT"},
          selector: "timeSlot",
          catalog: appointmentTimeSlots,
          omitRemoveFilters: true,
          defaultValue: responseData.currentSlotFilter,
        },
      ]);
    }
  }, [responseData]);

  const onDatePickerChanged = (date) => {
    setDateFilter(moment(date).format("YYYY/MM/DD"));
  };
  const resetAppointmentsDate = () => {
    if (dateFilter) setDateFilter(null);
  };

  const handleFilterChange = async (
    pagination,
    sorting,
    searchFilter,
    selectsFilters
  ) => {
    setLoading(true);

    const internServerErrorMessage = "Error interno de servidor";

    try {
      const response = await getAdminAppointmentsVideoconference(
        status,
        pagination,
        sorting,
        searchFilter,
        dateFilter,
        selectsFilters
      );

      if (response.status === 200) {
        const data = response.data.data.dataCleaned;

        if (!data || !data.length) {
          setDataTableData([]);
          setTotalRows(0);
        } else {
          const formattedData = data.filter(
            (dataAppointment) =>
              (dataAppointment.date = parseDateWithStartTime(
                dataAppointment.date,
                dataAppointment.startTime
              ))
          );
          setDataTableData(formattedData);
          setTotalRows(response.data.data.pagination.totalItems);
        }

        setResponseData(response.data.data);
      } else {
        console.error(internServerErrorMessage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="text-right">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            className={styles["reset-date-filter-btn"]}
            onClick={resetAppointmentsDate}
            disabled={loading || !dateFilter}
          >
            X
          </button>
          <DatePicker
            label={t("dateLabel")}
            value={dateFilter}
            onChange={onDatePickerChanged}
            disabled={loading}
          />
        </div>
      </div>
      <DataTable
        routeConfig={{
          history: navigationHistory,
          route: "/my-appointments",
          locationSearch: location.search,
        }}
        refreshTable={refreshTable}
        columns={dataTableColumns}
        filterFields={filterFields}
        data={dataTableData}
        pagination
        paginationServer
        enableSearch
        keyField="_id"
        onFilterChange={handleFilterChange}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalRows}
        progressPending={loading}
      />
    </>
  );
};

export default RolAdminOrGod;

import { useTranslation } from "react-i18next";
import React from "react";
import { Button, Grid, TextField, MenuItem } from "@material-ui/core";

import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const LimitedBuyer = ({ user, openAppointmentRequest = false }) => {
  const { t, i18n } = useTranslation(["profile", "public-profile"]);
  const currentLanguage = i18n.language;

  const {
    firstName,
    lastName,
    position,
    personType,
    needs,
    companyId: { comercialName, companyWeb },
  } = user;

  const { personTypes } = useUserCatalogs();

  return (
    <div data-testid="limited-buyer-data">
      <Grid container spacing={4}>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={firstName}
            name="firstName"
            label={t("profile:rightForm.firstName")}
            placeholder={t("profile:rightForm.firstNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={lastName}
            name="lastName"
            label={t("profile:rightForm.lastName")}
            placeholder={t("profile:rightForm.lastNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={position}
            name="position"
            label={t("profile:rightForm.position")}
            placeholder={t("profile:rightForm.positionPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={comercialName}
            name="comercialName"
            label={t("profile:rightForm.comercialName")}
            placeholder={t("profile:rightForm.comercialNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <TextField
            name="personType"
            label={t("profile:rightForm.personType")}
            fullWidth
            disabled
            select
            value={personType}
          >
            {personTypes.array.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label[currentLanguage]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={companyWeb}
            name="companyWeb"
            label={t("profile:rightForm.companyWeb")}
            placeholder={t("profile:rightForm.companyWebPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-4 pt-4">
        <Grid item className="w-100">
          <TextField
            type="text"
            value={needs}
            name="needs"
            label={t("profile:rightForm.needsBuyer")}
            placeholder={t("profile:rightForm.needsBuyerPlaceholder")}
            autoComplete="off"
            maxRows="4"
            multiline
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
      {openAppointmentRequest && (
        <Button
          color="primary"
          variant="contained"
          className="font-weight-bold mt-4 align-self-end custom-primary-btn"
          onClick={openAppointmentRequest}
        >
          {t("public-profile:b2b.requestOpenModalBtn")}
        </Button>
      )}
    </div>
  );
};

export default LimitedBuyer;

/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import { getBuyers } from "services/UserService";
import { getCatalogues } from "redux/actions/Catalogues";
import ModalMenu from "components/ModalMenu";
import DataTable from "components/DataTable";
import PageLayout from "components/PageLayout";
import TooltipTruncate from "../../components/TooltipTruncate";

const Buyers = ({ history, location }) => {
  const { t } = useTranslation("buyers");
  const dispatch = useDispatch();
  const { catalogues } = useSelector((store) => store.catalogues);

  const [buyers, setBuyers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const dataTableColumns = [
    {
      name: { es: "NOMBRE DE CONTACTO", en: "CONTACT NAME" },
      selector: "firstName",
      sortable: true,
      cell: (row) => <>{`${row.firstName} ${row.lastName}`}</>,
    },
    {
      name: { es: "EMPRESA", en: "COMPANY" },
      selector: "companyId[0].businessName",
      sortable: true,
      width: "auto",
    },
    {
      name: { es: "REQUERIMIENTOS", en: "REQUIREMENTS" },
      selector: "needs",
      sortable: true,
      cell: ({ needs }) => <TooltipTruncate needs={needs} />,
    },
    {
      name: { es: "ACCIONES", en: "ACTIONS" },
      cell: ({ _id }) => {
        const actionItem = () => {
          history.push("public-profile/" + _id);
        };
        const itemsMenu = [
          {
            name: { es: "Ver perfil", en: "View profile" },
            action: actionItem,
          },
        ];
        return <ModalMenu Items={itemsMenu} />;
      },
      grow: 0,
    },
  ];

  useEffect(() => {
    dispatch(getCatalogues(["businessNames"]));
  }, []);

  const handleFilterChange = async (
    pagination,
    sorting,
    searchFilter,
    selectsFilters
  ) => {
    setLoading(true);
    try {
      const res = await getBuyers(
        pagination,
        sorting,
        searchFilter,
        selectsFilters
      );
      if (res.status === 200) {
        const { users, pagination } = res.data.data;
        setTotalRows(pagination.totalItems);
        setBuyers(users);
      } else {
        setError("Error interno de servidor");
      }
    } catch (error) {
      console.error(error);
      setError("Error interno de servidor");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout
      titleHeading={t("pageTitle")}
      errorMessage="No hay compradores en la base de datos"
      error={error}
    >
      <DataTable
        routeConfig={{
          history,
          locationSearch: location.search,
        }}
        columns={dataTableColumns}
        filterFields={[
          {
            displayName: { es: "EMPRESA", en: "COMPANY"},
            selector: "businessName",
            catalog: catalogues.businessNames,
          },
        ]}
        data={buyers}
        pagination
        paginationServer
        enableSearch
        keyField="_id"
        onFilterChange={handleFilterChange}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalRows}
        progressPending={loading}
      />
    </PageLayout>
  );
};

export default Buyers;

import { ROLES, ROLES_FAILED, ROLES_SUCCESS } from "../actions";

const initialState = {
  loading: false,
  error: false,
  roles: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET roles-----
    case ROLES:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case ROLES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.data,
      };

    default:
      return state;
  }
}

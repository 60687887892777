import { minLength, maxLength, string } from "..";

export const validString = (value, minLengthValue = 3, maxLengthValue = 25) => {
  if (value) {
    return value.length
      ? !minLength(minLengthValue)(value) ||
          !maxLength(maxLengthValue)(value) ||
          !string(value)
      : false;
  }
  return false;
};

export const validShortNumber = (value) => {
  if (value) {
    return value.length
      ? !minLength(1)(value) || !maxLength(5)(value) || !string(value)
      : false;
  }
  return false;
};

export const validPostalCode = (value) => {
  if (value) {
    return value.length
      ? !minLength(5)(value) || !maxLength(5)(value) || !string(value)
      : false;
  }
  return false;
};

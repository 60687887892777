import { useTranslation } from "react-i18next";
import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaymentTransfer from "components/Register/payment-transfer";

const WireTransfer = () => {
  const { t } = useTranslation("register");
  const { eventInfo } = useSelector((store) => store.eventInfo);
  return (
    <div
      className="w-100 mt-2 mr-4"
      style={{
        border: "solid 2px lightgray",
        padding: "10px",
        display: "grid",
        gridTemplateColumns: "10% 90%",
      }}
    >
      <div className="">
        <FontAwesomeIcon
          icon={["fas", "university"]}
          className="font-size-xxl mr-3"
          color="gray"
        />
      </div>
      <div>
        <div>
          <Typography
            className="pb-2"
            style={{ position: "relative", top: "9px", fontWeight: "bold" }}
          >
            {t("payments.transfer")}
          </Typography>
        </div>
        <PaymentTransfer
          transferData={
            eventInfo.paymentMethods ? eventInfo.paymentMethods[0] : null
          }
        />
      </div>
    </div>
  );
};
export default WireTransfer;

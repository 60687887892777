import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { List, Typography, ListItem } from "@material-ui/core";

import useRouter from "utils/useRouter";
import SidebarMenuListItem from "./SidebarMenuListItem";

const SidebarMenuList = (props) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {
    pages,
    depth,
    // ...rest
  } = props;

  const menuSections = [
    {
      id: "inicio",
      name: {
        es: "INICIO",
        en: "DASHBOARD",
      },
    },
    {
      id: "virtual-tour",
      name: {
        es: "TOUR",
        en: "TOUR",
      },
    },
    {
      id: "b2b",
      name: {
        es: "ENCUENTRO DE NEGOCIOS B2B",
        en: "B2B",
      },
    },
    {
      id: "admin",
      name: {
        es: "ADMIN",
        en: "ADMIN",
      },
    },
  ];

  const groupedPages = pages.reduce((r, a) => {
    r[a.groupId] = r[a.groupId] || [];
    r[a.groupId].push(a);
    return r;
  }, Object.create(null));

  return (
    <List className="p-0">
      {menuSections.map((section, sectionIndex) => {
        return (
          <div key={sectionIndex}>
            {groupedPages[section.id] ? (
              <ListItem
                className="text-dark font-weight-bold"
                style={{ fontSize: "12px" }}
              >
                {section.name[currentLanguage]}
              </ListItem>
            ) : null}

            {groupedPages[section.id]
              ? groupedPages[section.id].map((page) => (
                  <SidebarMenuListItem
                    depth={depth}
                    href={page.to}
                    icon={page.icon}
                    key={page.label[currentLanguage]}
                    label={page.badge}
                    title={page.label[currentLanguage]}
                    hasAccess={page.hasAccess}
                    highlight={page.highlight}
                  />
                ))
              : null}
          </div>
        );
      })}
    </List>
  );
};

SidebarMenuList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const SidebarMenu = (props) => {
  const { title, pages, className, component: Component, ...rest } = props;

  const router = useRouter();

  return (
    <Component {...rest} className={className}>
      {title && (
        <Typography className="app-sidebar-heading">{title}</Typography>
      )}
      <SidebarMenuList depth={0} pages={pages} router={router} />
    </Component>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

SidebarMenu.defaultProps = {
  component: "nav",
};

export default SidebarMenu;

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import React, {
  useEffect,
  useState,
} from 'react';

import curp from '../../utils/form-validations/curp';
import email from '../../utils/form-validations/email';
import float from '../../utils/form-validations/float';
import length from '../../utils/form-validations/length';
import match from '../../utils/form-validations/match';
import maxLength from '../../utils/form-validations/maxLength';
import maxValue from '../../utils/form-validations/maxValue';
import minLength from '../../utils/form-validations/minLength';
import minValue from '../../utils/form-validations/minValue';
import numeric from '../../utils/form-validations/numeric';
import required from '../../utils/form-validations/required';
import requiredDate from '../../utils/form-validations/requiredDate';
import requiredSelected from '../../utils/form-validations/requiredSelected';
import string from '../../utils/form-validations/string';

const Form = (props) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [valid, setValid] = useState(false);
  const [fields, setFields] = useState({});

  useEffect(() => {
    createFields();
  }, []);

  const createFields = () => {
    const { fields: propFields } = props;
    const fields = {};
    for (const field of propFields) {
      const value = field.value;

      fields[field.name] = {
        ...field,
        errors: [],
        valid: field.validations ? false : true,
        value: field.options ? field.options[0].value : value,
      };

      // Validate non required fields when they're being created - avoid having to type in them to enable submit btn
      if (field.validations && field.validations?.required === undefined) {
        validateField(fields[field.name]);
      }

      if (field.value !== '' || field.options !== undefined) {
        validateOrSetErrors(fields[field.name], field.name);
      }
    }
    setFields(fields);
  };
  const handleChange = (name) => (event) => {
    try {
      const input = fields[name];

      if (event instanceof Date) {
        input.value = event;
      } else if (event.target.type === 'checkbox') {
        // Handle boolean values - checkboxes
        input.value = event.target.checked;
      } else {
        input.value = event.target.value;
      }

      if (input) {
        validateOrSetErrors(input, name);
      }
    } catch (e) {
      //
    }
  };
  const validateOrSetErrors = (field, name) => {
    validateField(field);
    if (field.watch && field.watch.length) {
      for (const input of field.watch) {
        validateField(fields[input]);
      }
    }
    if (field.evaluateValidationsFor) {
      field.evaluateValidationsFor.forEach(validateFieldName => {
        if (fields[validateFieldName] !== undefined) {
          // TODO: Create validation for NON error-red highlight
          // const highlightField = fields[validateFieldName].value === '';
          // validateField(fields[validateFieldName], highlightField);
          validateField(fields[validateFieldName]);
        }
      });
    }
    const isFormValid = Object.keys(fields).every(
      (field) => fields[field].valid,
    );
    setFields({
      ...fields,
      [name]: {
        ...field,
        value: field.value,
      },
    });
    setValid(isFormValid);
  };
  const handleSubmit = () => {
    if (!valid && !props.noValidation) {
      highlightRequired();
    } else {
      if (props.onSubmit) {
        const parsedFormWithValues = {};
        Object.keys(fields).forEach(
          (name) => (parsedFormWithValues[name] = fields[name].value),
        );
        props.onSubmit(parsedFormWithValues);
      }
    }
  };
  const highlightRequired = () => {
    Object.keys(fields).forEach((name) => {
      const input = fields[name];
      validateOrSetErrors(input, name);
    });
  };
  const validateField = (field, skipValidation = false) => {
    // Skip Validation IF - Conditions to avoid Validation
    if (field.skipValidationsIf && !skipValidation) {
      skipValidation = Object.keys(field.skipValidationsIf).some(skipFieldName => {
        if (
          fields[skipFieldName] &&
          fields[skipFieldName].value !== undefined &&
          typeof field.skipValidationsIf[skipFieldName] === 'object'
        ) {
          return field.skipValidationsIf[skipFieldName].includes(fields[skipFieldName].value);
        }
        return false;
      });
    }
    if (skipValidation) {
      field.errors = [];
      field.valid = true;
      return;
    }
    if (field.validations) {
      const validations = field.validations;
      const errors = [];
      if (validations.required) {
        if (
          typeof field.value === 'object' &&
          field.value.length !== 0 &&
          typeof field.value[0] === 'object'
        ) {
          if (!requiredSelected(field.value)) {
            errors.push(validations.required.errorMessage[currentLanguage]);
          }
        } else if (field.value instanceof Date) {
          if (!requiredDate(field.value)) {
            errors.push(validations.required.errorMessage[currentLanguage]);
          }
        } else {
          if (!required(field.value)) {
            errors.push(validations.required.errorMessage[currentLanguage]);
          }
        }
      }

      if (validations.dateFormat) {
        if (!requiredDate(field.value)) {
          errors.push(validations.dateFormat.errorMessage[currentLanguage]);
        }
      }
      if (validations.emailFormat) {
        if (!email(field.value)) {
          errors.push(validations.emailFormat.errorMessage[currentLanguage]);
        }
      }
      if (validations.curp) {
        if (!curp(field.value)) {
          errors.push(validations.curp.errorMessage[currentLanguage]);
        }
      }
      if (validations.minLength) {
        if (!minLength(validations.minLength.min)(field.value)) {
          errors.push(validations.minLength.errorMessage[currentLanguage]);
        }
      }
      if (validations.maxLength) {
        if (!maxLength(validations.maxLength.max)(field.value)) {
          errors.push(validations.maxLength.errorMessage[currentLanguage]);
        }
      }
      if (validations.minValue) {
        if (!minValue(validations.minValue.min)(field.value)) {
          errors.push(validations.minValue.errorMessage[currentLanguage]);
        }
      }
      if (validations.maxValue) {
        if (!maxValue(validations.maxValue.max)(field.value)) {
          errors.push(validations.maxValue.errorMessage[currentLanguage]);
        }
      }
      if (validations.match) {
        const matchingField = fields[validations.match.match];
        if (matchingField) {
          if (!match(matchingField.value)(field.value)) {
            errors.push(validations.match.errorMessage[currentLanguage]);
          }
        }
      }
      if (validations.string) {
        if (!string(field.value)) {
          errors.push(validations.string.errorMessage[currentLanguage]);
        }
      }
      if (validations.length) {
        if (!length(validations.length.length)(field.value)) {
          errors.push(validations.length.errorMessage[currentLanguage]);
        }
      }
      if (validations.numeric) {
        if (!numeric(field.value)) {
          errors.push(validations.numeric.errorMessage[currentLanguage]);
        }
      }
      if (validations.float) {
        if (!float(field.value)) {
          errors.push(validations.float.errorMessage[currentLanguage]);
        }
      }

      if (validations.required === undefined && field.value === '') {
        // Non required empty fields
        field.errors = [];
        field.valid = true;
      } else {
        let validate = true;
        if (validations.requiredIf) {
          const input = fields[validations.requiredIf.field];
          if (input) {
            validate = input.value === validations.requiredIf.equals;
          }
        }
        if (validate) {
          // Modify field
          field.errors = errors;
          field.valid = errors.length > 0 ? false : true;
        } else {
          // Non required empty fields bc of condition
          field.errors = [];
          field.valid = true;
        }
      }
    }
  };

  // First render doesn't have fields, so this is necesary
  if (Object.keys(fields).length === 0) {
    return <></>;
  }
  return props.children({
    fields,
    handleChange,
    handleSubmit,
    highlightRequired,
    valid,
  });
};
export default Form;

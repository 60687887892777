import https from 'https';

/** 
 * Asynchronous Url Validator
 * @param {Object} httpsOptions - Https request options (see reference at the end)
 * @param {Object} dataToSend - A valid JSON Object
 * @return {Promise<boolean>} true if url is working, otherwise false * 
 */
export default (httpsOptions, dataToSend) => {

    let data = null;
    if (dataToSend) {
        data = JSON.stringify(dataToSend);
    }

    return new Promise(function(resolve, reject) {
        const req = https.request(httpsOptions, (res) => {
            res.on('data', () => {
                if (res.statusCode < 400) {
                    return resolve(true);
                }
                reject(false);
            });
        });
        req.on('error', () => {
            reject(false);
        });
        if (data) {
            req.write(data);
        }
        req.end();
    });
}
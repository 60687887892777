import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";

import { AvatarUploader, Loader } from "../..";
import styles from "./left-resume.module.scss";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const LeftResume = ({
  user: {
    role: { _id: loggedUserRoleId },
    avatar,
    firstName,
    lastName,
    email,
    position,
  },
  appointmentCounters: { scheduled, pending, userlimitAppointments, limitAppointments },
  onAvatarSelected,
  loading,
}) => {
  const { t } = useTranslation("profile");
  const { roleId } = useUserCatalogs();
  const hideAppointmentsForFreeUser =
    loggedUserRoleId === roleId.FREE_ASSISTANT;

  return (
    <div className={styles["left-resume-wrapper"]}>
      <h4 className="text-left mb-4">{t("leftResume.cardTitle")}</h4>
      {loading ? (
        <Loader />
      ) : (
        <div className="avatar-icon-wrapper avatar-icon-xl">
          <div className="d-block p-0 avatar-icon-wrapper m-0">
            <div className="overflow-hidden">
              <div className="avatar-icon">
                <img
                  data-testid="avatar-img"
                  src={avatar}
                  alt={`${firstName} ${lastName} avatar`}
                  className={styles["avatar-img"]}
                />
              </div>
              <AvatarUploader
                loading={loading}
                onAvatarSelected={onAvatarSelected}
              />
            </div>
          </div>
        </div>
      )}

      <h5 className="font-weight-bold">
        {firstName} {lastName}
      </h5>
      {position && (
        <p data-testid="position-label" className={styles["position-label"]}>
          {position}
        </p>
      )}
      <p className={styles["email-label"]}>{email}</p>

      {!hideAppointmentsForFreeUser && (
        <div data-testid="appointments-info">
          <h5 className="font-weight-bold">
            {t("leftResume.myAppointmentsTitle")}
          </h5>
          <div className="d-flex justify-content-center">
            <div>
              <h2 title="scheduled-counter">{scheduled}</h2>
              <div
                className={`border-success ${styles["severity-appointments-indicator"]}`}
              />
              <p className={styles["appointments-indicator-label"]}>
                {t("leftResume.acceptedAppointmentsLabel")}
              </p>
            </div>

            <div className={styles["severity-separator"]} />

            <div>
              <h2 title="pending-counter">{pending}</h2>
              <div
                className={`border-warning ${styles["severity-appointments-indicator"]}`}
              />
              <p className={styles["appointments-indicator-label"]}>
                {t("leftResume.pendingAppointmentsLabel")}
              </p>
            </div>
          </div>

          {userlimitAppointments !== false && (
            <div className="my-2">
              <i>{t("leftResume.limit1")} {scheduled + pending} {t("leftResume.limit2")} {limitAppointments} {t("leftResume.limit3")}</i>
            </div>
          )}

          <RouterLink to="/my-appointments">
            <p className="text-primary font-weight-bold">
              {t("leftResume.viewAppointmentsLinkLabel")}
            </p>
          </RouterLink>
        </div>
      )}
    </div>
  );
};

export default LeftResume;

import React from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, IconButton, Button, Box } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ModalMenu = ({ Items, buttonText = false, buttonTextStyles = false, buttonIcon: Icon = false,variant=false }) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        {typeof buttonText === "string" ? (
          <Button variant={variant?'outlined':'text'} onClick={handleClick} size="small" style={buttonTextStyles ? buttonTextStyles : {}}>
            {Icon && <Icon className="app-language-icon" />}
            {buttonText}
          </Button>
        ) : (
          <IconButton color="primary" size="small" onClick={handleClick}>
            <FontAwesomeIcon icon={["fas", "ellipsis-h"]} />
          </IconButton>
        )}
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Items.map((item, index) => (
          <MenuItem
            disabled={item.disabled}
            key={index.toString()}
            onClick={() => {
              item.action();
              setAnchorEl(null);
            }}
          >
            {item.name[currentLanguage] || item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
export default ModalMenu;

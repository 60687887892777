import React, { useState, Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Hidden, IconButton, AppBar, Box, Tooltip } from "@material-ui/core";
import MenuOpenRoundedIcon from "@material-ui/icons/MenuOpenRounded";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

import {
  PaymentBanner,
  ModalChildren,
  InformationPayment,
  PremiumServiceOptions,
  SwitchLanguage,
} from "components";
import useWindowSize from "utils/custom-hooks/useWindowSize";

import HeaderLogo from "../../layout-components/HeaderLogo";
import HeaderUserbox from "../../layout-components/HeaderUserbox";

import {
  setSidebarToggle,
  setSidebarToggleMobile,
} from "../../redux/reducers/ThemeOptions";

const { REACT_APP_ROLE_ASISTANT_FREE } = process.env;

const Header = (props) => {
  const { i18n } = useTranslation();
  const windowSize = useWindowSize();
  const currentLanguage = i18n.language;
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const { loggedUser } = useSelector((store) => store.loggedUser);

  const [showPremiumServiceOptions, setShowPremiumServiceOptions] = useState(
    false
  );
  const [showConfirmationPayment, setShowConfirmationPayment] = useState(false);
  const [selectedServicePrices, setSelectedServicePrices] = useState({
    price: 0,
    priceWithIVA: 0,
    priceDescription: "",
  });

  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
  } = props;

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const onServiceSelect = (service) => {
    setShowPremiumServiceOptions(false);
    setShowConfirmationPayment(true);

    const { price, priceWithIVA, priceDescription } = service;
    setSelectedServicePrices({ price, priceWithIVA, priceDescription });
  };

  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx("app-header", {
          "app-header-collapsed-sidebar": props.isCollapsedLayout,
        })}
        position={headerFixed ? "fixed" : "absolute"}
        elevation={headerShadow ? 11 : 3}
      >
        {!props.isCollapsedLayout && <HeaderLogo />}

        <Box className="app-header-toolbar toggle-sidebar-container">
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title="Carolina React Admin Dashboard with Material-UI PRO"
              style={{ display: "none" }}
            >
              <Link to="/dashboard" className="app-logo-link">
                <IconButton
                  color="primary"
                  size="medium"
                  className="app-logo-btn"
                >
                  {/* <img
                    className="app-logo-img"
                    alt="Carolina React Admin Dashboard with Material-UI PRO"
                    src={projectLogo}
                  /> */}
                </IconButton>
              </Link>
              <Hidden smDown>
                <Box className="app-logo-text">Expo Tour Virtual</Box>
              </Hidden>
            </Box>
          </Hidden>

          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx("btn-toggle-collapse", {
                    "btn-toggle-collapse-closed": sidebarToggle,
                  })}
                >
                  <Tooltip title="Toggle Sidebar" placement="right">
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse"
                    >
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </Box>
          </Hidden>
          <img
            alt={eventInfo.title[currentLanguage]}
            src={eventInfo.assets.headerImage}
            style={{
              width: windowSize === "xsm" || windowSize === "sm" ? "125px" : "207px",
              margin: "5px 0",
              objectFit: "contain",
            }}
          />
          <Box className="d-flex align-items-center">
            <SwitchLanguage />
            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium"
                >
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>

      {/* Payment banner only for free users */}
      {loggedUser.role._id === REACT_APP_ROLE_ASISTANT_FREE && (
        <PaymentBanner
          eventInfo={eventInfo}
          setShowConfirmationPayment={setShowPremiumServiceOptions}
        />
      )}

      <ModalChildren
        showDialog={showPremiumServiceOptions}
        toggleDialog={() =>
          setShowPremiumServiceOptions(!showPremiumServiceOptions)
        }
      >
        <PremiumServiceOptions
          eventInfo={eventInfo}
          onServiceSelect={onServiceSelect}
        />
      </ModalChildren>

      <ModalChildren
        showDialog={showConfirmationPayment}
        toggleDialog={() =>
          setShowConfirmationPayment(!showConfirmationPayment)
        }
      >
        <InformationPayment
          selectedServicePrices={selectedServicePrices}
          eventInfo={eventInfo}
        />
      </ModalChildren>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggle: (enable) => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import {
  CATALOGUES,
  CATALOGUES_FAILED,
  CATALOGUES_SUCCESS,
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  success: false,
  catalogues: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- GET CATALOGUES -----
    case CATALOGUES:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case CATALOGUES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case CATALOGUES_SUCCESS:
      return {
        ...state,
        loading: false,
        catalogues: {
          ...state.catalogues,
          ...action.data,
        },
      };
    default:
      return state;
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Card, Button, TextField, Container } from "@material-ui/core";

import { changePassUser } from "redux/actions";
import { Alert, Loader, NoSessionNavigation } from "components";
import { required, minLength, confirmSameValue } from "utils/validations";

import styles from "./change-password.module.scss";

export default function ChangePassword({ history, location }) {
  const { t } = useTranslation("change-password");
  const dispatch = useDispatch();

  const { eventInfo } = useSelector((store) => store.eventInfo);
  const noSessionSiteBgUrl = eventInfo.assets.noSessionSiteBg;

  const { loading, error, changePassSuccess } = useSelector(
    (store) => store.register
  );
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const { password, confirmPassword } = data;

  useEffect(() => {
    if (changePassSuccess) {
      setData({ ...data, password: "", confirmPassword: "" });
    }
  }, [changePassSuccess]);

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const changePassword = () => {
    const token = new URLSearchParams(location.search).get("token");
    dispatch(changePassUser(token, data));
  };

  const goLogin = () => {
    history.push({
      pathname: "/login",
    });
  };

  return (
    <Grid
      container
      spacing={0}
      className="d-flex flex-column justify-content-between min-vh-100 nosession-site-bg-image"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${noSessionSiteBgUrl})`,
      }}
    >
      <div style={{ height: 42 }}>
        <NoSessionNavigation goBack={goLogin} containerStyle={{ flex: 1 }} />
      </div>

      <Grid className={styles["main-content"]}>
        <Container maxWidth="sm">
          <h1 className="display-2 mb-3 px-4 font-weight-bold text-center">
            {t("pageTitle")}
          </h1>
          <h3 className="font-size-lg line-height-sm font-weight-bold d-block px-3 mb-5 text-center">
          {t("description")}
          </h3>

          <Card className="p-5 mx-1 text-center">
            <div className="mb-3">
              <TextField
                disabled={changePassSuccess}
                value={password}
                error={
                  password.length > 0 &&
                  (!required(password) || !minLength(8)(password))
                }
                helperText={
                  password.length > 0
                    ? !required(password) || !minLength(8)(password)
                      ? t("form.passwordNotValid")
                      : null
                    : null
                }
                name="password"
                label={t("form.password")}
                fullWidth
                placeholder={t("form.passwordPlaceholder")}
                type="password"
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <TextField
                disabled={changePassSuccess}
                value={confirmPassword}
                error={
                  confirmPassword.length > 0 &&
                  (!required(confirmPassword) ||
                    !minLength(8)(confirmPassword) ||
                    !confirmSameValue(password, confirmPassword))
                }
                helperText={
                  confirmPassword.length > 0
                    ? !required(confirmPassword) ||
                      !minLength(8)(confirmPassword) ||
                      !confirmSameValue(password, confirmPassword)
                      ? t("form.passwordNotValid")
                      : null
                    : null
                }
                name="confirmPassword"
                label={t("form.confirmPassword")}
                fullWidth
                placeholder={t("form.confirmPasswordPlaceholder")}
                type="password"
                autoComplete="off"
                onChange={handleInputChange}
              />
            </div>
            {changePassSuccess ? (
              <div className="mt-4">
                <Alert
                  severity="success"
                  message={t("form.success")}
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#010090" }}
                  className="mt-4 text-white"
                  size="large"
                  onClick={goLogin}
                >
                  <span className="btn-wrapper--label">{t("form.login")}</span>
                </Button>
              </div>
            ) : loading ? (
              <Loader />
            ) : (
              <Button
                disabled={
                  !required(data.password) ||
                  !minLength(8)(data.password) ||
                  !required(data.confirmPassword) ||
                  !minLength(8)(data.confirmPassword) ||
                  !confirmSameValue(data.password, data.confirmPassword)
                }
                style={{ backgroundColor: "#010090" }}
                variant="contained"
                className="mt-4 text-white"
                size="large"
                onClick={changePassword}
              >
                <span className="btn-wrapper--label">{t("form.confirm")}</span>
              </Button>
            )}
          </Card>

          {error ? (
            <div className="mt-4">
              <Alert severity="error" message={error} />
            </div>
          ) : null}

        </Container>
      </Grid>
    </Grid>
  );
}

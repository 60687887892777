import React from "react";

const useUserCatalogs = () => {
  const [companySizes] = React.useState([
    {
      value: "Microempresas (1 - 10 trabajadores)",
      label: {
        es: "Microempresas (1 - 10 trabajadores)",
        en: "Micro (1 - 10 employees)",
      },
    },
    {
      value: "Pequeñas Empresas (11 - 50 trabajadores)",
      label: {
        es: "Pequeñas Empresas (11 - 50 trabajadores)",
        en: "Small (11 - 50 employees)",
      },
    },
    {
      value: "Medianas Empresas (51 - 250 trabajadores)",
      label: {
        es: "Medianas Empresas (51 - 250 trabajadores)",
        en: "Medium (51 - 250 employees)",
      },
    },
    {
      value: "Grandes Empresas (251 trabajadores o más)",
      label: {
        es: "Grandes Empresas (251 trabajadores o más)",
        en: "Large (251 employees or more)",
      },
    },
  ]);
  const [informationTechnologies, setInformationTechnologies] = React.useState([
    {
      value: "Desarrollo de Software",
      label: { es: "Desarrollo de Software", en: "Software Development" },
      checked: false,
    },
    {
      value: "Equipo / Hardware (Distribución y venta)",
      label: {
        es: "Equipo / Hardware (Distribución y venta)",
        en: "Equipment / Hardware *** ",
      },
      checked: false,
    },
    {
      value: "Software (Distribución y venta)",
      label: {
        es: "Software (Distribución y venta)",
        en: "Software ***",
      },
      checked: false,
    },
    {
      value: "Analítica de Datos",
      label: { es: "Analítica de Datos", en: "Analytics of Data" },
      checked: false,
    },
    {
      value: "Gobierno de Información",
      label: { es: "Gobierno de Información", en: "Government of Information" },
      checked: false,
    },
    {
      value: "Automatización de Procesos y Flujos de Trabajo",
      label: {
        es: "Automatización de Procesos y Flujos de Trabajo",
        en: "Automation of Processes and Workflows",
      },
      checked: false,
    },
    {
      value: "Servicios Administrados ",
      label: { es: "Servicios Administrados ", en: "Managed Services" },
      checked: false,
    },
    {
      value: "Bases de Datos",
      label: { es: "Bases de Datos", en: "Databases" },
      checked: false,
    },
    {
      value: "Inteligencia Artificial",
      label: { es: "Inteligencia Artificial", en: "Artificial Intelligence" },
      checked: false,
    },
    {
      value: "Plataformas de Movilidad",
      label: { es: "Plataformas de Movilidad", en: "Mobility Platforms" },
      checked: false,
    },
    {
      value: "Procesamiento de Transacciones",
      label: {
        es: "Procesamiento de Transacciones",
        en: "Transactions Processing",
      },
      checked: false,
    },
    {
      value: "Gestión de Proyectos (Servicio)",
      label: {
        es: "Gestión de Proyectos (Servicio)",
        en: "Project Management (Service)",
      },
      checked: false,
    },
    {
      value: "Internet of Things",
      label: { es: "Internet of Things", en: "Internet of Things" },
      checked: false,
    },
    {
      value: "Infraestructura (Software / Hardware)",
      label: {
        es: "Infraestructura (Software / Hardware)",
        en: "Infrastructure (Software / Hardware)",
      },
      checked: false,
    },
    {
      value: "Seguridad de TI",
      label: { es: "Seguridad de TI", en: "IT Security" },
      checked: false,
    },
    {
      value: "Capacitación (Servicio)",
      label: { es: "Capacitación (Servicio)", en: "Training ***" },
      checked: false,
    },
    {
      value: "Gestión de Contenido",
      label: { es: "Gestión de Contenido", en: "Content Management" },
      checked: false,
    },
    {
      value: "Productividad de Oficina",
      label: { es: "Productividad de Oficina", en: "Office Productivity" },
      checked: false,
    },
    {
      value: "Soluciones de Nube",
      label: { es: "Soluciones de Nube", en: "Cloud Solutions" },
      checked: false,
    },
  ]);
  const [industries4, setIndustries4] = React.useState([
    {
      value: "Blockchain",
      label: { es: "Blockchain", en: "Blockchain" },
      checked: false,
    },
    {
      value: "Nube",
      label: { es: "Nube", en: "Cloud" },
      checked: false,
    },
    {
      value: "Robótica",
      label: { es: "Robótica", en: "Robotics" },
      checked: false,
    },
    {
      value: "Simulaciones",
      label: { es: "Simulaciones", en: "Simulations" },
      checked: false,
    },
    {
      value: "Materiales Avanzados",
      label: { es: "Materiales Avanzados", en: "Advanced Materials" },
      checked: false,
    },
    {
      value: "Realidad Virtual / Aumentada",
      label: {
        es: "Realidad Virtual / Aumentada",
        en: "Virtual Reality / Augmented",
      },
      checked: false,
    },
    {
      value: "Manufactura aditiva",
      label: { es: "Manufactura aditiva", en: "Additive Manufacture" },
      checked: false,
    },
    {
      value: "Internet de las cosas IoT",
      label: { es: "Internet de las cosas IoT", en: "Internet of Things IoT" },
      checked: false,
    },
    {
      value: "Big Data",
      label: { es: "Big Data", en: "Big Data" },
      checked: false,
    },
    {
      value: "Ciberseguridad",
      label: { es: "Ciberseguridad", en: "Cybersecurity" },
      checked: false,
    },
    {
      value: "Desarrollo de Software",
      label: { es: "Desarrollo de Software", en: "Software Development" },
      checked: false,
    },
    {
      value: "Inteligencia Artificial",
      label: { es: "Inteligencia Artificial", en: "Artificial Intelligence" },
      checked: false,
    },
  ]);
  const [telecommunications, setTelecommunications] = React.useState([
    {
      value: "Almacenamiento",
      label: { es: "Almacenamiento", en: "Storage" },
      checked: false,
    },
    {
      value: "Redes y Comunicaciones",
      label: { es: "Redes y Comunicaciones", en: "Communication & Networking" },
      checked: false,
    },
    {
      value: "Infraestructura Pasiva de Telecomunicaciones",
      label: {
        es: "Infraestructura Pasiva de Telecomunicaciones",
        en: "Passive Telecommunications Infrastructure",
      },
      checked: false,
    },
    {
      value: "Seguridad de datos",
      label: { es: "Seguridad de datos", en: "Data Security" },
      checked: false,
    },
    {
      value: "Consultoría de soluciones de telecom",
      label: {
        es: "Consultoría de soluciones de telecom",
        en: "Telecom Solutions Consulting",
      },
      checked: false,
    },
    {
      value: "Servicios de colaboración",
      label: { es: "Servicios de colaboración", en: "Collaboration Services" },
      checked: false,
    },
    {
      value: "Ofrecen PAAS, IAAS (Distribución y venta)",
      label: {
        es: "Ofrecen PAAS, IAAS (Distribución y venta)",
        en: "Offer paces, IaaS ***",
      },
      checked: false,
    },
    {
      value: "Mobilidad (Wireless)",
      label: { es: "Mobilidad (Wireless)", en: "Mobility (Wireless)" },
      checked: false,
    },
    {
      value: "Lineas de teléfono",
      label: { es: "Lineas de teléfono", en: "Telephone Lines" },
      checked: false,
    },
    {
      value: "Servicios de Internet",
      label: { es: "Servicios de Internet", en: "Internet Services" },
      checked: false,
    },
    {
      value: "Plataformas de Movilidad",
      label: { es: "Plataformas de Movilidad", en: "Mobility Platforms" },
      checked: false,
    },
    {
      value: "Procesamiento de Transacciones",
      label: {
        es: "Procesamiento de Transacciones",
        en: "Transactions Processing",
      },
      checked: false,
    },
    {
      value: "Gestión de Proyectos (Servicio)",
      label: {
        es: "Gestión de Proyectos (Servicio)",
        en: "Projects Management ***",
      },
      checked: false,
    },
    {
      value: "Internet of Things",
      label: { es: "Internet of Things", en: "Internet of Things" },
      checked: false,
    },
    {
      value: "Infraestructura (Software / Hardware)",
      label: {
        es: "Infraestructura (Software / Hardware)",
        en: "Infrastructure (Software / Hardware)",
      },
      checked: false,
    },
    {
      value: "Seguridad de TI",
      label: { es: "Seguridad de TI", en: "IT Security" },
      checked: false,
    },
    {
      value: "Capacitación (Servicio)",
      label: { es: "Capacitación (Servicio)", en: "Training (Service) ***" },
      checked: false,
    },
    {
      value: "Gestión de Contenido",
      label: { es: "Gestión de Contenido", en: "Content Management" },
      checked: false,
    },
    {
      value: "Productividad de Oficina",
      label: { es: "Productividad de Oficina", en: "Office Productivity" },
      checked: false,
    },
    {
      value: "Soluciones de Nube",
      label: { es: "Soluciones de Nube", en: "Cloud Solutions" },
      checked: false,
    },
  ]);
  const [interactiveMedias, setInteractiveMedias] = React.useState([
    {
      value: "Cine / Producción de video",
      label: {
        es: "Cine / Producción de video",
        en: "Cinema / Video Production",
      },
      checked: false,
    },
    {
      value: "Marketing Digital",
      label: { es: "Marketing Digital", en: "Digital Marketing" },
      checked: false,
    },
    {
      value: "Interactivos / Realidad mixta",
      label: {
        es: "Interactivos / Realidad mixta",
        en: "Interactive / Mixed Reality",
      },
      checked: false,
    },
    {
      value: "Audio",
      label: { es: "Audio", en: "Audio" },
      checked: false,
    },
    {
      value: "Animación 2D/3D",
      label: { es: "Animación 2D/3D", en: "2D / 3D Animation" },
      checked: false,
    },
    {
      value: "E-Learning",
      label: { es: "E-Learning", en: "E-Learning" },
      checked: false,
    },
    {
      value: "Simulación",
      label: { es: "Simulación", en: "Simulation" },
      checked: false,
    },
    {
      value: "Videojuegos",
      label: { es: "Videojuegos", en: "Video Game" },
      checked: false,
    },
  ]);
  const [sectors, setSectors] = React.useState([
    {
      value: "Telecomunicaciones",
      label: { es: "Telecomunicaciones", en: "Telecommunications" },
      checked: false,
    },
    {
      value: "Electrónica",
      label: { es: "Electrónica", en: "Electronics" },
      checked: false,
    },
    {
      value: "Medios Interactivos",
      label: { es: "Medios Interactivos", en: "Interactive Media" },
      checked: false,
    },
    {
      value: "Tecnologías de Información",
      label: {
        es: "Tecnologías de Información",
        en: "Information Technologies",
      },
      checked: false,
    },
    {
      value: "Servicios Generales de Apoyo",
      label: {
        es: "Servicios Generales de Apoyo",
        en: "General Support Services",
      },
      checked: false,
    },
  ]);
  const [personTypes] = React.useState([
    {
      value: "Persona Física",
      label: { es: "Persona Física", en: "Company" },
    },
    {
      value: "Persona Moral",
      label: { es: "Persona Moral", en: "Individual" },
    },
  ]);

  const [providerTypes] = React.useState([
    {
      value: { en: "IT Services", es: "Servicios de TI" },
      label: { en: "IT Services", es: "Servicios de TI" },
    },
    {
      value: {
        en: "General Support Services",
        es: "Servicios Generales de Apoyo",
      },
      label: {
        en: "General Support Services",
        es: "Servicios Generales de Apoyo",
      },
    },
  ]);

  const [companyTypes, setCompanyTypes] = React.useState([
    {
      value: "Fabricante",
      label: { es: "Fabricante", en: "Manufacturer" },
      checked: false,
    },
    {
      value: "Distribuidor",
      label: { es: "Distribuidor", en: "Distributor" },
      checked: false,
    },
    {
      value: "Mayorista",
      label: { es: "Mayorista", en: "Wholesaler" },
      checked: false,
    },
    {
      value: "Integrador de soluciones",
      label: { es: "Integrador de soluciones", en: "Integrator of Solutions" },
      checked: false,
    },
  ]);

  const [attendedMarkets, setAttendedMarkets] = React.useState([
    {
      value: "Norte América (México, EUA y/o Canadá)",
      label: {
        es: "Norte América (México, EUA y/o Canadá)",
        en: "North America",
      },
      checked: false,
    },
    {
      value:
        "Centro América (Belice, Costa Rica, El Salvador, Guatemala, Honduras, Nicaragua, Panamá)",
      label: {
        es:
          "Centro América (Belice, Costa Rica, El Salvador, Guatemala, Honduras, Nicaragua, Panamá)",
        en: "Central America",
      },
      checked: false,
    },
    {
      value:
        "Sudamérica (Argentina, Bolivia, Brasil, Chile, Colombia, Ecuador, Guayana, Paraguay, Perú, Surinam, Uruguay, Venezuela)",
      label: {
        es:
          "Sudamérica (Argentina, Bolivia, Brasil, Chile, Colombia, Ecuador, Guayana, Paraguay, Perú, Surinam, Uruguay, Venezuela)",
        en: "South America",
      },
      checked: false,
    },
    {
      value: "Europa",
      label: { es: "Europa", en: "Europe" },
      checked: false,
    },
    {
      value: "Asia",
      label: { es: "Asia", en: "Asia" },
      checked: false,
    },
    {
      value: "África",
      label: { es: "África", en: "Africa" },
      checked: false,
    },
    {
      value: "Oceanía",
      label: { es: "Oceanía", en: "Oceania" },
      checked: false,
    },
  ]);

  const [certificationOptions, setCertificationOptions] = React.useState([
    {
      value: "ISO 9001",
      label: "ISO 9001",
      checked: false,
    },
    {
      value: "ISO 50001",
      label: "ISO 50001",
      checked: false,
    },
    {
      value: "ISO 27001",
      label: "ISO 27001",
      checked: false,
    },
    {
      value: "ISO 45001",
      label: "ISO 45001",
      checked: false,
    },
    {
      value: "SCRUM",
      label: "SCRUM",
      checked: false,
    },
    {
      value: "ITIL",
      label: "ITIL",
      checked: false,
    },
    {
      value: "PMI",
      label: "PMI",
      checked: false,
    },
  ]);

  const [belongingClusters, setBelongingClusters] = React.useState([
    {
      value: "CANIETI",
      label: "CANIETI",
      checked: false,
    },
    {
      value: "Csoft",
      label: "Csoft",
      checked: false,
    },
    {
      value: "MIMEC",
      label: "MIMEC",
      checked: false,
    },
    {
      value: "NL4.0",
      label: "NL4.0",
      checked: false,
    },
    {
      value: "Monterrey IT Cluster",
      label: "Monterrey IT Cluster",
      checked: false,
    },
    {
      value: "ANADIC",
      label: "ANADIC",
      checked: false,
    },
    {
      value: "CAAAREM",
      label: "CAAAREM",
      checked: false,
    },
    {
      value: "CAINTRA",
      label: "CAINTRA",
      checked: false,
    },
    {
      value: "CANACO",
      label: "CANACO",
      checked: false,
    },
    {
      value: "CANACINTRA",
      label: "CANACINTRA",
      checked: false,
    },
    {
      value: "CANAME",
      label: "CCIJ",
      checked: false,
    },
  ]);

  /**
   * ENUM for the different screen components
   * Check 'src/User/UserResponseObjects.ts' file on Backend APP
   */
  const [screenEnums] = React.useState({
    FULL_USER: "FULL_USER",

    /** Buyer */
    LIMITED_BUYER_NO_APPOINTMENT: "LIMITED_BUYER_NO_APPOINTMENT",
    LIMITED_BUYER_APPOINTMENT_REQUESTED: "LIMITED_BUYER_APPOINTMENT_REQUESTED",
    FULL_BUYER_APPOINTMENT_ACCEPTED: "FULL_BUYER_APPOINTMENT_ACCEPTED",
    LIMITED_BUYER_APPOINTMENT_REJECTED: "LIMITED_BUYER_APPOINTMENT_REJECTED",
    LIMITED_BUYER_APPOINTMENT_CANCELED: "LIMITED_BUYER_APPOINTMENT_CANCELED",

    /** Seller */
    FULL_SELLER_NO_APPOINTMENT: "FULL_SELLER_NO_APPOINTMENT",
    FULL_SELLER_APPOINTMENT_ACCEPTED: "FULL_SELLER_APPOINTMENT_ACCEPTED",
    FULL_SELLER_APPOINTMENT_REQUESTED: "FULL_SELLER_APPOINTMENT_REQUESTED",
    FULL_SELLER_APPOINTMENT_REJECTED: "FULL_SELLER_APPOINTMENT_REJECTED",
    FULL_SELLER_APPOINTMENT_CANCELED: "FULL_SELLER_APPOINTMENT_CANCELED",
  });

  /**
   * ENUM for the different role codes to show data dinamically
   */
  const [roleId] = React.useState({
    GOD: "6050ddfd1384c42534f8eb74",
    ADMIN: "5f56beed42a3ec3f2b61923f",
    FREE_ASSISTANT: "5f56bf1942a3ec3f2b619243",
    PREMIUM_ASSISTANT: "5f56bf1942a3ec3f2b619244",
    BUYER: "5f56bffa01a3ec3f2b16dcba",
  });

  /**
   * Appointment time slots for table filter
   */
  const [appointmentTimeSlots] = React.useState([
    { timeSlot: "09:00" },
    { timeSlot: "09:30" },
    { timeSlot: "10:00" },
    { timeSlot: "10:30" },
    { timeSlot: "11:00" },
    { timeSlot: "11:30" },
    { timeSlot: "12:00" },
    { timeSlot: "12:30" },
    { timeSlot: "13:00" },
    { timeSlot: "13:30" },
    { timeSlot: "14:00" },
    { timeSlot: "14:30" },
    { timeSlot: "15:00" },
    { timeSlot: "15:30" },
    { timeSlot: "16:00" },
    { timeSlot: "16:30" },
  ]);

  /**
   * ENUM for the different role codes to show data dinamically
   */
  const serviceId = {
    FREE_ASSISTANT: "5f56bf1942a3ec3f2b619243",
    INDUSTRIAL_ASSISTANT: "5c12cda91a93ec3f2b619345",
    PREMIUM_ASSISTANT: "5f56ba89a1a3ec3f2b6192a0",
    BUYER: "606b54e12762f716167d8fe3",
  };
  const serviceCatalog = {
    FREE_ASSISTANT: {
      es: {
        id: "5f56bf1942a3ec3f2b619243",
        name: "Proveedor Gratuito",
      },
      en: {
        id: "5f56bf1942a3ec3f2b619243",
        name: "Free Provider",
      },
    },
    PREMIUM_ASSISTANT: {
      es: {
        id: "5f56ba89a1a3ec3f2b6192a0",
        name: "Proveedor Premium",
      },
      en: {
        id: "5f56ba89a1a3ec3f2b6192a0",
        name: "Premium Provider",
      },
    },
    INDUSTRIAL_ASSISTANT: {
      es: {
        id: "5c12cda91a93ec3f2b619345",
        name: "Proveedor Gratuito Industrial",
      },
      en: {
        id: "5c12cda91a93ec3f2b619345",
        name: "Industrial Free Provider",
      },
    },
    BUYER: {
      es: {
        id: "5f56bffa01a3ec3f2b16dcba",
        name: "Comprador",
      },
      en: {
        id: "5f56bffa01a3ec3f2b16dcba",
        name: "Buyer",
      },
    },
  };

  return {
    companySizes: { array: companySizes },
    personTypes: { array: personTypes },
    companyTypes: { array: companyTypes, setter: setCompanyTypes },
    attendedMarkets: { array: attendedMarkets, setter: setAttendedMarkets },
    certificationOptions: {
      array: certificationOptions,
      setter: setCertificationOptions,
    },
    sectors: { array: sectors, setter: setSectors },
    screenEnums,
    roleId,
    appointmentTimeSlots,
    serviceId,
    serviceCatalog,
    industries4: { array: industries4, setter: setIndustries4 },
    telecommunications: {
      array: telecommunications,
      setter: setTelecommunications,
    },
    interactiveMedias: {
      array: interactiveMedias,
      setter: setInteractiveMedias,
    },
    belongingClusters: {
      array: belongingClusters,
      setter: setBelongingClusters,
    },
    informationTechnologies: {
      array: informationTechnologies,
      setter: setInformationTechnologies,
    },
    providerTypes: { array: providerTypes },
  };
};

export default useUserCatalogs;

import React from "react";
import { useSelector } from "react-redux";

import SidebarMenu from "../../layout-components/SidebarMenu";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";
import validateUserResources from "utils/Routes/validate-user-resources";

const NavItems = () => {
  const {
    loggedUser: { role },
  } = useSelector((store) => store.loggedUser);
  const { roleId } = useUserCatalogs();

  const { validSidebarModules } = validateUserResources(role, roleId);

  return (
    <>
      {validSidebarModules.map((list, index) => (
        <SidebarMenu
          component="div"
          key={list.label + index}
          pages={list.content}
          title={list.label}
        />
      ))}
    </>
  );
};

export default NavItems;

import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { AnimatePresence } from "framer-motion";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";

import { LeftSidebar } from "../../layout-blueprints";
import MuiTheme from "../../theme";

import useUserCatalogs from "../catalogs/useUserCatalogs";
import validateUserResources from "./validate-user-resources";

const SessionRoutes = () => {
  const location = useLocation();
  const {
    loggedUser: { role },
  } = useSelector((store) => store.loggedUser);
  const { roleId } = useUserCatalogs();

  const { validSessionRoutes } = validateUserResources(role, roleId);
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <LeftSidebar>
          <Switch location={location} key={location.pathname}>
            {validSessionRoutes.map(({ to, component }, index) => (
              <Route key={index} exact path={to} component={component} />
            ))}
            <Route render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </LeftSidebar>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default SessionRoutes;

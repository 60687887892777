import axios from "config/axios";

const { REACT_APP_API_URL } = process.env;

const getCompany = (companyid) => {
  return axios.get(`${REACT_APP_API_URL}/company/${companyid}`);
};

const getCompanyForm = () => {
  return axios.get(`${REACT_APP_API_URL}/companyform`);
};

export { getCompany, getCompanyForm };

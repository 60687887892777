import { LOGIN, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT, RESET } from "../actions";

const initialState = {
  loggedUser: {
    user: {
      _id: "",
    },
  },
  loading: true,
  error: false,
  loginSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true,
        error: false,
        loginSuccess: false,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
        loginSuccess: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        loggedUser: action.data,
        loginSuccess: true,
      };
    case LOGOUT:
      return {
        ...state,
        loggedUser: null,
        loginSuccess: false,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
      };
   
    default:
      return state;
  }
}

import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import formatNumberPrice from "../../utils/format-number-price";
import styles from "./ChooseServiceCard.module.scss";
import ChooseServiceCardBenefitsList from "./ChooseServiceCardBenefitsList";
import useUserCatalogs from "../../utils/catalogs/useUserCatalogs";


const ChooseServiceCard = ({ service, onServiceSelect, modalDashboard }) => {
  const { serviceId } = useUserCatalogs();
  const { t, i18n } = useTranslation("choose-service-card");
  const [priceLabel,setPriceLabel] = React.useState({
    en:'MX (VAT included)',
    es:'MX (IVA incluido)'
  })
  const currentLanguage = i18n.language;

  const { benefits, cardTitle, title, color, notes,price,priceDescription,_id } = service;
  const displayFreeServiceField = serviceId.FREE_ASSISTANT === _id;

  

  const serviceSelect = () => {
    onServiceSelect(service);
  };
  const renderNotes = () => {
    if (notes[currentLanguage] && notes[currentLanguage].length) {
      return (
        <div
          className="text-center my-2 font-weight-bold"
          style={{ fontSize: "14px" }}
        >
          <p>{t("importantText")}</p>
          {notes[currentLanguage].map((note, index) => (
            <p key={index}>{note}</p>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="d-flex justify-content-center">
      <div className={styles["card-container"]}>
        <div
          className={styles["card-color-line"]}
          style={{ backgroundColor: color }}
        />
        <div className={styles["card-text-content"]}>
          <h2 className="text-center font-weight-bold">
            {cardTitle[currentLanguage]}
          </h2>
          <p className={styles["card-description-label"]}>
            {title[currentLanguage]}
          </p>
          <p className={styles["card-price-label"]} style={{ color,fontSize:'30px' }}>
            {displayFreeServiceField?null:
            `${t('price')} ${formatNumberPrice(price)} ${priceLabel[currentLanguage]}`
}
          </p>
        
          <b style={{ fontSize: 14 }}>{t("benefits")}</b>
          <ChooseServiceCardBenefitsList
            benefits={benefits[currentLanguage]}
            actionRegister={serviceSelect}
            color={color}
          />

          {renderNotes()}

          <Button
            size="large"
            style={{
              backgroundColor: color,
              color: "white",
              fontWeight: "bold",
              marginTop: "auto",
              marginBottom: 10,
              width: "56%",
            }}
            onClick={serviceSelect}
          >
            {modalDashboard ? t("titleButtonDashboard") : t("titleButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChooseServiceCard;

import React from "react";
import { useTranslation } from "react-i18next";

import stylesImport from "./import-user.module.scss";

const ImportUsers = (props) => {
  const { i18n } = useTranslation("users");
  const currentLanguage = i18n.language;

  const { title, description, children } = props;

  const getDownloadTemplateText = () => {
    switch (currentLanguage) {
      case "es":
        return (
          <p>
            Descargue esta
            <a
              download="plantilla.csv"
              //this line contains the template file in base64 format
              href="data:application/vnd.ms-excel;base64,77u/Tm9tYnJlcyoobWluIDMgbWF4IDI1IGNhcmFjdGVyZXMpLEFwZWxsaWRvcyoobWluIDMgbWF4IDI1IGNhcmFjdGVyZXMpLENvcnJlbyBFbGVjdHJvbmljbyoobWluIDggbWF4IDQwIGNhcmFjdGVyZXMpLENvbmZpcm1hciBjb3JyZW8gZWxlY3Ryb25pY28qKG1pbiA4IG1heCA0MCBjYXJhY3RlcmVzKSxDb2RpZ28gZGUgcGFpcyoobWluIDEgbWF4IDQgY2FyYWN0ZXJlcyksVGVsZWZvbm8gQ2VsdWxhcioobWluIDggbWF4IDEyIGNhcmFjdGVyZXMpLENvbnRyYXNlw7FhKihtaW4gOCBtYXggNDAgY2FyYWN0ZXJlcyksRW1wcmVzYSxTaXRpbyBXZWIsRXN0YWRvDQo="
            >
              {" "}
              plantilla{" "}
            </a>
            y llene la información de los usuarios, a continuación suba el
            archivo actualizado.
          </p>
        );
      case "en":
        return (
          <p>
            Download this
            <a
              download="plantilla.csv"
              //this line contains the template file in base64 format
              href="data:application/vnd.ms-excel;base64,77u/Tm9tYnJlcyoobWluIDMgbWF4IDI1IGNhcmFjdGVyZXMpLEFwZWxsaWRvcyoobWluIDMgbWF4IDI1IGNhcmFjdGVyZXMpLENvcnJlbyBFbGVjdHJvbmljbyoobWluIDggbWF4IDQwIGNhcmFjdGVyZXMpLENvbmZpcm1hciBjb3JyZW8gZWxlY3Ryb25pY28qKG1pbiA4IG1heCA0MCBjYXJhY3RlcmVzKSxDb2RpZ28gZGUgcGFpcyoobWluIDEgbWF4IDQgY2FyYWN0ZXJlcyksVGVsZWZvbm8gQ2VsdWxhcioobWluIDggbWF4IDEyIGNhcmFjdGVyZXMpLENvbnRyYXNlw7FhKihtaW4gOCBtYXggNDAgY2FyYWN0ZXJlcyksRW1wcmVzYSxTaXRpbyBXZWIsRXN0YWRvDQo="
            >
              {" "}
              template{" "}
            </a>
            and fill in the user information, then upload the updated file.
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <div className={stylesImport["import-user-container"]}>
      <p>{title}</p>
      <p>{description}</p>
      {getDownloadTemplateText()}
      {children}
    </div>
  );
};
export default ImportUsers;

import React, { Fragment, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Card, Grid, Dialog, Button } from "@material-ui/core";

import { Alert, Loader, PageTitle } from "components";
import LeftResume from "./left-resume";
import { uploadCompanyAvatar } from "../../../redux/actions";
import AddBuyer from "./AddBuyer";
import convertToBase64 from "../../../utils/convertToBase64";
import { getCompany as getCompanyService } from "services/CompanyService";
import { getBuyersFromCompany } from "services/UserService";
import defaultCompany from "../../../assets/images/avatars/logo_empresa.png";
import profileStyles from "../../profile/profile.module.scss";
import styles from "./company-details.module.scss";
import ModalMenu from "components/ModalMenu";
import DataTable from "components/DataTable";
import { parseDate } from "utils/helpers/date";

const CompanyDetail = ({ history, location }) => {
  const { t } = useTranslation("company-details");
  const dispatch = useDispatch();

  const { loading } = useSelector((store) => store.company);

  const [buyersLoading, setBuyersLoading] = useState(false);
  const [totalBuyerRows, setTotalBuyerRows] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(undefined);
  const [buyers, setBuyers] = useState([]);
  // const [refreshTable, setRefreshTable] = useState(1); TODO: Is this gonna be used?
  const [company, setCompany] = useState({
    _id: "",
    companyName: "",
    avatar: "",
    industryName: "",
    companyWeb: "",
    rfc: "",
    appointments: {
      accepted: 0,
      pending: 0,
    },
  });
  const { CompanyId } = useParams();
  const tableColumns = [
    {
      name: { es: "NOMBRE", en: "NAME" },
      selector: "firstName",
      sortable: true,
    },
    {
      name: { es: "APELLIDOS", en: "LAST NAME" },
      selector: "lastName",
      sortable: true,
    },
    {
      name: { es: "CORREO ELECTRÓNICO", en: "EMAIL" },
      selector: "email",
      sortable: true,
      width: "auto",
    },
    {
      name: { es: "FECHA REGISTRO", en: "REGISTRATION DATE" },
      selector: "createdAt",
      sortable: true,
      cell: ({ createdAt }) => parseDate(createdAt),
      width: "auto",
    },
    {
      name: { es: "ACCIONES", en: "ACTIONS" },
      cell: (row) => {
        const actionItem = () => {
          history.push(`/public-profile/${row._id}`);
        };
        const itemsMenu = [
          { name: { es: "Detalles", en: "Details" }, action: actionItem },
        ];
        return <ModalMenu Items={itemsMenu} />;
      },
      grow: 0,
    },
  ];

  const getCompany = useCallback(async () => {
    const response = await getCompanyService(CompanyId);
    if (response.status === 200) {
      const companyResponse = response.data.data;
      let parsedCompanyData = {
        _id: companyResponse._id || "",
        companyWeb: companyResponse.companyWeb || "Sin especificar",
        companyName: companyResponse.comercialName || "Sin especificar",
        avatar: companyResponse.logo || "",
        businessName: companyResponse.businessName || "Sin especificar",
        rfc: companyResponse.rfc || "Sin especificar",
        appointments: companyResponse.appointments,
      };
      setCompany(parsedCompanyData);
      // Get customers
      // setBuyers(companyResponse.customers);
    } else {
      setError(response.data);
    }
  }, [CompanyId]);

  const handleFilterChange = useCallback(
    async (pagination, sorting, searchFilter, selectsFilters) => {
      setBuyersLoading(true);
      try {
        const res = await getBuyersFromCompany(
          pagination,
          sorting,
          searchFilter,
          selectsFilters,
          CompanyId
        );
        if (res.status === 200) {
          const { users, pagination } = res.data.data;
          setTotalBuyerRows(pagination.totalItems);
          setBuyers(users);
        } else {
          setError("Error interno de servidor");
        }
      } catch (error) {
        console.error(error);
        setError("Error interno de servidor");
      } finally {
        setBuyersLoading(false);
      }
    },
    [CompanyId]
  );

  useEffect(() => {
    getCompany();
    handleFilterChange();
  }, [getCompany, handleFilterChange]);

  const handleAddUserClick = () => setOpen(true);
  const onClose = () => setOpen(false);

  const onSuccess = () => {
    onClose();
    // TODO: refresh buyers will not work on this module implmementation.
    // Internal Add-EditBuyer modules are the ones responsible for refreshing
  };

  const onAvatarSelected = async (e) => {
    const selectedFile = e.target.files[0];
    let base64Avatar = undefined;
    try {
      base64Avatar = await convertToBase64(selectedFile);
      dispatch(uploadCompanyAvatar(company._id, base64Avatar));
      setCompany({ ...company, avatar: base64Avatar });
    } catch (error) {
      throw new Error(error);
    }
  };

  if (!company) {
    return <Loader />;
  }

  return (
    <Fragment>
      <PageTitle
        titleHeading={t("pageTitle")}
        showPageTitleIconBox={false}
        hasGoBackButton
        history={history}
      />

      {error && <Alert severity="error" message={error} />}

      <Card className={profileStyles["new-profile-wrapper"]}>
        <div className={profileStyles["resume-form-container"]}>
          <LeftResume
            company={{
              ...company,
              avatar: company.avatar ? company.avatar : defaultCompany,
            }}
            onAvatarSelected={onAvatarSelected}
            loading={loading}
          />

          <div className={styles["form-separator"]} />

          <div className="w-100">
            <h4 className="mb-4">{t("rightResume.cardTitle")}</h4>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <b>{t("rightResume.comercialName")}</b>
                  </Grid>
                  <Grid item>
                    <span>{company.companyName}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <b>{t("rightResume.businessName")}</b>
                  </Grid>
                  <Grid item>
                    <span>{company.businessName}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <b>{t("rightResume.rfc")}</b>
                  </Grid>
                  <Grid item>
                    <span>{company.rfc}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>

      <Grid container className="mt-4">
        <Grid item xs={12} lg={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <h4 className="font-size-lg mb-0 py-2 font-weight-bold">
                {t("companyBuyersTable.cardTitle")}
              </h4>
            </Grid>
            <Grid item>
              <Button
                size="small"
                variant="contained"
                color="primary"
                className="custom-primary-btn"
                onClick={handleAddUserClick}
              >
                {t("companyBuyersTable.addBuyerButtonLabel")}
              </Button>
            </Grid>
          </Grid>

          <Dialog maxWidth="lg" fullWidth={true} open={open}>
            <AddBuyer
              companyid={CompanyId}
              onClose={onClose}
              onSuccess={onSuccess}
            />
          </Dialog>
        </Grid>
      </Grid>
      <DataTable
        routeConfig={{
          history,
          locationSearch: location.search,
        }}
        // refreshTable={refreshTable}
        columns={tableColumns}
        data={buyers}
        pagination
        paginationServer
        keyField="_id"
        onFilterChange={handleFilterChange}
        paginationRowsPerPageOptions={[10, 20, 50, 100]}
        paginationTotalRows={totalBuyerRows}
        progressPending={buyersLoading}
      />
    </Fragment>
  );
};

export default CompanyDetail;

import React from "react";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { ClimbingBoxLoader } from "react-spinners";
import Button from "@material-ui/core/Button";

import validateFileSize from "../../../../../../utils/validate-file-size";
import convertToBase64 from "../../../../../../utils/convertToBase64";
import axios from "../../../../../../config/axios";
import { FileDropzone } from "../../../../../../components";
import stylesBody from "./body-import.module.scss";

const styleImports = {
  height: "60px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const BodyImportUser = ({ handleImportUserCancel }) => {
  const { t } = useTranslation("users");
  const [loading, setLoading] = React.useState(false);
  const [fileBase64, setFileBase64] = React.useState("");
  const [disabledImport, setDisabledImport] = React.useState(true);
  const [fileLabel, setFileLabel] = React.useState(
    t("importUsersModal.dropzonePlaceholder")
  );

  const onDropFile = async (files, fieldName, maxFileSize) => {
    const selectedFile = files[0];
    const isValidSizeFile = validateFileSize(selectedFile.size, maxFileSize);

    // Validate max file size, clean dropped file in case it's invalid
    if (isValidSizeFile) {
      let base64File = undefined;
      try {
        base64File = await convertToBase64(selectedFile);
        setFileBase64(base64File);
        setDisabledImport(false);
        setFileLabel(selectedFile.name);
      } catch (error) {
        throw new Error(error);
      }
    } else {
      handleImportUserCancel();
      Swal.fire({
        icon: "error",
        title: "Archivo muy pesado",
        text: `El tamaño máximo permitido del archivo es de ${maxFileSize *
          1000}KB, inténtalo de nuevo.`,
      });
      return;
    }
  };
  const handleImportUsers = () => {
    setLoading(true);
    setDisabledImport(true);
    try {
      const csvData = fileBase64.split("/")[2];
      axios
        .post(
          `import-users`,
          { csvData },
          { headers: { responseType: "blob" } }
        )
        .then((body) => {
          if (
            new Blob([body.data]).size < 50 &&
            body.data.data.message === "Ok"
          ) {
            handleImportUserCancel();
            Swal.fire({
              icon: "success",
              title: "Usuarios importados exitosamente",
              timer: 3000,
            });
            setLoading(false);
            setDisabledImport(false);
          } else if (body.status === 201) {
            handleImportUserCancel();
            Swal.fire({
              icon: "error",
              title:
                "Se importaron solo algunos usuarios con éxito, favor de revisar el reporte de errores descargado.",
            });
            let url = window.URL.createObjectURL(new Blob([body.data]));
            let a = document.createElement("a");
            a.href = url;
            a.download = "ReporteDeErrores.csv";
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
            setDisabledImport(false);
          } else {
            handleImportUserCancel();
            Swal.fire({
              icon: "error",
              title:
                "Se encontraron errores en la importación, se descargará un archivo csv con los errores.",
            });
            let url = window.URL.createObjectURL(new Blob([body.data]));
            let a = document.createElement("a");
            a.href = url;
            a.download = "ReporteDeErrores.csv";
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoading(false);
            setDisabledImport(false);
          }
        })
        .catch((e) => {
          handleImportUserCancel();
          Swal.fire({
            icon: "error",
            title: "Error del servidor",
          });
        });
    } catch (error) {
      throw new Error(error);
    }
  };
  return (
    <div className={stylesBody["body-import-container"]}>
      <p>{t("importUsersModal.dropzoneTitle")}</p>
      <FileDropzone
        label={fileLabel}
        disabled={loading}
        fileTypesAccepted=".csv"
        onDrop={(files) => onDropFile(files, "brochure", 0.5)}
        styleImports={styleImports}
      />
      <div style={{ display: loading ? "block" : "none" }}>
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={"#5383ff"} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          {t("loadingImportUsers")}
          <span className="font-size-lg d-block text-dark">
            {/* This live preview instance can be slower than a real production
              build! */}
          </span>
        </div>
      </div>
      <div className={stylesBody["container-action-buttons"]}>
        <Button
          type="submit"
          size="small"
          variant="contained"
          className="custom-primary-btn"
          color="default"
          onClick={handleImportUserCancel}
        >
          {t("importUsersModal.cancelButtonLabel")}
        </Button>
        <Button
          type="submit"
          size="small"
          variant="contained"
          color="primary"
          className="custom-primary-btn"
          onClick={handleImportUsers}
          disabled={disabledImport}
        >
          {t("importUsersModal.importButtonLabel")}
        </Button>
      </div>
    </div>
  );
};
export default BodyImportUser;

import {
  REGISTER,
  REGISTER_FAILED,
  REGISTER_SUCCESS,
  CONFIRM,
  CONFIRM_FAILED,
  CONFIRM_SUCCESS,
  RECOVER_PASS,
  RECOVER_PASS_FAILED,
  RECOVER_PASS_SUCCESS,
  CHANGE_PASS,
  CHANGE_PASS_FAILED,
  CHANGE_PASS_SUCCESS,
  DYNAMIC_FORM,
  DYNAMIC_FORM_FAILED,
  DYNAMIC_FORM_SUCCESS,
  RESET,
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  success: false,
  recoverSuccess: false,
  changePassSuccess: false,
  dynamicForm: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- REGISTER  -----
    case REGISTER:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case REGISTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      };

    // ----- REGISTER CONFIRMATION -----
    case CONFIRM:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case CONFIRM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
        success: false,
      };
    case CONFIRM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
      };

    // ----- RECOVER PASSWORD - EMAIL SENT  -----
    case RECOVER_PASS:
      return {
        ...state,
        loading: true,
        error: false,
        recoverSuccess: false,
      };
    case RECOVER_PASS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case RECOVER_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        recoverSuccess: true,
      };

    // ----- CHANGE PASSWORD - RECOVERY  -----
    case CHANGE_PASS:
      return {
        ...state,
        loading: true,
        error: false,
        changePassSuccess: false,
      };
    case CHANGE_PASS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };
    case CHANGE_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        changePassSuccess: true,
      };

    // ----- GET DYNAMIC REGISTER FORM -----
    case DYNAMIC_FORM:
      return {
        ...state,
        loading: true,
      };

    case DYNAMIC_FORM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case DYNAMIC_FORM_SUCCESS:
      return {
        ...state,
        loading: false,
        dynamicForm: action.data,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
        recoverSuccess: false,
        changePassSuccess: false,
      };
    default:
      return state;
  }
}

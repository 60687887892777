import axios from "axios";
import getLocalAccessToken from '../utils/helpers/getLocalAccessToken';
const { REACT_APP_API_URL, REACT_APP_TENANT_CODE, REACT_APP_EVENT_INFO_ID } = process.env;

// Axios defaults here
axios.defaults.baseURL = REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  const accessToken = getLocalAccessToken();
  const loggedUser = JSON.parse(
    localStorage.getItem("loggedUser") || sessionStorage.getItem("loggedUser")
  );

  config.headers = config.headers || {};
  config.headers["code"] = REACT_APP_TENANT_CODE;
  config.headers["eventinfoid"] = REACT_APP_EVENT_INFO_ID;

  if (loggedUser) {
    config.headers["role"] = loggedUser.role.code;
  }

  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`;
  } else {
    delete config.headers["Authorization"];
  }
  return config;
});

export default axios;

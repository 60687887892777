import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "components";
import { getRoles, reset, registerBuyerAndCompany } from "redux/actions/";
import BuyerForm from "./BuyerForm";

const AddAssistant = (props) => {
  const { t, i18n } = useTranslation("users");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const {
    loading: registerLoading,
    error: registerError,
    success: registerSuccess,
  } = useSelector((store) => store.buyers);

  useEffect(() => {
    if (registerSuccess) {
      props.onSuccess();
      dispatch(reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerSuccess]);

  // ----- State -----

  const { success, error: additionalInfoError } = useSelector(
    (store) => store.additionalInfo
  );

  const onSubmit = (user) => {
    dispatch(registerBuyerAndCompany(user));
  };

  const getModalTitle = () => {
    switch (currentLanguage) {
      case "es":
        return "Información Comprador";
      case "en":
        return "Buyer Information";
      default:
        return "";
    }
  };

  return (
    <div className="mx-4">
      <Grid item lg={12} xs={12} style={{ width: 1000 }}>
        <h4 className="font-size-xl mb-0 py-2 font-weight-bold">
          {getModalTitle()}
        </h4>

        <BuyerForm
          registerLoading={registerLoading}
          history={props.history}
          onSubmit={onSubmit}
        />

        <Button
          size="large"
          variant="contained"
          className="mb-2 cerradoDinamico"
          onClick={props.onClose}
          style={{ marginTop: "-75px" }}
        >
          {t("addUserModal.closeButtonLabel")}
        </Button>
      </Grid>

      {success && (
        <Grid item xs={12} lg={12}>
          <Alert
            severity="success"
            message="Operación realizada exitosamente."
          />
        </Grid>
      )}

      <Grid item className="w-100">
        {registerError && <Alert severity="error" message={registerError} />}
      </Grid>

      {additionalInfoError && (
        <Grid item xs={12} lg={12}>
          <Alert severity="error" message={additionalInfoError} />
        </Grid>
      )}
    </div>
  );
};

export default AddAssistant;

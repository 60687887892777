import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { cdnDynamic } from "../utils/dynamicTags";

export default function ReactPayPal({ price }) {
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const [paid, setPaid] = useState(false);
  // const [error, setError] = useState(null);
  const [cdnLoaded, setCdnLoaded] = useState(false);
  const paypalRef = useRef();

  useEffect(() => {
    if (eventInfo.paymentMethods) {
      const urlDynamic = `https://www.paypal.com/sdk/js?client-id=${eventInfo.paymentMethods[1].key}&currency=MXN`;
      cdnDynamic(urlDynamic, () => {
        setCdnLoaded(true);
      });
    }
  }, [eventInfo]);

  // To show PayPal buttons once the component loads
  useEffect(() => {
    if (window.paypal && cdnLoaded) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [
                {
                  description: "Your description",
                  amount: {
                    currency_code: "MXN",
                    value: price,
                  },
                },
              ],
            });
          },
          onApprove: async (data, actions) => {
            await actions.order.capture();
            setPaid(true);
          },
          onError: (err) => {
            //   setError(err),
            console.error(err);
          },
        })
        .render(paypalRef.current);
    }
  }, [cdnLoaded, price]);

  // If the payment has been made
  if (paid) {
    return <div>Pago exitoso.!</div>;
  }

  // If any error occurs
  // if (error) {
  //   return (
  //     <div>
  //       Ocurrio un error al procesar el pago.! Por favor intenta de nuevo.
  //     </div>
  //   );
  // }

  // Default Render
  return (
    <div>
      <div ref={paypalRef} />
    </div>
  );
}

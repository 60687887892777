import React from "react";
import PhoneInputComponent from "react-phone-input-2";

import styles from "./phone-input.module.scss";
import rightFormStyles from "../profile/right-form/right-form.module.scss";

const PhoneInput = ({
  dataTestId,
  inputLabel,
  value,
  valueName,
  previousState,
  setValue,
  placeholder,
  error,
  helperText = "",
  setCountryData,
  disabled = false,
}) => {
  return (
    <>
      <p className={rightFormStyles["form-label"]}>{inputLabel}</p>
      <PhoneInputComponent
        country="mx"
        disabled={disabled}
        value={value}
        inputProps={{
          autoComplete: "none",
          "data-testid": dataTestId + "-input",
        }}
        placeholder={placeholder}
        onChange={
          setValue
            ? (phone) => setValue({ ...previousState, [valueName]: phone })
            : () => {}
        }
        onBlur={
          setCountryData ? (_, country) => setCountryData(country) : () => {}
        }
        containerStyle={{ marginBottom: "1rem" }}
        inputStyle={{ width: "100%", color: disabled ? "gray" : "" }}
      />
      {value && value.length && error ? (
        <p className={styles["helper-error-text"]}>{helperText}</p>
      ) : null}
    </>
  );
};

export default PhoneInput;

import React, { Fragment } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IconButton, Box } from "@material-ui/core";

const HeaderLogo = (props) => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx("app-header-logo", {
          "app-header-logo-close": sidebarToggle,
          "app-header-logo-open": sidebarHover,
        })}
      >
        <Box className="header-logo-wrapper" title="Expo Tour Virtual">
          <Link to="/dashboard" className="header-logo-wrapper-link">
            <IconButton
              color="primary"
              size="medium"
              className="header-logo-wrapper-btn"
            >
              {/* <img
                className="app-header-logo-img"
                alt="Carolina React Admin Dashboard with Material-UI PRO"
                src={projectLogo} TODO: WHAT ICON TO PLACE HERE
              /> */}
            </IconButton>
          </Link>
          <Box className="header-logo-text">Expo Tour Virtual</Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
});

export default connect(mapStateToProps)(HeaderLogo);

import { useTranslation } from "react-i18next";
import React from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  filtersContainer: {
    display: "flex",
    flex: 1,
    overflowX: "scroll",
  },
  filterWrapper: {
    width: 180,
    marginRight: 10,
  },
});

const DroplistFilter = ({
  handleSelectChange,
  filters,
  textFieldClassName,
  menuItemClassName,
}) => {
  const { i18n, t } = useTranslation("data-table");
  const currentLanguage = i18n.language;
  const classes = useStyles();

  try {
    return (
      <div className={classes.filtersContainer}>
        {filters.length > 0 &&
          filters.map(
            (
              {
                displayName,
                catalog,
                selector,
                defaultSelectorKey,
                omitRemoveFilters,
                defaultValue,
              },
              i
            ) => (
              <div key={`datatable-select-${i}`} style={{ width: "200px" }}>
                <TextField
                  name={displayName[currentLanguage]}
                  label={`${displayName[currentLanguage]}`}
                  fullWidth
                  select
                  defaultValue={defaultValue || "REMOVE_FILTERS"}
                  onChange={({ target: { value } }) =>
                    handleSelectChange({
                      selector,
                      value,
                      defaultSelectorKey,
                      omitRemoveFilters,
                    })
                  }
                  className={`${classes.filterWrapper} ${textFieldClassName}`}
                >
                  <MenuItem
                    key={`datatable-selectoption-${displayName[currentLanguage]}-default`}
                    value={"REMOVE_FILTERS"}
                    className={menuItemClassName}
                  >
                    {t("all")}
                  </MenuItem>
                  {catalog.map((option, index) => (
                    <MenuItem
                      key={`datatable-selectoption-${displayName[currentLanguage]}-${index}`}
                      value={option[selector]}
                    >
                      {option[selector]}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
            )
          )}
      </div>
    );
  } catch {
    return null;
  }
};

export default DroplistFilter;

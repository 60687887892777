import { useTranslation } from "react-i18next";
import React from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";

import { PhoneInput } from "../../..";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const FullBuyer = ({ user }) => {
  const { t, i18n } = useTranslation("profile");
  const currentLanguage = i18n.language;

  const {
    firstName,
    lastName,
    position,
    email,
    contact: { phoneDialCode, phone, cellphoneDialCode, cellphone },
    personType,
    needs,
    companyId: { comercialName, businessName, rfc, companyWeb },
  } = user;

  const { personTypes } = useUserCatalogs();

  return (
    <div data-testid="full-buyer-data">
      <Grid container spacing={4}>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={firstName}
            name="firstName"
            label={t("rightForm.firstName")}
            placeholder={t("rightForm.firstNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={lastName}
            name="lastName"
            label={t("rightForm.lastName")}
            placeholder={t("rightForm.lastNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={position}
            name="position"
            label={t("rightForm.position")}
            placeholder={t("rightForm.positionPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>

        <Grid item className="w-50">
          <TextField
            type="email"
            value={email}
            name="email"
            label={t("rightForm.email")}
            placeholder={t("rightForm.emailPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <PhoneInput
            inputLabel={t("rightForm.phone")}
            value={phone ? `${phoneDialCode}${phone}` : ""}
            valueName="phone"
            previousState={user}
            placeholder={t("rightForm.phonePlaceholder")}
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <PhoneInput
            inputLabel={t("rightForm.cellphone")}
            value={`${cellphoneDialCode}${cellphone}`}
            valueName="cellphone"
            previousState={user}
            placeholder={t("rightForm.cellphonePlaceholder")}
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={comercialName}
            name="comercialName"
            label={t("rightForm.comercialName")}
            placeholder={t("rightForm.comercialNamePlaceholder")}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <TextField
            name="personType"
            label={t("rightForm.personType")}
            fullWidth
            disabled
            select
            value={personType}
          >
            {personTypes.array.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label[currentLanguage]}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={businessName}
            name="businessName"
            label={t("rightForm.businessName")}
            placeholder={t("rightForm.businessNamePlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
        <Grid item className="w-50">
          <TextField
            type="text"
            value={rfc}
            name="rfc"
            label={t("rightForm.rfc")}
            placeholder={t("rightForm.rfcPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-50">
          <TextField
            type="text"
            value={companyWeb}
            name="companyWeb"
            label={t("rightForm.companyWeb")}
            placeholder={t("rightForm.companyWebPlaceholder")}
            autoComplete="off"
            fullWidth
            disabled
          />
        </Grid>
      </Grid>

      <Grid container spacing={4} className="mt-1">
        <Grid item className="w-100">
          <TextField
            type="text"
            value={needs}
            name="needs"
            label={t("rightForm.needsBuyer")}
            placeholder={t("rightForm.needsBuyerPlaceholder")}
            autoComplete="off"
            maxRows="4"
            multiline
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FullBuyer;

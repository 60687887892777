/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField, Button } from "@material-ui/core";

import { AvatarUploader, Loader } from "components";
import ModalMenu from "components/ModalMenu";
import BuyerCalendar from "./buyer-calendar";
import styles from "./left-resume.module.scss";
import { uploadUserAvatar } from "redux/actions";
import convertToBase64 from "utils/convertToBase64";
import profileStyles from "../../profile/left-resume/left-resume.module.scss";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const LeftResume = ({
  user: { _id, avatar, firstName, lastName, email, position },
  appointment: {
    date,
    startTime,
    endTime,
    status,
    description,
    videoConferenceUrl,
    googleLink,
    outlookLink,
    icsFileContent,
  },
  screen,
  availableHours,
  acceptAppointmentRequest,
  rejectAppointmentRequest,
  cancelAcceptedAppointment,
}) => {
  const { t, i18n } = useTranslation("appointment-profile");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const { screenEnums, roleId } = useUserCatalogs();

  const { loggedUser } = useSelector((store) => store.loggedUser);
  const isRoleGodOrAdmin =
    loggedUser.role._id === roleId.GOD || loggedUser.role._id === roleId.ADMIN;

  const {
    loading,
    updateSuccess,
    updatedUser,
    uploadSuccess,
    newAvatar,
  } = useSelector((store) => store.user);

  const [userData, setUserData] = useState({
    avatar,
    firstName,
    lastName,
    position,
  });

  const acceptedAppointmentStatusId = "5f57e0dfc8658a46c5c484d1"; // TODO: Move this to a catalog like the one on useUserCatalogs
  const displayAppointmentField =
    status && status._id === acceptedAppointmentStatusId ? true : false;

  // Update user avatar without refresh with updated response
  useEffect(() => {
    if (uploadSuccess) {
      setUserData({
        ...userData,
        avatar: newAvatar,
      });
    }
  }, [uploadSuccess]);
  // Reset user avatar when mounting to avoid taking last updated
  useEffect(() => {
    setUserData({
      ...userData,
      avatar,
    });
  }, []);
  useEffect(() => {
    if (updateSuccess) {
      setUserData({
        ...userData,
        firstName: updatedUser.firstName,
        lastName: updatedUser.lastName,
        position: updatedUser.position,
      });
    }
  }, [updateSuccess]);

  const onAvatarSelected = async (e) => {
    const selectedFile = e.target.files[0];
    let base64Avatar = undefined;
    try {
      base64Avatar = await convertToBase64(selectedFile);
      uploadAvatar(base64Avatar);
    } catch (error) {
      throw new Error(error);
    }
  };
  const uploadAvatar = (userAvatarConverted) => {
    dispatch(uploadUserAvatar(_id, userAvatarConverted));
  };

  const AppointmentDetails = () => {
    return (
      <div data-testid="appointment-details">
        <div className="text-center">
          <b>{t("leftResume.appointmentDetailsTitle")}</b>
        </div>

        <Grid container className="mt-4">
          <Grid item className="w-100 mb-3">
            <TextField
              type="text"
              label={t("leftResume.appointmentStatus.dateLabel")}
              value={date ? moment.utc(date).format("DD/MM/YYYY") : ""}
              disabled
            />
          </Grid>
          <Grid item className="w-100 mb-3">
            <TextField
              type="text"
              label={t("leftResume.appointmentStatus.hourLabel")}
              value={`${startTime}-${endTime}`}
              disabled
            />
          </Grid>
          <Grid item className="w-100 mb-3">
            <TextField
              type="text"
              label={t("leftResume.appointmentStatus.statusLabel")}
              value={status.name[currentLanguage]}
              disabled
            />
          </Grid>
          <Grid item className="w-100 mb-3">
            <TextField
              type="text"
              label={t("leftResume.appointmentStatus.notesLabel")}
              value={
                description.length ? description[description.length - 1] : ""
              }
              disabled
            />
          </Grid>
          {displayAppointmentField ? (
            <>
              <Grid item className="w-100 mt-2">
                <ModalMenu
                  Items={[
                    {
                      name: "Google Calendar",
                      action: () => window.open(googleLink),
                    },
                    {
                      name: "Outlook Live",
                      action: () => window.open(outlookLink),
                    },
                    {
                      name: t("leftResume.appointmentStatus.downloadEvent"),
                      action: () => {
                        const url = window.URL.createObjectURL(
                          new Blob([icsFileContent], { type: "text/calendar" })
                        );
                        const a = document.createElement("a");
                        a.href = url;
                        a.download = "invite.ics";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                      },
                    },
                  ]}
                  buttonText={t("leftResume.appointmentStatus.addCalendar")}
                  buttonTextStyles={{background: 'rgba(211, 208, 207,.2)'}}
                  variant={true}
                />
              </Grid>
              <Grid item className="w-100 mt-4">
                {videoConferenceUrl && videoConferenceUrl !== "" ? (
                  <>
                    <a href={videoConferenceUrl}>
                      <Button variant="contained" color="primary">
                        {t("leftResume.videoconferenceButtonLabel")}
                      </Button>
                    </a>
                  </>
                ) : null}
              </Grid>
            </>
          ) : null}
        </Grid>
      </div>
    );
  };

  return (
    <div className={profileStyles["left-resume-wrapper"]}>
      <h4 className="text-left mb-4">{t("leftResume.cardTitle")}</h4>
      {loading ? (
        <Loader />
      ) : (
        <div className="text-center">
          <div className="avatar-icon-wrapper avatar-icon-xl">
            <div className="d-block p-0 avatar-icon-wrapper m-0">
              <div className="overflow-hidden">
                <div className="avatar-icon">
                  <img
                    data-testid="avatar-img"
                    src={userData.avatar}
                    alt={`${userData.firstName} ${userData.lastName} avatar`}
                    className={styles["avatar-img"]}
                  />
                </div>
                {isRoleGodOrAdmin && (
                  <AvatarUploader
                    loading={loading}
                    onAvatarSelected={onAvatarSelected}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <h5 className="font-weight-bold text-center">
        {userData.firstName} {userData.lastName}
      </h5>
      {userData.position && (
        <p className={styles["position-label"]}>{userData.position}</p>
      )}
      <p className={styles["email-label"]}>{email}</p>

      <div>
        {/* Buyer */}
        {screen === screenEnums.LIMITED_BUYER_APPOINTMENT_REQUESTED ? (
          <div className="text-center">
            <b style={{ color: "var(--primary)" }}>
              {t("leftResume.appointmentStatus.requestedAppointmentLabel")}
            </b>
          </div>
        ) : screen === screenEnums.FULL_BUYER_APPOINTMENT_ACCEPTED ? (
          <>
            <AppointmentDetails />
          </>
        ) : screen === screenEnums.LIMITED_BUYER_APPOINTMENT_REJECTED ? (
          <div className="text-center">
            <b style={{ color: "#F92B2B" }}>
              {t(
                "leftResume.appointmentStatus.rejectedAppointmentAsProviderLabel"
              )}
            </b>
          </div>
        ) : screen === screenEnums.LIMITED_BUYER_APPOINTMENT_CANCELED ? (
          <>
            <div className="text-center mb-4">
              <b>
                {t(
                  "leftResume.appointmentStatus.canceledAppointmentAsProviderLabel"
                )}
              </b>
            </div>

            <AppointmentDetails />
          </>
        ) : null}
        {/* Seller */}
        {screen === screenEnums.FULL_SELLER_APPOINTMENT_REQUESTED ? (
          <BuyerCalendar
            availableHours={availableHours}
            rejectAppointmentRequest={rejectAppointmentRequest}
            acceptAppointmentRequest={acceptAppointmentRequest}
          />
        ) : screen === screenEnums.FULL_SELLER_APPOINTMENT_ACCEPTED ? (
          <>
            <AppointmentDetails />

            <div className="text-center mt-4">
              <Button
                className="text-white font-weight-bold"
                style={{ backgroundColor: "#F92B2B" }}
                onClick={cancelAcceptedAppointment}
              >
                {t("leftResume.cancelAppointmentButtonLabel")}
              </Button>
            </div>
          </>
        ) : screen === screenEnums.FULL_SELLER_APPOINTMENT_REJECTED ? (
          <div className="text-center">
            <b>
              {t(
                "leftResume.appointmentStatus.rejectedAppointmentAsBuyerLabel"
              )}
            </b>
          </div>
        ) : screen === screenEnums.FULL_SELLER_APPOINTMENT_CANCELED ? (
          <>
            <div className="text-center mb-4">
              <b>
                {t(
                  "leftResume.appointmentStatus.canceledAppointmentAsBuyerLabel"
                )}
              </b>
            </div>

            <AppointmentDetails />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default LeftResume;

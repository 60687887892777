import React from "react";
import { Card, Grid } from "@material-ui/core";
import "./_base.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
const { TIME_ZONE } = process.env;
/**
 * Each array inside the contents array must have the same length of headers
 *
 * @param {Array} headers
 * @param {Array of Arrays} content
 *
 * @return {JSX element}
 */
const DynamicCalendar = (props) => {
  moment.tz.setDefault(TIME_ZONE);
        const dateNow = moment().format(
          "YYYY-MM-DD"
        )
        const hoursNow = moment().format(
          "HH"
        )
        const minutesNow = moment().format(
          "mm"
        )
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const { t, i18n } = useTranslation("public-profile");
  const currentLanguage = i18n.language;
  moment.locale(currentLanguage);
  const [showAll, setShowAll] = React.useState(false);
  const {
    calendarSchedule,
    selectAppointment,
    daySelected,
    timeSelected,
    buyerSchedule,
  } = props;

  function addMinutes(time, minsToAdd) {
    function validateTime(J) {
      return (J < 10 ? "0" : "") + J;
    }
    let piece = time.split(":");
    let mins = piece[0] * 60 + +piece[1] + +minsToAdd;

    return (
      validateTime(((mins % (24 * 60)) / 60) | 0) +
      ":" +
      validateTime(mins % 60)
    );
  }
  const onSelectAppointment = (day, time) => {
    return () => {
      selectAppointment({
        date: moment(day),
        startTime: time,
        endTime: addMinutes(time, 30),
      });
    };
  };

  //const paginationTotal = calendarSchedule.length

  const [renderizar, setRenderizar] = React.useState(""); //con este estado renderizaremos cada que se encuentren cambios

  let countPages = 0; //contador de paginas

  let refreshPage = 0; //controlador para saber la paginacion

  let paginationTotal = calendarSchedule.length; //tomemos el total de dias que enviaron

  if (paginationTotal > 4) {
    //si la variable es mayor a 4, entonces tenemos paginacion
    countPages = 1; //declaramos la primera pagina
    for (let ipa = 0; ipa < paginationTotal - 1; ipa++) {
      //recorremos la paginacion total para ir diviendo en 4, y tomar las paginas correspondientes
      refreshPage++;
      if (refreshPage >= 4) {
        countPages++;
        refreshPage = 0;
      }
    }
  } else {
  }

  const [paginationActual, setPaginationActual] = React.useState(1); //con este estado, obtenemos el numero de pagina en el que estaremos situados

  const [enumera, setEnumera] = React.useState([0, 3]); //este estado contiene el array para filtrar las horas y fechas

  const calendarWidthStyles = {
    width: 370, // EDIT: below this measure, calendar wont work as expected or will mess up if putting inside <div>
    // width: calendarSchedule.length * 55,
  };
  const calendarHeightStyles = !showAll ? { height: 307.5 } : {};
  const toggleSchedules = () => {
    setShowAll(!showAll);
  };
  if (!calendarSchedule || !calendarSchedule.length)
    return "Sin datos para mostrar en el calendario";
  let DOMCalendarColumns = [];
  //horas en general, para mostrar
  let EventHours = eventInfo.calendarHours || [];

  let setDOMCalendarColumns = (initial, final) => {
    //definimos una funcion con la cual recibimos los parametros iniciales y finales para filtrar
    //unimos los arrays en un objeto vacio para despues comprobar cuales son las horas con s o con n, y despues validar
    calendarSchedule.forEach((schedule, i) => {
      let DOMDayColumn = [];
      if (i >= initial && i <= final) {
        //si nuestra variable de mapeo se encuentra en el intervalo que le pasamos, entonces empezaremos a llenar el arreglo para posteriormente renderizar
        //prueba

        //recorremos el arreglo para craer uno nuevo concatenando ',s' esto para despues hacer split y validar

        let commonValues2 = buyerSchedule[i].available.filter(function(value) {
          //este es nuestro punto de interseccion, con esto sabremos, que horas estan realmente disponibles !
          return schedule.available.indexOf(value) > -1;
        });

        //recorreremos  cada una de las horas que nos manden de el vendedor
        schedule.available.forEach((val, indi) => {
          EventHours.forEach((valor, indica) => {
            //recorremos las horas generales para compararlas y saber si hay disponibilidad
            let spliti = valor.split(","); //generamos un array separado por comas, para saber el valor verdadero ya que actualemnte usamos ,n para la disponibilidad
            if (spliti[0] == val) {
              //si son iguales, entonces, declararemos el nuevo valor y quitaremos la n
              EventHours[indica] = val;
            } else {
            }
          });
        });
        //recibimos los datos de el comprador
        buyerSchedule[i].available.forEach((val, indi) => {
          EventHours.forEach((valor, indica) => {
            if(buyerSchedule[indi]){
              if(buyerSchedule[indi].day === dateNow){
                for(let iSchedule = 0; iSchedule < buyerSchedule[indi].available.length;iSchedule++){
                  const getScheduleTime = buyerSchedule[indi].available[iSchedule].split(":")
                 if(Number(getScheduleTime[0]) <= Number(hoursNow)){
                   if(Number(getScheduleTime[0]) === Number(hoursNow)){
                    if(Number(getScheduleTime[1]) < minutesNow){
                      buyerSchedule[indi].available[iSchedule] = buyerSchedule[indi].available[iSchedule]+',o'
                     }
                   }else{
                    buyerSchedule[indi].available[iSchedule] = buyerSchedule[indi].available[iSchedule]+',o'
                   }
                             }
                }       
                            }
            }
            //mismo proceso, buscamos dentro del array general
            let spliti = valor.split(","); //separamos el array por comillas

            if (spliti[0] == val) {
              //si nos encontramos un valor igual entramos al siguiente ciclo

              if (spliti.length < 2) {
                //esto nos indicara, si ya se quito la n !

                EventHours[indica] = val;
              } else {
                //si no se ha quitado, automaticamente, se vuelve hora para el vendedor
                EventHours[indica] = val + ",vendedor";
              }
            } else {
              //si no coinciden los datos,  entonces
              let buscame = commonValues2.find((buscar) => buscar == spliti[0]); //buscaremos ese array en nuestra interseccion , para descartar estar metiendo el ,n y generar valores erroneos
              if (buscame) {
                //si el valor esta dentro de la inrseccion, no pasara nada
              } else {
                //de lo contrario entramos a lo siguiente

                if (spliti.length < 2) {
                  //preguntamos si dicho valor esta ya declarado por el vendedor, de ser asi tomaremos prioridad para nuestro comprador

                  EventHours[indica] = spliti[0] + ",n";
                } else {
                }
              }
            }
          });
        });

        let ArrayHours = EventHours;

        if (schedule.available && schedule.available.length) {
          DOMDayColumn.push(
            <Grid
              className="date-column-width header-date"
              key={`${i}-day-column`}
            >
              <span className="day">{moment(schedule.day).format("dddd")}</span>
              <span className="date">
                {moment(schedule.day).format("MMM D")}
              </span>
            </Grid>
          );
          //recorremos el nuevo array que tendra la combinacion de horas con s y n, las cuales s, significa validos, y n, no valido

          ArrayHours.forEach((appointment, j) => {
            let AppointmentNew = appointment.split(","); //creamos un array a partir de la ',' para diferenciar entre s y n
            if (AppointmentNew[1] === "n" || AppointmentNew[1] === "vendedor") {
              //preguntamos si el segundo valor es n,  y si es n, tachamos la hora y la deshabilitamos
              DOMDayColumn.push(
                <Grid
                  container
                  justifyContent="center"
                  key={`${j}-hour-row`}
                  className="date-column-width"
                >
                  <div
                    className={"time-block-unable"}
                    style={
                      AppointmentNew[1] === "vendedor"
                        ? { background: "white" }
                        : { background: "rgba(250,0,0,.3)" }
                    }
                  >
                    <span className="time-text">
                      <s>{AppointmentNew[0]}</s>
                    </span>
                  </div>
                </Grid>
              );
            } else {
              //si no es n, habilitamos la hora, para su posterior activacion
              DOMDayColumn.push(
                <Grid
                  container
                  justifyContent="center"
                  key={`${j}-hour-row`}
                  className="date-column-width"
                >
                  <div
                    className={`${
                      schedule.day === daySelected &&
                      AppointmentNew[0] === timeSelected
                        ? "time-slot-selected"
                        : "time-block "
                    }`}
                    onClick={onSelectAppointment(
                      schedule.day,
                      AppointmentNew[0]
                    )}
                  >
                    <span
                      onClick={
                        //funcion para cambibar el color en automatico, y no se quede guardada la seleccion anterior
                        (e) => {
                          let backgroundHidden = document.querySelectorAll(
                            ".time-text"
                          );
                          for (
                            let ifa = 0;
                            ifa < backgroundHidden.length;
                            ifa++
                          ) {
                            if (
                              backgroundHidden[
                                ifa
                              ].parentElement.classList.contains(
                                "time-block-unable"
                              )
                            ) {
                            } else {
                              backgroundHidden[ifa].style.background =
                                "#EDF3FC";
                              backgroundHidden[
                                ifa
                              ].parentElement.style.background = "#EDF3FC";
                              backgroundHidden[ifa].style.color =
                                "rgba(0,0,210,.7)";
                              backgroundHidden[ifa].parentElement.style.color =
                                "rgba(0,0,210,.7)";
                            }
                          }
                          e.target.style.background = "blue";
                          e.target.parentElement.style.background = "blue";
                          e.target.style.color = "white";
                          e.target.parentElement.style.color = "white";
                        }
                      }
                      className="time-text"
                    >
                      {AppointmentNew[0]}
                    </span>
                  </div>
                </Grid>
              );
            }

            // DOM appointment hour button render
          });
        }

        // DOM Title date - day render

        DOMCalendarColumns.push(
          <Grid className="date-column-width" key={`${i}-calendar-columns`}>
            {DOMDayColumn}
          </Grid>
        );
      }
    });
  };
  setDOMCalendarColumns(enumera[0], enumera[1]); //iniciamos la funcion para obtener las primeras fechas

  const paginationLeft = () => {
    //funcion de izquierda para paginacion
    DOMCalendarColumns = []; //volvemos vacio el array del dom
    let comparate = paginationActual; //verificamos en que pagina estamos
    comparate--; //restamos ya que iremos hacia atras
    if (comparate == 0) {
      //si estamos en la pagina 0, pues no tendremos por que retroceder mas
    } else {
      let infe = enumera[0]; //obtenemos el array que es el filtrador para despues restarle 4
      let supe = enumera[1];
      infe -= 4;
      supe -= 4;
      setEnumera([infe, supe]); //insertamos en el estado, el nuevo array de filtrado
      setPaginationActual(comparate); //posicionamos la pagina actual
      setDOMCalendarColumns(infe, supe); //llamamos al renderizador
      setRenderizar(DOMCalendarColumns); //renderizamos con el estado
    }
  };

  const paginationRight = () => {
    let comparate = paginationActual;
    comparate++;
    if (comparate > countPages) {
    } else {
      let infe = enumera[0];
      let supe = enumera[1];

      infe += 4;
      supe += 4;

      setEnumera([infe, supe]);
      setPaginationActual(comparate);

      DOMCalendarColumns = [];
      setDOMCalendarColumns(infe, supe);
      setRenderizar(DOMCalendarColumns);
    }
  };
  const cardHeaderClasses = [
    "font-size-lg",
    "mb-0",
    "py-2",
    "font-weight-bold",
  ];

  return (
    <Grid
      item
      xs={12}
      className="d-flex justify-content-center"
      style={{ flexDirection: "column", marginTop: "0px" }}
    >
      <div className="text-center">
        <h4
          className={cardHeaderClasses.join(" ")}
          style={{ fontSize: "1rem" }}
        >
          {t("calendar.title")}
        </h4>
      </div>
      <Card className="p-3">
        <div className="horizontal-scroll" style={calendarHeightStyles}>
          <Grid
            container
            justifyContent="flex-start"
            style={calendarWidthStyles}
          >
            {countPages <= 1 ? null : paginationActual == 1 ? null : (
              <div className="pagination-left" onClick={paginationLeft}>
                <FontAwesomeIcon icon={["fas", "angle-left"]} color={"blue"} />
              </div>
            )}

            {renderizar === "" ? DOMCalendarColumns : renderizar}
            {countPages <= 1 ? null : paginationActual == countPages ? null : (
              <div className="pagination-right" onClick={paginationRight}>
                <FontAwesomeIcon icon={["fas", "angle-right"]} color={"blue"} />
              </div>
            )}
          </Grid>
        </div>
        <div className="show-more-button" onClick={toggleSchedules}>
          <span>{t("calendar.show")} {showAll ? t("calendar.less") : t("calendar.more")}</span>
          <span className="btn-icon-arrow-down">
            <FontAwesomeIcon icon={["fas", "angle-down"]} color={"blue"} />
          </span>
        </div>
        {/* <Button
          onClick={show}
          size="large"
          color="secondary">Mostrar {showAll ? 'menos' : 'más'}</Button> */}
      </Card>
    </Grid>
  );
};

export default DynamicCalendar;

import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";

import { Footer } from "../layout-components";

const PrivacyPolicy = (props) => {
  const { eventInfo } = useSelector((store) => store.eventInfo);

  return (
    <div className="bg-composed-wrapper--content py-5">
      <Grid item xl={5} lg={7} md={10} sm={12} className="mx-auto text-black">
        <div
          dangerouslySetInnerHTML={{ __html: eventInfo.privacyPolicyRichText }}
        />
      </Grid>
      <Footer showShadow={false} />
    </div>
  );
};

export default PrivacyPolicy;

/* eslint-disable no-useless-escape */
class Email {
  static regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static test(value) {
    return this.regexp.test(value.toLowerCase());
  }
}
const email = (value) => Email.test(value);

export default email;

/* eslint-disable react-hooks/exhaustive-deps */
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import Table from "react-data-table-component";
import SearchIcon from "@material-ui/icons/Search";
import ArrowUpIcon from "@material-ui/icons/ArrowDropUp";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { InputAdornment, Box, TextField, IconButton } from "@material-ui/core";

import DroplistFilter from "./DroplistFilter";

const useStyles = makeStyles((theme) =>
  createStyles({
    customTableHeader: {
      display: "flex",
      // justifyContent: "space-between",
      backgroundColor: "#FFF",
      borderWidth: 0,
      borderBottomWidth: 2,
      borderColor: "#E0E0E0",
      borderStyle: "solid",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    customTextInput: {
      borderRadius: 8,
      fontWeight: "bold",
      width: "320px",
    },
    customInputContainer: {
      padding: 10,
      width: "50%",
    },
    filtersContainer: {
      marginRight: 40,
      marginLeft: 40,
    },
  })
);

const tableCustomStyles = {
  headCells: {
    style: {
      backgroundColor: "#FFF",
      color: "#000",
      fontWeight: "bold",
    },
  },
};

const MILISECONDS_FOR_SEARCH_INPUT = 500;
const ROW_LIMIT_DEFAULT = 10;

// A custom hook function to save a previous value,
// accepts any type in order to compare them on a re-render
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const parseQueryNumber = (query) => {
  if (query) {
    return parseInt(query);
  }
  return undefined;
};

const DataTable = (props) => {
  const { i18n, t } = useTranslation("data-table");
  const currentLanguage = i18n.language;

  const {
    columns,
    enableSearch,
    onFilterChange,
    customStyles,
    filterFields,
    routeConfig,
    refreshTable,
  } = props;

  /* 
    Preselect column name to display in table based in current language.
    We get the key {es, en} in the _name_ key from props
  */
  for (const column of columns) {
    if (column.name[currentLanguage])
      column.name = column.name[currentLanguage];
  }

  let history = false,
    locationSearch = false;
  if (routeConfig) {
    history = routeConfig.history;
    locationSearch = routeConfig.locationSearch;
  }
  // Default table filter values - same for QUERY PARAM values
  let defaultValues = {
    search: "",
    rows: ROW_LIMIT_DEFAULT,
    page: "1",
    // sel: [],
    sort: 1,
    sortkey: "_id",
  };
  // If router config is sent, add listener to 'history' and read-write QUERY PARAM string values
  if (locationSearch) {
    const parsedQueryString = queryString.parse(locationSearch || "", {
      arrayFormat: "bracket-separator",
    });
    defaultValues = {
      ...defaultValues,
      ...parsedQueryString,
    };
  }

  // States
  const classes = useStyles();
  const [page, setPage] = useState(parseQueryNumber(defaultValues.page));
  const [limit, setLimit] = useState(parseQueryNumber(defaultValues.rows));
  const [sorting, setSorting] = useState({
    [defaultValues.sortkey]: parseQueryNumber(defaultValues.sort),
  });
  const [searchFilter, setSearchFilter] = useState(defaultValues.search);
  // const [selectedRows, setSelectedRows] = useState(defaultValues.querySelection);
  const [selectsFilters, setSelectsFilters] = useState({});
  const [typinTimeout, setTypinTimeout] = useState(0);

  // Hook prev state
  const previousSearch = usePrevious(searchFilter);

  // Main Effect to emit Filters outside event and populate URL query params
  const emitOnFilterChange = () => {
    const pagination = {
      limit,
      page,
    };
    onFilterChange(pagination, sorting, searchFilter.trim(), selectsFilters);
    if (history || locationSearch) {
      let sortingObject = false;
      if (typeof sorting === "object") {
        const key = Object.keys(sorting)[0];
        if (key !== "_id") sortingObject = { sortkey: key, sort: sorting[key] };
      }
      let newLocationSearch = "?";
      newLocationSearch += limit !== ROW_LIMIT_DEFAULT ? `rows=${limit}&` : "";
      newLocationSearch += page !== 1 ? `page=${page}&` : "";
      newLocationSearch += sortingObject
        ? `sortkey=${sortingObject.sortkey}&sort=${sortingObject.sort}&`
        : "";
      newLocationSearch += searchFilter !== "" ? `search=${searchFilter}&` : "";
      // newLocationSearch += selectedRows.length > 0 ? `sel[]=${selectedRows.toString()}` : "";

      if (newLocationSearch !== "?" || locationSearch !== newLocationSearch) {
        history.replace({ search: newLocationSearch });
      }
    }
  };

  // All filters effects
  useEffect(() => {
    emitOnFilterChange();
  }, [page, limit, sorting, selectsFilters, refreshTable]);
  // Search filter effects
  useEffect(() => {
    if (
      (previousSearch || searchFilter.length !== 0) &&
      previousSearch !== searchFilter
    ) {
      if (typinTimeout) {
        clearTimeout(typinTimeout);
      }
      setTypinTimeout(
        setTimeout(() => {
          emitOnFilterChange();
        }, MILISECONDS_FOR_SEARCH_INPUT)
      );
    }
  }, [searchFilter]);

  const handleRowLimit = useCallback(
    (currentLimit, _currentPage) => {
      setLimit(currentLimit);
    },
    [setLimit]
  );

  const handlePageChange = useCallback(
    (page, _totalRows) => {
      setPage(page);
    },
    [setPage]
  );

  const handleSorting = useCallback(
    (field, sort) => {
      const fieldSelector = (typeof field.selector === "string"
        ? field.selector
        : typeof field.name === "string"
        ? field.name
        : "_id"
      ).replace("[0]", "");
      const sortingField = {
        [fieldSelector]: sort === "asc" ? 1 : sort === "desc" ? -1 : 1,
      };
      setSorting(sortingField);
    },
    [setSorting]
  );

  const handleOnSearch = useCallback(
    (event) => {
      const search = event.target.value;
      setSearchFilter(search);
    },
    [setSearchFilter]
  );

  const handleClearSearch = useCallback(() => {
    setSearchFilter("");
  }, [setSearchFilter]);

  const handleSelectChange = useCallback(
    ({ value, selector, defaultSelectorKey, omitRemoveFilters }) => {
      const selectorKey = selector.replace("[0]", "");
      if (value === "REMOVE_FILTERS" && !omitRemoveFilters) {
        const _selectsFilters = { ...selectsFilters };
        delete _selectsFilters[defaultSelectorKey || selectorKey];
        setSelectsFilters(_selectsFilters);
        return;
      }
      setSelectsFilters({
        ...selectsFilters,
        [defaultSelectorKey || selectorKey]: value,
      });
    },
    [setSelectsFilters, selectsFilters]
  );

  const renderClearIcon = () => {
    if (searchFilter.length > 0) {
      return (
        <IconButton onClick={handleClearSearch}>
          <HighlightOffIcon color="disabled" />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <>
      {(enableSearch || filterFields) && (
        <Box className={classes.customTableHeader}>
          <TextField
            value={searchFilter}
            onChange={handleOnSearch}
            variant="outlined"
            placeholder={t("searchPlaceholder")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="disabled" />
                </InputAdornment>
              ),
              endAdornment: renderClearIcon(),
              className: classes.customTextInput,
            }}
            inputProps={{
              className: classes.customInputContainer,
            }}
          />
          {filterFields && filterFields.length > 0 && (
            <DroplistFilter
              filters={filterFields}
              handleSelectChange={handleSelectChange}
              textFieldClassName={classes.filtersContainer}
            />
          )}
        </Box>
      )}
      <Table
        {...props}
        onChangeRowsPerPage={handleRowLimit}
        onChangePage={handlePageChange}
        sortServer
        paginationPerPage={limit}
        paginationDefaultPage={page}
        defaultSortField={Object.keys(sorting)[0]}
        defaultSortAsc={sorting[Object.keys(sorting)[0]] ? true : false}
        onSort={handleSorting}
        noHeader={enableSearch}
        sortIcon={<ArrowUpIcon />}
        customStyles={{
          ...tableCustomStyles,
          ...customStyles,
        }}
        paginationComponentOptions={{
          rowsPerPageText: t("rowsPerPage"),
          rangeSeparatorText: t("rangeSeparator"),
        }}
      />
    </>
  );
};

export default DataTable;

import { minLength, maxLength, numeric } from "..";

export const validPhoneFlag = (phone) => {
  if (phone) {
    return (
      phone.length &&
      (!minLength(10)(phone) || !maxLength(15)(phone) || !numeric(phone))
    );
  }
  return true;
};

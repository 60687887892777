import {
  UPDATE_USER_SELF_DETAILS,
  UPDATE_USER_SELF_DETAILS_FAILED,
  UPDATE_USER_SELF_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_FAILED,
  UPDATE_USER_DETAILS_SUCCESS,
  RESET,
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  success: false,
  updatedUserSelfDetails: {},
  updatedUserDetails: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_SELF_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };

    case UPDATE_USER_SELF_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case UPDATE_USER_SELF_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        updatedUserSelfDetails: action.data,
      };

    // Update user details by admin on public profile
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        loading: true,
        success: false,
        error: false,
      };

    case UPDATE_USER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        updatedUserDetails: action.data,
      };

    case RESET:
      return {
        ...state,
        loading: false,
        error: false,
        success: false,
      };

    default:
      return state;
  }
}

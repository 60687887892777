import {
  Dashboard,
  ActivateAssistants as ActivateAssistantsPage,
  Users as UsersPage,
  VirtualTour as VirtualTourPage,
  MyAppointments as MyAppointmentsPage,
  Videoconferences as MyVideoconferencesPage,
  Profile as ProfilePage,
  PublicProfile as PublicProfilePage,
  Buyers as BuyersPage,
  Sellers as SellersPage,
  CompanyDetail as CompanyDetailPage,
  AddCompany as AddCompanyPage,
  Companies as CompaniesPage,
  AppointmentProfile as AppointmentProfilePage,
  ChangeRol as ChangeRolPage,
  SuccessfulRegister as SuccessfulRegisterPage,
  Report as ReportPage,
  ReportAppointments as ReportAppointmentsPage,
  ReportUsers as ReportPageUsers,
  Meet,
} from "../../pages";

// Session routes - options depending on role permissions
let DashboardModule = {
  label: {
    es: "Inicio",
    en: "Dashboard",
  },
  description: "Pantalla inicial del sistema",
  icon: "DashboardTwoToneIcon",
  to: "/dashboard",
  groupId: "inicio",
  hasAccess: true,
  component: Dashboard,
};
let TourVirtual = {
  label: {
    es: "Tour Virtual",
    en: "Virtual Tour",
  },
  description: "Acceso al tour virtual en 3D Vista",
  icon: "CameraAltOutlined",
  to: "/VirtualTour",
  groupId: "virtual-tour",
  hasAccess: true,
  highlight: true,
  component: VirtualTourPage,
};
let Sellers = {
  label: {
    es: "Proveedores",
    en: "Providers",
  },
  description: "Empresas que me proveen de insumos",
  icon: "AddShoppingCart",
  to: "/sellers",
  groupId: "b2b",
  hasAccess: true,
  component: SellersPage,
};
let Buyers = {
  label: {
    es: "Compradores",
    en: "Buyers",
  },
  description: "Empresas que compran mis productos",
  icon: "AddShoppingCart",
  to: "/buyers",
  groupId: "b2b",
  hasAccess: true,
  component: BuyersPage,
};
let MyAppointments = {
  label: {
    es: "Mis citas",
    en: "My Appointments",
  },
  description: "Listado de citas pendientes de revisar.",
  icon: "ListAltIcon",
  to: "/my-appointments",
  groupId: "b2b",
  hasAccess: true,
  component: MyAppointmentsPage,
};
let Videoconferences = {
  label: {
    es: "Videoconferencias",
    en: "Videoconferences",
  },
  icon: "VideoLabelIcon",
  to: "/videoconferences",
  groupId: "b2b",
  hasAccess: true,
  component: MyVideoconferencesPage,
};
let Users = {
  label: {
    es: "Usuarios",
    en: "Users",
  },
  description: "Listado de usuarios registrados en sistema.",
  icon: "PeopleIcon",
  to: "/users",
  groupId: "admin",
  hasAccess: true,
  component: UsersPage,
};
let Companies = {
  label: {
    es: "Empresas",
    en: "Companies",
  },
  description: "Empresas de compradores, CRUD solo manejable por ADMIN",
  icon: "ApartmentIcon",
  to: "/companies",
  groupId: "admin",
  hasAccess: true,
  component: CompaniesPage,
};
let ActivateAssistants = {
  label: {
    es: "Activar Proveedores",
    en: "Activate Providers",
  },
  description: "Permite al admin darle acceso a los Proveedores",
  icon: "CheckCircle",
  to: "/activate-assistants",
  groupId: "admin",
  hasAccess: true,
  component: ActivateAssistantsPage,
};
let ChangeRol = {
  label: {
    es: "Cambiar Rol",
    en: "Change Role",
  },
  description: "Permite al admin cambiar el rol de vendedor a asistente",
  icon: "TransformIcon",
  to: "/ChangeRol",
  groupId: "admin",
  hasAccess: true,
  component: ChangeRolPage,
};
export const SuccessfulRegister = {
  to: "/succesful-register",
  component: SuccessfulRegisterPage,
};
export const Report = {
  to: "/Report",
  component: ReportPage,
};
export const ReportAppointments = {
  to: "/reportAppointments",
  component: ReportAppointmentsPage,
};
export const ReportUsers = {
  to: "/report-users/:type",
  component: ReportPageUsers,
};
export const Profile = {
  to: "/profile",
  component: ProfilePage,
};
export const PublicProfile = {
  to: "/public-profile/:userid",
  component: PublicProfilePage,
};
export const AppointmentProfile = {
  to: "/appointment/:appointmentid",
  component: AppointmentProfilePage,
};
export const AddCompany = {
  to: "/AddCompany",
  component: AddCompanyPage,
};
export const CompanyDetail = {
  to: "/company-details/:CompanyId",
  component: CompanyDetailPage,
};

export const Meeting = {
  to: "/meet/:meetId",
  component: Meet,
};

export {
  DashboardModule,
  TourVirtual,
  Sellers,
  MyAppointments,
  Videoconferences,
  Users,
  Companies,
  Buyers,
  ActivateAssistants,
  ChangeRol,
};

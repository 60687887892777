import React from "react";
import { useTranslation } from "react-i18next";

import { RightForm } from "../..";
import FullBuyer from "./full-buyer/full-buyer";
import FullSeller from "./full-seller/full-seller";
import styles from "./right-resume.module.scss";
import LimitedBuyer from "./limited-buyer/limited-buyer";
import useUserCatalogs from "utils/catalogs/useUserCatalogs";

const RightResume = ({ user, screen, openAppointmentRequest }) => {
  const { t } = useTranslation("profile");
  const { screenEnums } = useUserCatalogs();

  return (
    <div className={styles["right-form-wrapper"]}>

      {/* EVALUATE WHICH COMPONENT TO PRINT HERE */}
      <div className="d-flex flex-column">
        {/* For GODS/ADMINS */}
        {screen === screenEnums.FULL_USER ? (
          <RightForm userData={user} />
        ) : // Buyers
        screen === screenEnums.LIMITED_BUYER_NO_APPOINTMENT ? (
          <LimitedBuyer
            user={user}
            openAppointmentRequest={openAppointmentRequest}
          />
        ) : screen === screenEnums.LIMITED_BUYER_APPOINTMENT_REQUESTED ? (
          <LimitedBuyer user={user} />
        ) : screen === screenEnums.FULL_BUYER_APPOINTMENT_ACCEPTED ? (
          <FullBuyer user={user} />
        ) : screen === screenEnums.LIMITED_BUYER_APPOINTMENT_REJECTED ? (
          <LimitedBuyer user={user} />
        ) : screen === screenEnums.LIMITED_BUYER_APPOINTMENT_CANCELED ? (
          <LimitedBuyer
            user={user}
            openAppointmentRequest={openAppointmentRequest}
          />
        ) : // Sellers
        screen === screenEnums.FULL_SELLER_NO_APPOINTMENT ? (
          <FullSeller
            user={user}
            openAppointmentRequest={openAppointmentRequest}
          />
        ) : screen === screenEnums.FULL_SELLER_APPOINTMENT_ACCEPTED ||
          screen === screenEnums.FULL_SELLER_APPOINTMENT_REQUESTED ||
          screen === screenEnums.FULL_SELLER_APPOINTMENT_REJECTED ? (
          <FullSeller user={user} />
        ) : screen === screenEnums.FULL_SELLER_APPOINTMENT_CANCELED ? (
          <FullSeller
            user={user}
            openAppointmentRequest={openAppointmentRequest}
          />
        ) : null}
      </div>
    </div>
  );
};

export default RightResume;

import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, Radio } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Loader, Alert, Recaptcha } from "..";

import TermsConditions from "./terms-conditions";
import PaymentTransfer from "./payment-transfer";
import styles from "./premium-register-resume.module.scss";

import ReactPayPal from "../ReactPayPal";

import formatNumberPrice from "../../utils/format-number-price";

const PAYPAL_OPTION = "PAYPAL_OPTION";
const TRANSFER_OPTION = "TRANSFER_OPTION";

const PremiumRegisterPayments = ({ registerAction, service }) => {
  const { t } = useTranslation("register");
  const { priceDescription, price, priceWithIVA } = service;
  const { loading, error } = useSelector((store) => store.register);
  const { eventInfo } = useSelector((store) => store.eventInfo);
  const [selectedValue, setSelectedValue] = useState(TRANSFER_OPTION);
  const [transferData, setTransferData] = useState(false);
  // const [paypalData, setPaypalData] = useState(false);
  const [captchaPassed, setCaptchaPassed] = useState(false);
  const captchaSucceededHandler = () => setCaptchaPassed(true);
  const captchaFailedHandler = () => setCaptchaPassed(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const emitRegisterAction = () => {
    // Tell the parent if paypal selected is true
    registerAction(selectedValue === PAYPAL_OPTION);
  };

  useEffect(() => {
    if (eventInfo && eventInfo.paymentMethods) {
      eventInfo.paymentMethods.forEach((paymentMethod) => {
        const {
          accountHolder,
          accountNumber,
          key,
          method,
          nameBank,
          type,
        } = paymentMethod;
        switch (method) {
          case "transferencia":
            setTransferData({
              accountHolder,
              accountNumber,
              key,
              nameBank,
              type,
            });
            break;
          case "paypal":
            // setPaypalData({ key, pass, user });
            break;
          default:
            break;
        }
      });
    }
  }, [eventInfo]);

  return (
    <div className="w-100">
      <div className={styles["payments-container"]}>
        {/* Transfer Option */}
        <div className="d-flex w-100 align-items-start py-2">
          <Radio
            checked={selectedValue === TRANSFER_OPTION}
            onChange={handleChange}
            value={TRANSFER_OPTION}
            name="radio-button-demo"
            color="primary"
          />
          <div className="w-100 mt-2 mr-4">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={["fas", "university"]}
                className="font-size-xxl mr-3"
              />
              <Typography className="pb-2">{t("payments.transfer")}</Typography>
            </div>
            <div className={selectedValue !== TRANSFER_OPTION ? "d-none" : ""}>
              <PaymentTransfer transferData={transferData} />
            </div>
          </div>
        </div>
        {/* Paymal Option */}
        <div
          className={`d-flex w-100 align-items-start py-2 ${styles["payment-paypal"]}`}
        >
          <Radio
            checked={selectedValue === PAYPAL_OPTION}
            onChange={handleChange}
            value={PAYPAL_OPTION}
            name="radio-button-demo"
            color="primary"
          />
          <div className="w-100 mt-2 mr-4">
            <div className="d-flex align-items-center">
              <FontAwesomeIcon
                icon={["fab", "paypal"]}
                className="font-size-xxl mr-4 text-primary"
              />
              <Typography className="pb-2">{t("payments.paypal")}</Typography>
            </div>
            <div
              style={{ width: "100%" }}
              className={selectedValue !== PAYPAL_OPTION ? "d-none" : ""}
            >
              <ReactPayPal price={priceWithIVA} />
            </div>
          </div>
        </div>
      </div>
      {/* Totals */}
      <div className="mt-4">
        <h3>{t("resume.totalAmount")}</h3>
        <h4>
          {formatNumberPrice(price)} {priceDescription}
        </h4>
        <div className="my-4">
          <Recaptcha
            captchaSucceededHandler={captchaSucceededHandler}
            captchaFailedHandler={captchaFailedHandler}
          />
        </div>
        <TermsConditions />
        <div
          className="d-flex justify-content-center mt-4"
          style={{ position: "relative", bottom: "-36px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Button
              color="primary"
              variant="contained"
              className="custom-primary-btn"
              disabled={!captchaPassed || loading}
              onClick={emitRegisterAction}
            >
              {t("resume.register")}
            </Button>
          )}
        </div>
        {error ? <Alert severity="error" message={error} /> : null}
      </div>
    </div>
  );
};
export default PremiumRegisterPayments;

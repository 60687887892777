import { useTranslation } from "react-i18next";
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
} from "@material-ui/core";

const ConfirmExit = (props) => {
  const { t } = useTranslation("confirm-exit");
  const { handleCloseConfirmExit, acceptConfirmExit, showConfirmExit } = props;

  const style = {
    color: "red",
    fontWeight: "bold",
  };

  return (
    <Dialog
      open={showConfirmExit}
      onClose={handleCloseConfirmExit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t("exitTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("exitPrompt")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirmExit} color="secondary">
          {t("cancel")}
        </Button>
        <Button
          onClick={acceptConfirmExit}
          color="primary"
          className="custom-primary-btn"
          style={style}
          autoFocus
        >
          {t("exit")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmExit;

import { useTranslation } from "react-i18next";
import React from "react";
import { Card, Divider } from "@material-ui/core";

import styles from "./free-register.module.scss";
import { RegisterForm } from "../../../components";

const FreeRegister = ({ onRegisterAction, service }) => {
  const { t } = useTranslation("register");
  return (
    <Card className={styles["card-container"]}>
      <div className="font-size-lg font-weight-bold">
        {t("title")}
      </div>

      <Divider className="mt-2 mb-4" />

      <RegisterForm
        onRegisterAction={onRegisterAction}
        usePromoCode={false}
        initialServiceId={service._id}
      />
    </Card>
  );
};

export default FreeRegister;

import React, { useEffect, useState, useCallback } from "react";
import Swal from "sweetalert2";
import { Card } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { TabForms } from "components";
import axios from "../../config/axios";
import PageLayout from "components/PageLayout";
import styles from "./public-profile.module.scss";
import LeftResume from "components/public-profile/left-resume/left-resume";
import RightResume from "components/public-profile/right-resume/right-resume";
import RequestAppointmentDialog from "components/public-profile/request-appointment-dialog";
import profileStyles from "../profile/profile.module.scss";

const PublicProfile = () => {
  // State
  const { t, i18n } = useTranslation("public-profile");
  const currentLanguage = i18n.language;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [publicUser, setPublicUser] = useState(false);
  const [appointment, setAppointment] = useState({
    date: "",
    startTime: "",
    endTime: "",
    status: { name: "" },
    description: [],
    buyer: { videoConferenceUrl: "" },
  });
  const [availableHours, setAvailableHours] = useState(false);
  let isAssistant = false;
  if (publicUser && publicUser.service) {
    const {
      service: { isAssistant: x },
    } = publicUser;
    isAssistant = x;
  }

  const [enumScreen, setEnumScreen] = useState(false);
  const [showRequestDialog, setShowRequestDialog] = useState(false);

  const showTabForms = enumScreen && enumScreen.includes("FULL") && isAssistant;

  const { userid } = useParams();

  /** Get THIS public user */
  const getPublicSelf = useCallback(async () => {
    axios
      .get(`/users/${userid}`)
      .then(({ data }) => {
        setPublicUser(data.data.user);
        setAppointment(data.data.appointment);
        setEnumScreen(data.data.screen);
        setAvailableHours(data.data.availableHours);
      })
      .catch(() => {
        setError(t("profileNotFoundLabel"));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [userid]);

  useEffect(() => {
    // Get public user
    getPublicSelf();
  }, [dispatch, getPublicSelf]);

  /** Get page title heading based on current language selected */
  const getPageTitleHeading = () => {
    switch (currentLanguage) {
      case "es":
        return `Datos del ${publicUser.role.name[currentLanguage]}`;
      case "en":
        return `${publicUser.role.name[currentLanguage]} Information`;
      default:
        return "";
    }
  };

  /** Open Appointment Request Confirm Box */
  const openAppointmentRequest = () => {
    setShowRequestDialog(true);
  };

  /**
   * Creates an appointment request with this public user
   * Also detects if the current user is a buyer or not. Backend does the rest
   */
  const createAppointmentRequest = ({ description }) => {
    setLoading(true);
    axios
      .post(`/appointment/${userid}/create-appointment-request`, {
        description,
        useridIsBuyer: !isAssistant,
      })
      .then(({ data }) => {
        const textMessage = data.data.successMessage && data.data.successMessage[currentLanguage]
          ? data.data.successMessage[currentLanguage]
          : `${t("b2b.requestAppointmentSent")} ${publicUser.firstName} ${publicUser.lastName}`;
        Swal.fire({
          icon: "success",
          title: t("b2b.requestAppointmentTitle"),
          text: textMessage,
        });
        setPublicUser(data.data.user);
        setEnumScreen(data.data.screen);
        setAvailableHours(data.data.availableHours);
      })
      .catch((error) => {
        const errorMsg =
          error.response.data && error.response.data.message && error.response.data.message[currentLanguage]
            ? error.response.data.message[currentLanguage]
            : t("b2b.requestAppointmentError");
        Swal.fire({
          icon: "error",
          title: t("b2b.requestAppointmentErrorTitle"),
          text: `${t("b2b.requestAppointmentSendFailed")} ${publicUser.firstName}. ${errorMsg}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Accepts the current appointment request with this public user
   * HERE: ONLY FOR BUYERS
   */
  const acceptAppointmentRequest = ({ date, startTime, endTime }) => {
    setLoading(true);
    axios
      .post(`/appointment/${userid}/accept-appointment-request`, {
        date,
        startTime,
        endTime,
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          title: t("b2b.acceptAppointmentTitle"),
          text: `${t("b2b.acceptAppointmentSent")} ${publicUser.firstName} ${publicUser.lastName}`,
        });
        setPublicUser(data.data.user);
        setEnumScreen(data.data.screen);
        setAppointment(data.data.appointment);
        setAvailableHours(data.data.availableHours);
      })
      .catch((error) => {
        const errorMsg =
          error.response.data && error.response.data.message && error.response.data.message[currentLanguage]
            ? error.response.data.message[currentLanguage]
            : t("b2b.acceptAppointmentError");
        Swal.fire({
          icon: "error",
          title: t("b2b.acceptAppointmentErrorTitle"),
          text: `${t("b2b.acceptAppointmentSendFailed")} ${publicUser.firstName}. ${errorMsg}`,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Rejects the current appointment request with this public user
   * HERE: ONLY FOR BUYERS
   */
  const rejectAppointmentRequest = () => {
    Swal.fire({
      title: t("b2b.rejectAppointmentPrompt"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("b2b.rejectAppointmentConfirmBtn"),
      cancelButtonText: t("b2b.rejectAppointmentCancelBtn"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .post(`/appointment/${userid}/reject-appointment-request`)
          .then(({ data }) => {
            Swal.fire(t("b2b.rejectAppointmentSuccess"), "", "success");
            setPublicUser(data.data.user);
            setEnumScreen(data.data.screen);
            setAppointment(data.data.appointment);
            setAvailableHours(data.data.availableHours);
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: t("b2b.rejectAppointmentErrorTitle"),
              text: `${t("b2b.rejectAppointmentError1")} ${publicUser.firstName} ${publicUser.lastName}. ${t("b2b.rejectAppointmentError2")}`,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  /**
   * Cancels the accepted appointment with this public user
   * HERE: ONLY FOR BUYERS
   */
  const cancelAcceptedAppointment = () => {
    Swal.fire({
      title: t("b2b.cancelAppointmentPrompt"),
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: t("b2b.cancelAppointmentConfirmBtn"),
      cancelButtonText: t("b2b.cancelAppointmentCancelBtn"),
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .post(`/appointment/${userid}/cancel-appointment-request`)
          .then(({ data }) => {
            Swal.fire(t("b2b.cancelAppointmentSuccess"), "", "success");
            setPublicUser(data.data.user);
            setEnumScreen(data.data.screen);
            setAppointment(data.data.appointment);
            setAvailableHours(data.data.availableHours);
          })
          .catch(() => {
            Swal.fire({
              icon: "error",
              title: t("b2b.cancelAppointmentErrorTitle"),
              text: `${t("b2b.cancelAppointmentError1")} ${publicUser.firstName} ${publicUser.lastName}. ${t("b2b.cancelAppointmentError2")}`,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    });
  };

  return (
    <PageLayout
      errorMessage={error}
      loading={loading}
      error={error}
      titleHeading={
        publicUser
          ? getPageTitleHeading()
          : error
          ? t("profileNotFoundLabel")
          : t("loadingProfileLabel")
      }
    >
      <RequestAppointmentDialog
        createAppointmentRequest={createAppointmentRequest}
        showRequestDialog={showRequestDialog}
        setShowRequestDialog={setShowRequestDialog}
      />

      <div className={profileStyles["content-wrapper"]}>
        <Card className={profileStyles["new-profile-wrapper"]}>
          <div className={styles["resume-form-container"]}>
            <LeftResume
              user={publicUser}
              appointment={appointment}
              screen={enumScreen}
              availableHours={availableHours}
              acceptAppointmentRequest={acceptAppointmentRequest}
              rejectAppointmentRequest={rejectAppointmentRequest}
              cancelAcceptedAppointment={cancelAcceptedAppointment}
            />

            <div className={profileStyles["resume-form-separator"]} />

            <RightResume
              user={publicUser}
              screen={enumScreen}
              openAppointmentRequest={openAppointmentRequest}
            />
          </div>
        </Card>

        {showTabForms ? <TabForms userData={publicUser} /> : null}
      </div>
    </PageLayout>
  );
};

export default PublicProfile;

import React from "react"
import Tooltip from '@material-ui/core/Tooltip';
import { truncateString } from "utils/helpers/string";
const TooltipTruncate = ({needs}) => {
    const needsTruncate =  truncateString(needs, 50) || "No especificados"
    const renderNeeds = needsTruncate === needs ? <p>{needs}</p> : <Tooltip title={needs}><p>{needsTruncate}</p>
    </Tooltip>
    return renderNeeds;
}
export default TooltipTruncate

import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import ListAltIcon from "@material-ui/icons/ListAlt";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import CalendarToday from "@material-ui/icons/CalendarToday";
import CameraAltOutlined from "@material-ui/icons/CameraAltOutlined";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ApartmentIcon from "@material-ui/icons/Apartment";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import TransformIcon from "@material-ui/icons/Transform";
import VideoLabelIcon from '@material-ui/icons/VideoLabel';

import {
  DashboardModule,
  TourVirtual,
  Sellers,
  Buyers,
  MyAppointments,
  Videoconferences,
  Users,
  Companies,
  ActivateAssistants,
  SuccessfulRegister,
  Report,
  ReportUsers,
  ReportAppointments,
  ChangeRol,
  Profile,
  PublicProfile,
  AppointmentProfile,
  AddCompany,
  CompanyDetail,
  Meeting
} from "./sidebar-modules";

const iconsMap = {
  CalendarToday,
  CameraAltOutlined,
  DashboardTwoToneIcon,
  PeopleIcon,
  ListAltIcon,
  VideoLabelIcon,
  ApartmentIcon,
  AddShoppingCart,
  CheckCircle,
  TransformIcon,
};

export default function validateUserResources(userRole, roleIdCatalog) {
  let validSidebarModules = [];
  validSidebarModules.push(DashboardModule);

  let validSessionRoutes = [];
  validSessionRoutes.push(DashboardModule);
  validSessionRoutes.push(SuccessfulRegister);
  validSessionRoutes.push(Meeting);

  // Add specific modules to ADMIN & GODS - resource or permission DOES NOT exist on role
  if (
    userRole._id === roleIdCatalog.GOD ||
    userRole._id === roleIdCatalog.ADMIN
  ) {
    validSessionRoutes.push(ChangeRol);
    validSessionRoutes.push(Report);
    validSessionRoutes.push(ReportUsers);
    validSessionRoutes.push(ReportAppointments);
  }

  /**
   * Read DB permissions and dynamically add the modules
   */
  if (userRole && Object.keys(userRole).length) {
    // Boolean to disable the screens (gray link) for FREE_ASSISTANT users
    const blockedModules = userRole._id !== roleIdCatalog.FREE_ASSISTANT;

    if (userRole.virtualTour) {
      validSidebarModules.push(TourVirtual);
      validSessionRoutes.push(TourVirtual);
    }

    if (userRole.resource) {
      userRole.resource.forEach((resource) => {
        if (resource.accessTo === "User") {
          if (resource.permissions.includes("toogle-assistants-service")) {
            validSidebarModules.push(ActivateAssistants);
            validSessionRoutes.push(ActivateAssistants);
          }
          if (resource.permissions.includes("read-roles")) {
            validSidebarModules.push(Users);
            validSessionRoutes.push(Users);
          }
          if (resource.permissions.includes("read-one")) {
            validSessionRoutes.push(Profile);
            validSessionRoutes.push(PublicProfile);
          }
        }

        if (resource.accessTo === "Appointment") {
          MyAppointments.hasAccess = blockedModules;
          if (blockedModules) validSessionRoutes.push(MyAppointments);
          validSidebarModules.push(MyAppointments);
          if (resource.permissions.includes("videoconference-list")) {
            validSessionRoutes.push(Videoconferences);
            validSidebarModules.push(Videoconferences);
          }
          if (resource.permissions.includes("read-buyers")) {
            Buyers.hasAccess = blockedModules;
            validSidebarModules.push(Buyers);
            if (blockedModules) validSessionRoutes.push(Buyers);
          }
          if (resource.permissions.includes("read-vendors")) {
            validSidebarModules.push(Sellers);
            validSessionRoutes.push(Sellers);
          }
          if (resource.permissions.includes("read-one")) {
            validSessionRoutes.push(AppointmentProfile);
          }
        }

        if (resource.accessTo === "Company") {
          validSidebarModules.push(Companies);
          if (resource.permissions.includes("create")) {
            validSessionRoutes.push(AddCompany);
          }
          if (resource.permissions.includes("read")) {
            validSessionRoutes.push(Companies);
          }
          if (resource.permissions.includes("read-one")) {
            validSessionRoutes.push(CompanyDetail);
          }
        }
      });
    }
  }

  validSidebarModules = JSON.stringify(validSidebarModules);
  validSidebarModules = [
    {
      content: JSON.parse(validSidebarModules, (key, value) => {
        if (key === "icon") {
          return iconsMap[value];
        } else {
          return value;
        }
      }),
    },
  ];

  return {
    validSidebarModules,
    validSessionRoutes,
  };
}

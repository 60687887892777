import axios from "../../config/axios";
import handleAxiosError from "../../utils/handleAxiosError";
import { EVENT_INFO, EVENT_INFO_FAILED, EVENT_INFO_SUCCESS } from "./Constants";

export const eventInfo = () => ({ type: EVENT_INFO });
export const eventInfoFailed = (error) => ({
  type: EVENT_INFO_FAILED,
  data: error,
});
export const eventInfoSuccess = (response) => ({
  type: EVENT_INFO_SUCCESS,
  data: response,
});

export const getEventInfo = () => {
  return async (dispatch) => {
    dispatch(eventInfo());

    try {
      const response = await axios.get("/eventinfo");

      if (response.status === 200) {
        const eventInfoResponse = response.data.data;
        dispatch(eventInfoSuccess(eventInfoResponse));
      } else {
        dispatch(eventInfoFailed(response.data));
      }
    } catch (error) {
      dispatch(eventInfoFailed(handleAxiosError(error)));
    }
  };
};

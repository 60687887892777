import React, { useState, forwardRef } from "react";
import { useSelector } from "react-redux";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";

import { ListItem, Button, Collapse } from "@material-ui/core";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {
  ModalChildren,
  ReminderPayment,
  InformationPayment,
  PremiumServiceOptions,
} from "../../components";

const CustomRouterLink = forwardRef(function CustomLink(props, ref) {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  );
});

const SidebarMenuListItem = (props) => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    hasAccess,
    highlight,
    ...rest
  } = props;

  const { eventInfo } = useSelector((store) => store.eventInfo);

  const [showPremiumServiceOptions, setShowPremiumServiceOptions] = useState(
    false
  );
  const [selectedServicePrices, setSelectedServicePrices] = useState({
    price: 0,
    priceWithIVA: 0,
    priceDescription: "",
  });

  const [open, setOpen] = useState(openProp);
  const [showReminderPayment, setShowReminderPayment] = React.useState(false);
  const [showConfirmationPayment, setShowConfirmationPayment] = React.useState(
    false
  );

  const onServiceSelect = (service) => {
    setShowPremiumServiceOptions(false);
    setShowConfirmationPayment(true);

    const { price, priceWithIVA, priceDescription } = service;
    setSelectedServicePrices({ price, priceWithIVA, priceDescription });
  };

  const toggleConfirmModal = () => setShowReminderPayment(!showReminderPayment);
  const toggleConfirmModalPayment = () =>
    setShowConfirmationPayment(!showConfirmationPayment);
  const toggleHide = () => {
    setShowPremiumServiceOptions(true);
    setShowReminderPayment(false);
  };
  const handleToggle = () => {
    setOpen((open) => !open);
  };

  let paddingLeft = 14;

  if (depth > 0) {
    paddingLeft = 32 + 20 * depth;
  }

  const style = {
    paddingLeft,
  };
  const styleDisabled = {
    color: "#d3d3d3",
    paddingLeft,
  };
  const styleHighlight = {
    color: "white",
    backgroundColor: "var(--primary)",
    borderRadius: 0,
    paddingTop: 10,
    paddingBottom: 10,
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx("app-sidebar-item", className)}
        disableGutters
      >
        <Button
          color="primary"
          className={clsx("app-sidebar-button", { active: open })}
          onClick={handleToggle}
          style={style}
        >
          {Icon && <Icon className="app-sidebar-icon" />}
          <span>{title}</span>
          {open ? (
            <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
          ) : (
            <ExpandLessIcon
              className="sidebar-expand-icon sidebar-expand-icon-rotate"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx("app-sidebar-item", className)}
        disableGutters
      >
        {hasAccess ? (
          <Button
            activeClassName="active-item"
            color="primary"
            disableRipple
            variant="text"
            className={clsx("app-sidebar-button-wrapper", `depth-${depth}`)}
            component={CustomRouterLink}
            exact
            style={highlight ? styleHighlight : style}
            to={href}
          >
            {Icon && <Icon className="app-sidebar-icon" />}
            {title}
            {Label && (
              <span className="menu-item-label">
                <Label />
              </span>
            )}
          </Button>
        ) : (
          <div>
            <Button
              color="primary"
              disableRipple
              variant="text"
              className={clsx("app-sidebar-button-wrapper", `depth-${depth}`)}
              style={styleDisabled}
              to={href}
              onClick={() => {
                setShowReminderPayment(true);
              }}
            >
              {Icon && <Icon className="app-sidebar-icon" />}
              {title}
              {Label && (
                <span className="menu-item-label">
                  <Label />
                </span>
              )}
            </Button>
            <ModalChildren
              center={true}
              showDialog={showReminderPayment}
              toggleDialog={toggleConfirmModal}
            >
              <ReminderPayment buttonAction={toggleHide} />
            </ModalChildren>

            <ModalChildren
              showDialog={showPremiumServiceOptions}
              toggleDialog={() =>
                setShowPremiumServiceOptions(!showPremiumServiceOptions)
              }
            >
              <PremiumServiceOptions
                eventInfo={eventInfo}
                onServiceSelect={onServiceSelect}
              />
            </ModalChildren>

            <ModalChildren
              showDialog={showConfirmationPayment}
              toggleDialog={toggleConfirmModalPayment}
            >
              <InformationPayment
                selectedServicePrices={selectedServicePrices}
                eventInfo={eventInfo}
              />
            </ModalChildren>
          </div>
        )}
      </ListItem>
    );
  }
};

SidebarMenuListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

SidebarMenuListItem.defaultProps = {
  depth: 0,
  open: false,
};

export default SidebarMenuListItem;

import moment from "moment";
import "moment/locale/es";

const parseDateToMx = (date) => moment(date).format("L h:mm a");

const parseDateMxToISO = (dateMx) => moment(dateMx, "L h:mm a").toISOString();

const parseDateToUTC = (date) => moment.utc(date).format("L h:mm a");

const parseDateWithStartTime = (date, startTime) => {
  if (date) {
    return `${moment.utc(date).format("DD/MM/YYYY")} ${startTime}`;
  }
  return "";
};

const parseDate = (date) => {
  if (date) {
    return `${moment.utc(date).format("DD/MM/YYYY H:mm")}`;
  }
  return "";
};

export {
  parseDateToMx,
  parseDateMxToISO,
  parseDateToUTC,
  parseDateWithStartTime,
  parseDate
};

import {
  GET_APPOINTMENT,
  GET_APPOINTMENT_FAILED,
  GET_APPOINTMENT_SUCCESS,
  REQUEST_APPOINTMENT,
  REQUEST_APPOINTMENT_FAILED,
  REQUEST_APPOINTMENT_SUCCESS,
  ADD_APPOINTMENT,
  ADD_APPOINTMENT_FAILED,
  ADD_APPOINTMENT_SUCCESS,
  UPDATE_APPOINTMENT_STATUS,
  UPDATE_APPOINTMENT_STATUS_FAILED,
  UPDATE_APPOINTMENT_STATUS_SUCCESS,
  RESET,
} from "../actions";

const initialState = {
  loading: false,
  error: false,
  requestSuccess: false,
  scheduleSuccess: false,
  updateSuccess: false,
  appointment: {
    _id: "",
    buyer: {},
    seller: { _id: "" },
    status: {
      code: "",
    },
    description: [],
  },
  appointmentRequestedId: "",
  buyerVirtualLink: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // ----- Get appointment by id  -----
    case GET_APPOINTMENT:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_APPOINTMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        appointment: action.data.appointment,
        buyerVirtualLink: action.data.buyerVirtualLink,
      };

    // ----- Request appointment (from vendor to buyer) -----
    case REQUEST_APPOINTMENT:
      return {
        ...state,
        loading: true,
        error: false,
        requestSuccess: false,
      };

    case REQUEST_APPOINTMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case REQUEST_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        requestSuccess: true,
        appointmentRequestedId: action.data,
      };
    // ----- Update appointment status  -----
    case UPDATE_APPOINTMENT_STATUS:
      return {
        ...state,
        loading: true,
        error: false,
        updateSuccess: false,
        scheduleSuccess: false,
      };

    case UPDATE_APPOINTMENT_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case UPDATE_APPOINTMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        updateSuccess: true,
      };

    // ----- Add appointment (only admins) -----
    case ADD_APPOINTMENT:
      return {
        ...state,
        loading: true,
        error: false,
        scheduleSuccess: false,
      };

    case ADD_APPOINTMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data,
      };

    case ADD_APPOINTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        scheduleSuccess: true,
      };

    case RESET:
      return {
        ...state,
        requestSuccess: false,
        scheduleSuccess: false,
        updateSuccess: false,
        error: false,
        loading: false,
        buyerVirtualLink: "",
      };

    default:
      return state;
  }
}

import moment from "moment";
import React from "react";
import "moment-timezone";
import { useParams } from "react-router-dom";
import axios from "../../config/axios";
const { TIME_ZONE } = process.env;

const Report = ({ history }) => {
  const {type} = useParams()
  let downloadFile1 = () => {
    axios
      .get(`/report-users/${type?type:''}`, { responseType: "blob" })
      .then((body) => {
        let url = window.URL.createObjectURL(new Blob([body.data]));
        let a = document.createElement("a");
        moment.tz.setDefault(TIME_ZONE);
        const nameFile = `ReporteDeUsuarios${moment().format(
          "YYYYMMDDHHmm"
        )}.xlsx`;
        a.href = url;
        a.download = nameFile;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  React.useEffect(() => {
    downloadFile1();
    history.push("/dashboard");
  }, [history]);

  return <div />;
};
export default Report;

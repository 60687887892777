import axios from "config/axios";
import handleAxiosError from "utils/handleAxiosError";
import {
  BUYERS,
  BUYERS_FAILED,
  BUYERS_SUCCESS,
  REGISTER_BUYER_FAILED,
  REGISTER_BUYER_SUCCESS,
  BUYER,
  BUYER_FAILED,
  BUYER_SUCCESS,
} from "./Constants";

export const buyers = () => ({ type: BUYERS });
export const buyersFailed = (error) => ({
  type: BUYERS_FAILED,
  data: error,
});
export const buyersSuccess = (response) => ({
  type: BUYERS_SUCCESS,
  data: response,
});
export const registerBuyerFailed = (error) => ({
  type: REGISTER_BUYER_FAILED,
  data: error,
});
export const registerBuyerSuccess = (response) => ({
  type: REGISTER_BUYER_SUCCESS,
  data: response,
});
export const getBuyers = (query) => {
  return async (dispatch) => {
    dispatch(buyers());

    try {
      const response = await axios.post("/buyers/filters", query);

      if (response.status === 200) {
        const buyersResponse = response.data.data;
        dispatch(buyersSuccess(buyersResponse));
      } else {
        dispatch(buyersFailed(response.data));
      }
    } catch (error) {
      dispatch(buyersFailed(handleAxiosError(error)));
    }
  };
};

export const buyer = () => ({ type: BUYER });
export const buyerFailed = (error) => ({
  type: BUYER_FAILED,
  data: error,
});
export const buyerSuccess = (response) => ({
  type: BUYER_SUCCESS,
  data: response,
});
export const getBuyer = (id) => async (dispatch) => {
  dispatch(buyer());

  try {
    const response = await axios.get(`/users/${id}`);
    if (response.status === 200) {
      const buyerResponse = response.data.data;
      dispatch(buyerSuccess(buyerResponse));
    } else {
      dispatch(buyerFailed(response.data));
    }
    return response;
  } catch (error) {
    dispatch(buyerFailed(handleAxiosError(error)));
  }
};

export const registerBuyer = (user, companyid) => {
  return async (dispatch) => {
    dispatch(buyer());

    try {
      const response = await axios.post(`/company/${companyid}/customer`, user);

      if (response.status === 200) {
        dispatch(registerBuyerSuccess(response.data));
      } else {
        dispatch(registerBuyerFailed(response.data));
      }
    } catch (error) {
      dispatch(registerBuyerFailed(handleAxiosError(error)));
    }
  };
};

export const registerBuyerAndCompany = (user) => {
  return async (dispatch) => {
    dispatch(buyer());

    try {      
      const response = await axios.post(`/company`, { user });

      if (response.status === 200) {
        dispatch(registerBuyerSuccess(response.data));
      } else {
        dispatch(registerBuyerFailed(response.data));
      }
    } catch (error) {
      dispatch(registerBuyerFailed(handleAxiosError(error)));
    }
  };
};

import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = (props) => {
  const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY || "";

  const { captchaSucceededHandler, captchaFailedHandler } = props;

  const onChangeHandler = (value) => {
    if (value) {
      captchaSucceededHandler();
    }
  };

  const onExpiredHandler = () => captchaFailedHandler();

  const onErroredHandler = () => captchaFailedHandler();

  if (CAPTCHA_KEY) {
    return (
      <ReCAPTCHA
        sitekey={CAPTCHA_KEY}
        onChange={onChangeHandler}
        onExpired={onExpiredHandler}
        onErrored={onErroredHandler}
      />
    );
  }

  return <b className="text-danger">Captcha key is missing</b>;
};

export default Recaptcha;

export default (number) => {
  if (number >= 0) {
    if (number === 0) {
      return "";
    } else {
      return `$${number
        .toString()
        .replace(/\B(?!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`;
    }
  } else {
    return "";
  }
};

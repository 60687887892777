import { useTranslation } from "react-i18next";
import React from "react";

import styles from "./premium-register-resume.module.scss";

const PaymentTransfer = ({ transferData }) => {
  const { t } = useTranslation("register");
  return (
    <div className={styles["payment-transaction-info"]}>
      <div className={styles["payment-transaction-item"]}>
        <p>{t("payments.accountType")}</p>
        <b>{transferData.type || ""}</b>
      </div>

      <div className={styles["payment-transaction-item"]}>
        <p>{t("payments.bankName")}</p>
        <b>{transferData.nameBank || ""}</b>
      </div>

      <div className={styles["payment-transaction-item"]}>
        <p>{t("payments.accountNo")}</p>
        <b>
          {transferData.accountNumber || ""} / {transferData.key || ""}
        </b>
      </div>

      <div className={styles["payment-transaction-item"]}>
        <p>{t("payments.accountHolder")}</p>
        <b>{transferData.accountHolder || ""}</b>
      </div>
    </div>
  );
};

export default PaymentTransfer;

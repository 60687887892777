import React, { useState } from "react";
import axios from "config/axios";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const styleDiv = {
  height: "80vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const styleTitle = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
};
const ChangeRol = () => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [dataError, setDataError] = useState("");
  const [dataSuccess, setDataSuccess] = useState("");
  const [email, setEmail] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenError(false);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    getResult();
  };

  const getResult = async () => {
    try {
      const response = await axios.put("/reassign-role", { email });
      if (
        response.data.data ===
        "El usuario ha sido convertido a un proveedor, exitosamente!"
      ) {
        setDataSuccess("Hemos cambiado tu usuario a proveedor exitosamente");
        setOpen(true);
        setEmail("");
      } else {
        setDataError(response.data.data);
        setOpenError(true);
        setEmail("");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  return (
    <div style={styleDiv}>
      <Card>
        <CardContent style={styleTitle}>
          <Typography color="textSecondary" gutterBottom>
            Ingresa el correo del usuario visitante que se desea cambiar el rol
            a proveedor
          </Typography>
          <TextField
            style={{ width: "250px" }}
            label="Email"
            value={email}
            onChange={handleChange}
          />
        </CardContent>
        <CardActions style={{ display: "flex", justifyContent: "center" }}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className="custom-primary-btn"
            onClick={handleSubmit}
          >
            Cambiar Rol
          </Button>
        </CardActions>
      </Card>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {dataSuccess}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleCloseError}
      >
        <Alert onClose={handleCloseError} severity="error">
          {dataError}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ChangeRol;

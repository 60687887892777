import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AvatarUploader = ({ loading, onAvatarSelected }) => {
  const inputFileStyles = {
    cursor: "pointer",
    backgroundColor: "white",
    position: "absolute",
    borderBottomLeftRadius: 5,
    right: -15,
    top: -10,
    paddingLeft: 5,
    paddingRight: 5,
    opacity: 0.5,
  };

  return (
    <label data-testid="avatar-uploader" style={inputFileStyles}>
      <FontAwesomeIcon icon={["fa", "pencil-alt"]} />
      <input
        id="input-file"
        type="file"
        accept="image/*"
        disabled={loading}
        style={{ display: "none" }}
        onChange={onAvatarSelected}
      />
    </label>
  );
};

export default AvatarUploader;
